import { Link, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid2";

const NoPage = () => {
	const navigate = useNavigate();
	return (
		<Grid alignItems="center" justifyContent="center">
			<Grid>
				<Typography align="center" variant="h4" color="initial">
					Page Not Found : 404
				</Typography>
			</Grid>
			<Grid>
				<Link to="/homepage">
					<Typography
						align="center"
						variant="subtitle1"
						color="initial"
					>
						Return to Hompage
					</Typography>
				</Link>
			</Grid>
		</Grid>
	);
};

export default NoPage;
