import React from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	Typography,
	IconButton,
} from "@mui/material";
import CloseIcon from "../../assets/images/close.svg";

// Define the types for the component's props
interface ListItem {
	title?: string;
	content: string;
}

interface Section {
	title: string;
	description?: string;
	list?: ListItem[];
}

interface ReusableDialogProps {
	open: boolean;
	onClose: () => void;
	title: string;
	sections: Section[];
}

const ReusableDialog: React.FC<ReusableDialogProps> = ({
	open,
	onClose,
	title,
	sections,
}) => {
	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
			<DialogTitle
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					textAlign: "center",
				}}
			>
				{title}
				<IconButton
					edge="end"
					color="inherit"
					onClick={onClose}
					aria-label="close"
				>
					<img src={CloseIcon} alt="x" />
				</IconButton>
			</DialogTitle>
			<DialogContent dividers>
				<DialogContentText component="div" sx={{ typography: "body1" }}>
					{sections.map((section, index) => (
						<div key={index}>
							<Typography variant="h6" gutterBottom>
								{section.title}
							</Typography>
							{section.description && (
								<Typography paragraph>
									{section.description}
								</Typography>
							)}
							{section.list && (
								<ul>
									{section.list.map((item, idx) => (
										<li key={idx}>
											<strong>{item.title}</strong>{" "}
											{item.content}
										</li>
									))}
								</ul>
							)}
						</div>
					))}
				</DialogContentText>
			</DialogContent>
		</Dialog>
	);
};

export default ReusableDialog;
