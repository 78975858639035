import * as React from "react";

interface SvgComponentProps {
	[key: string]: any;
}

const StorageIcon: React.FC<SvgComponentProps> = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={25}
		height={25}
		fill="none"
		{...props}
	>
		<path
			fill="currentColor"
			fillRule="evenodd"
			d="M11.5 0C18.055 0 22.998 2.185 23 5.082V20.863C23 23.58 17.214 25 11.5 25S0 23.58 0 20.863V5.082C.002 2.185 4.946 0 11.5 0Zm9.967 5.084c0-1.686-4.262-3.574-9.967-3.574-5.705 0-9.967 1.888-9.967 3.574 0 1.687 4.262 3.574 9.967 3.574 5.705 0 9.967-1.887 9.967-3.574ZM1.533 20.864c0 .916 3.424 2.626 9.967 2.626 6.543 0 9.967-1.71 9.967-2.627V14.63c-1.95 1.507-5.596 2.481-9.967 2.481s-8.016-.974-9.967-2.481v6.233Zm19.934-8.837c0 1.69-4.093 3.574-9.967 3.574-5.874 0-9.967-1.883-9.967-3.574v-4.34c1.95 1.506 5.596 2.48 9.967 2.48s8.016-.974 9.967-2.48v4.34Z"
			clipRule="evenodd"
		/>
	</svg>
);
export default StorageIcon;
