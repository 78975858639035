import React, { ReactNode, useEffect, useState } from "react";
import {
	AppBar,
	Drawer,
	Toolbar,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	IconButton,
	Typography,
	Box,
	Menu,
	Tooltip,
	MenuItem,
	Avatar,
	Badge,
	Divider,
	ListItemButton,
} from "@mui/material";
import Logo from "../../assets/images/Allianz.svg";
import NotificationIcon from "../../assets/images/NotificationIcon";
import { useTheme } from "@mui/material/styles";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/authSlice";
import LeftArrowIcon from "../../assets/images/LeftArrow";
import RightArrowIcon from "../../assets/images/RightArrow";
import HomeIcon from "../../assets/images/HomeIcon";
import StorageIcon from "../../assets/images/StorageIcon";
import SettingIcon from "../../assets/images/SettingIcon";
import ConfigureIcon from "../../assets/images/ConfigureIcon";
import VectorIcon from "../../assets/images/VectorIcon";
import ChevronIcon from "../../assets/images/ChevronIcon";
import { RootState } from "../../store/appStore";
import frontendSettings from "../../../frontend_settings.json";
import { hideLoader, showLoader } from "../../store/loaderSlice";
import { contractStatus, getContractStatus } from "../../api";
import {
	selectProgressStatus,
	setProgressStatus,
} from "../../store/progressStatus";
import Dialog from "../Dialog/Dialog";
import { privacyPolicySections } from "../../constants/common";
const DefaultLayout: React.FC = () => {
	const progress = useSelector(selectProgressStatus);
	const theme = useTheme();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const currentPath = location.pathname;
	const userInfo:any = useSelector((state: RootState) => state.auth.user);
	const isAdmin = userInfo?.roles?.includes("Admin");
	const drawerWidth = 250;
	const miniDrawerWidth = 60;
	const [progVal, setProgVal] = useState(progress.percentStatus);

	const fetchStatus = async () => {
		try {
			dispatch(showLoader());
			const fetchedStatus: contractStatus[] = await getContractStatus();
			if (fetchedStatus && fetchedStatus.length > 0) {
				const response = fetchedStatus[0]; // fetching 1st result as per BE api
				setProgVal(parseInt(response.percent_status) || 0);
				dispatch(
					setProgressStatus({
						percentStatus: parseInt(response.percent_status) || 0,
					})
				);
			}
		} catch (error) {
			console.error("Error fetching status:", error);

			dispatch(hideLoader());
		}
		dispatch(hideLoader());
	};

	const [sideBarItems, setSideBarItems] = useState([
		{
			name: "Home",
			icon: <HomeIcon />,
			path: "/homepage",
			isActive: false,
			disabled: false,
		},
		{
			name: "Contract Management",
			icon: <StorageIcon />,
			path: "/homepage/management",
			isActive: false,
			disabled: false,
		},
		{
			name: "Contract Insights",
			icon: <VectorIcon />,
			path: "/homepage/intelligence",
			isActive: false,
			disabled: false,
		},
    ...(isAdmin
      ? [
          {
            name: "User Management",
            icon: <ConfigureIcon />,
            path: "/homepage/user",
            isActive: false,
            disabled: false,
          },
        ]
      : []),
		// {
		// 	name: "Settings",
		// 	icon: <SettingIcon />,
		// 	path: "/settings",
		// 	isActive: false,
		// },
	]);
	
	React.useEffect(() => {
		setSideBarItems(
			sideBarItems.map((ele) => ({
				...ele,
				isActive: ele.path === location.pathname,
			}))
		);
		fetchStatus();
	}, [location.pathname]);

	useEffect(() => {
		// Update sidebar items when `percentStatus` changes
		setSideBarItems((items) =>
			items.map((item) => ({
				...item,
			}))
		);
	}, [progress.percentStatus]);

	const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
		null
	);

	const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};
	const handleLogout = (event: React.MouseEvent<HTMLElement>) => {
		dispatch(logout());
		handleCloseUserMenu();
	};

	const [open, setOpen] = React.useState<boolean>(false);

	const handleDrawerOpen = () => {
		setOpen(!open);
	};

	const handleSelect = (event: React.MouseEvent<HTMLElement>) => {
		const data = event.currentTarget.getAttribute("data-value");
		let link: string = "";
		setSideBarItems(
			sideBarItems.map((ele) => {
				// return { ...ele, isActive: ele.name === data };
				if (ele.name === data) {
					ele.isActive = true;
					link = ele.path;
				} else ele.isActive = false;
				return ele;
			})
		);
		navigate(link);
	};
	const [dialogOpen, setDialogOpen] = useState(false);
	const [dialogTitle, setDialogTitle] = useState("");

	const handleOpenDialog = (data: string) => {
		setDialogTitle(data);
		setDialogOpen(true);
	};

	const handleCloseDialog = () => {
		setDialogOpen(false);
		setDialogTitle("");
	};

	return (
		<div>
			{/* Main App Bar */}
			<AppBar
				position="fixed"
				sx={{ background: theme.palette.background.default }}
			>
				<Box paddingX={2}>
					<Toolbar disableGutters>
						<Box
							component="img"
							sx={{ width: "135px" }}
							src={
								frontendSettings &&
								frontendSettings?.login?.photo
							}
						></Box>

						<Box
							sx={{
								flexGrow: 1,
								display: "flex",
							}}
						>
							<Divider
								sx={{ borderColor: "#9E9E9E", mx: 2 }}
								orientation="vertical"
								flexItem
							/>
							<Typography
								variant="h6"
								align="center"
								sx={{
									fontWeight: 400,
									color: theme.palette.text.primary,
								}}
							>
								Contract Intelligence
							</Typography>
						</Box>

						<Box
							sx={{
								mx: 1,
								cursor: "not-allowed",
								opacity: 0.4,
							}}
						></Box>

						<Box sx={{ flexGrow: 0 }}>
							<Tooltip title="Open settings">
								<IconButton
									onClick={handleOpenUserMenu}
									sx={{
										py: 1,
									}}
								>
									<Avatar
										alt={`Shubham Mishra`}
										sx={{
											fontSize: "14px",
											background:
												theme.palette.background
													.default,
											border: `2px solid ${theme.palette.primary.main}`,
											color: theme.palette.text.primary,
										}}
									>
										{userInfo?.initials}
									</Avatar>
									<Box
										component="div"
										sx={{
											mx: 2,
											color: theme.palette.primary.main,
										}}
									>
										<ChevronIcon />
									</Box>
								</IconButton>
							</Tooltip>
							<Menu
								sx={{ mt: "45px" }}
								id="menu-appbar"
								anchorEl={anchorElUser}
								anchorOrigin={{
									vertical: "top",
									horizontal: "right",
								}}
								keepMounted
								transformOrigin={{
									vertical: "top",
									horizontal: "right",
								}}
								open={Boolean(anchorElUser)}
								onClose={handleCloseUserMenu}
							>
								<MenuItem onClick={handleLogout}>
									<Typography sx={{ textAlign: "center" }}>
										Logout
									</Typography>
								</MenuItem>
							</Menu>
						</Box>
					</Toolbar>
				</Box>
			</AppBar>

			{/* Side Bar */}
			{
				<Drawer
					sx={{
						"& .MuiDrawer-paper": {
							marginTop: 9,
							width: open ? drawerWidth : miniDrawerWidth,
							transition: "width 0.3s ease",
							boxSizing: "border-box",
							borderRadius: `0 30px 30px 0`,
							maxHeight: 800,
						},
					}}
					variant="permanent"
					anchor="left"
					open={open}
				>
					{open ? (
						<Box
							component="div"
							sx={{
								width: "32px",
								position: "absolute",
								right: 0,
								zIndex: 99,
								marginTop: 3,
								cursor: "pointer",
								color: theme.palette.primary.main,
							}}
							onClick={handleDrawerOpen}
						>
							<LeftArrowIcon />
						</Box>
					) : (
						<Box
							component="div"
							sx={{
								width: "32px",
								position: "absolute",
								right: 0,
								zIndex: 99,
								marginTop: 3,
								cursor: "pointer",
								color: theme.palette.primary.main,
							}}
							onClick={handleDrawerOpen}
						>
							<RightArrowIcon />
						</Box>
					)}
					<List
						sx={{
							marginTop: 8,
							position: "relative",
							height: "75vh",
						}}
					>
						{sideBarItems.map((text, index) => (
							<ListItem
								key={text.name}
								disablePadding
								sx={{
									display: "block",
									position:
										index === sideBarItems.length - 1
											? "absolute"
											: "relative",
									py: 1,
								}}
							>
								<ListItemButton
									data-value={text.name}
									sx={[
										{
											minHeight: 48,
											justifyContent: "center",
											background: text.isActive
												? theme.palette.primary.main
												: theme.palette.secondary
														.contrastText,
											"&:hover": {
												background: text.isActive
													? theme.palette.primary.dark // Change this to your desired hover color
													: "", // Change this to your desired hover color
											},
											pointerEvents: text.disabled
												? "none"
												: "auto",
											cursor: text.disabled
												? "copy"
												: "pointer",
											opacity: text.disabled ? 0.5 : 1,
										},
										open
											? {
													justifyContent: "initial",
											  }
											: {
													justifyContent: "center",
											  },
									]}
									onClick={handleSelect}
								>
									<ListItemIcon
										data-value={text.name}
										sx={[
											{
												justifyContent: "center",
											},
											open
												? {
														mr: 1,
												  }
												: {},
										]}
									>
										<Box
											component="div"
											sx={{
												color: text.isActive
													? theme.palette.secondary
															.contrastText
													: theme.palette.text
															.primary,
												my: 1,
												position:
													index ===
													sideBarItems.length - 1
														? "absolute"
														: "relative",
												bottom:
													index ===
													sideBarItems.length - 1
														? 0
														: "auto",
											}}
										>
											{text.icon}
										</Box>
									</ListItemIcon>
									{open && (
										<ListItemText
											data-value={text.name}
											primary={text.name}
											sx={{
												opacity: 1,
												color: text.isActive
													? theme.palette.secondary
															.contrastText
													: "",
											}}
										/>
									)}
								</ListItemButton>
							</ListItem>
						))}
					</List>
				</Drawer>
			}

			<main
				style={{
					flexGrow: 1,
					marginLeft: open
						? `calc(${drawerWidth}px)`
						: `calc(${miniDrawerWidth}px)`,
					padding: "15px 15px 0 15px",
					minHeight: "100vh",
					background: "#F9F9F9",
				}}
			>
				<Outlet />
			</main>

			{/* footer */}
			<Box
				component="footer"
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					gap: 2,
					py: 1,
					backgroundColor: theme.palette.primary.main,
					color: "#fff",
					position: "fixed",
					bottom: 0,
					left: open ? `${drawerWidth}px` : `${miniDrawerWidth}px`,
					right: 0,
					zIndex: theme.zIndex.drawer + 1,
					cursor: "pointer",
				}}
			>
				<Typography
					variant="body2"
					sx={{
						"&:hover": {
							textDecoration: "underline",
						},
					}}
				>
					© Copy 2024
				</Typography>
				<Typography
					variant="body2"
					sx={{
						"&:hover": {
							textDecoration: "underline",
						},
					}}
				>
					Legal Disclaimer
				</Typography>
				<Typography
					variant="body2"
					onClick={() => handleOpenDialog("Terms and Conditions")}
					sx={{
						"&:hover": {
							textDecoration: "underline",
						},
					}}
				>
					Terms and Conditions
				</Typography>
				<Typography
					variant="body2"
					onClick={() => handleOpenDialog("Privacy Policy")}
					sx={{
						"&:hover": {
							textDecoration: "underline",
						},
					}}
				>
					Privacy Policy
				</Typography>
				<Typography
					variant="body2"
					sx={{
						position: "absolute",
						right: 25,
						"&:hover": {
							textDecoration: "underline",
						},
					}}
				>
					Version : {privacyPolicySections.Version}
				</Typography>
			</Box>

			{/* Dialog */}
			<Dialog
				open={dialogOpen}
				onClose={handleCloseDialog}
				title={dialogTitle}
				sections={privacyPolicySections.data}
			/>
		</div>
	);
};

export default DefaultLayout;
