import React from 'react';
import CreatePassword from '../../components/Login/CreatePassword';
import { useLocation } from 'react-router-dom';

const ResetPassword: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  return (
      <CreatePassword pageType="reset-password" token = {token || ""}/>
  );
};

export default ResetPassword;
