import * as React from "react";

interface SvgComponentProps {
	[key: string]: any;
}

const ChevronIcon: React.FC<SvgComponentProps> = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={12}
		height={13}
		fill="none"
		{...props}
	>
		<path
			fill="currentColor"
			d="m6 7.916-4.537-4.58a.852.852 0 0 0-1.212 0A.859.859 0 0 0 0 3.946c0 .233.091.455.251.612l5.143 5.192a.852.852 0 0 0 1.212 0l5.143-5.192a.859.859 0 0 0 .25-.612.859.859 0 0 0-1.462-.612L6 7.915Z"
		/>
	</svg>
);
export default ChevronIcon;
