import React from "react";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { RootState } from "../../store/appStore";
import { Box, CircularProgress } from "@mui/material";

const LoaderContainer = styled("div")`
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: linear-gradient(
		rgba(255, 255, 255, 0.5),
		rgba(255, 255, 255, 0.5)
	);
	z-index: 99999;
`;

const LoadingContainer = styled("div")`
	font-size: 50px;
	font-weight: 800;
	text-align: center;
	& span {
		display: inline-block;
		margin: 0 -0.05em;
		color: ${({ theme }) => theme.palette.primary.main};
	}
`;

const lightFont = {
	fontWeight: 100,
};

const Loader: React.FC = () => {
	const loading = useSelector((state: RootState) => state.loader.loading);
	if (loading) {
		return (
			<LoaderContainer>
				<LoadingContainer>
					<CircularProgress />
				</LoadingContainer>
			</LoaderContainer>
		);
	}

	return null;
};

export default Loader;
