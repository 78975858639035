import Grid from "@mui/material/Grid2";
import {
	Box,
	Divider,
	Typography,
	Button,
	Tabs,
	Tab,
	TextField,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Checkbox,
	Pagination,
	Paper,
} from "@mui/material";
import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import DataTable from "../../components/dataTable/DataTable";
import ReusableDialog from "../../components/formDialog/ReusableDialog";
import {
	Createclient,
	CreateUser,
	DeleteClient,
	DeleteUser,
	getClients,
	getUserList,
	getUserListDropdowns,
	updateClient,
	updateUser,
} from "../../api";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../store/loaderSlice";
import DeleteIcon from "../../assets/images/DeteleIcon";
import EditIcon from "../../assets/images/EditIcon";

import { IconButton } from "@fluentui/react";
import { toast } from "react-toastify";
import {
	columnsClientManagement,
	columnsUserManagement,
	filterDataByVisibleColumns,
} from "../../constants/common";

const TabPanel = (props: {
	children?: React.ReactNode;
	index: number;
	value: number;
}) => {
	const { children, value, index, ...other } = props;
	return (
		<div role="tabpanel" hidden={value !== index} {...other}>
			{value === index && <Box sx={{ p: 3 }}>{children}</Box>}
		</div>
	);
};

const UserManagement = () => {
	const [value, setValue] = useState(0);
	const [openDialog, setOpenDialog] = useState(false);
	const [userList, setUserList] = useState<[]>([]);
	const [selected, setSelected] = useState<number[]>([]);
	const [userColumn, setUserColumn] = useState(columnsUserManagement);
	const [dialogTitle, setDialogTitle] = useState("New User");
	const [editData, setEditData] = useState<Record<string, string> | null>(
		null
	);
	const [search, setSearch] = useState("");
	const [searchTerm, setSearchTerm] = useState(""); // Immediate input
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [totalCount, setTotalCount] = useState(30);
	const [clientList, setClientList] = useState([]);
	const [userButton, setUserButton] = useState("Create User");
	console.log(selected, "selected");

	const dispatch = useDispatch();

	useEffect(() => {
		if (value === 0) fetchUserList();
		else if (value === 1) fetchClientsList();
	}, [search, page, rowsPerPage, value]);

	useEffect(() => {
		fetchUserDropdownData();
	}, []);

	// debouncing on search
	useEffect(() => {
		const delayDebounce = setTimeout(() => {
			setSearch(searchTerm); // Update debounced search after delay
		}, 1000); // Set debounce time as needed

		// Cleanup timeout if user types again before delay ends
		return () => clearTimeout(delayDebounce);
	}, [searchTerm]);

	const fetchUserDropdownData = async () => {
		try {
			dispatch(showLoader());
			const response = await getUserListDropdowns();
			if (response && response.success && response.data) {
				const data = columnsUserManagement.map((ele) => {
					if (
						ele.id === "role_list" ||
						ele.id === "login_method" ||
						ele.id === "client_name"
					) {
						return {
							...ele,
							options: response.data[ele.id],
						};
					}
					return ele;
				});
				setUserColumn(data);
				dispatch(hideLoader());
			}else {
				const errorMsg = response?.payload["0"] || "Failed to fetch user list dropdown";
				toast.error(errorMsg);
			}
		} catch (error) {
			dispatch(hideLoader());
			let err: any = error;
			const errorMsg = err?.payload["0"] || "Failed to fetch user list dropdown";
			toast.error(errorMsg);
		}
	};

	const fetchUserList = async () => {
		try {
			dispatch(showLoader());
			const response = await getUserList(search, page + 1, rowsPerPage);
			if (response && response?.success && response?.data  ) {
				const mapUserData = response.data.map((user: any) => ({
					...user,
					role: user.role_list.join(","),
					verification: user.is_verified
						? "Verified"
						: "Not Verified",
				}));

				setUserList(mapUserData);
				setTotalCount(response.pagination.total_items);
				dispatch(hideLoader());
			}else{
				const errorMsg = response?.payload || "Failed to fetch users list"
				toast.error(errorMsg);
			}
		} catch (error) {
			dispatch(hideLoader());
			let err: any = error;
			const errorMsg = err?.payload || "Failed to fetch users list"
			toast.error(errorMsg);
		} finally {
			dispatch(hideLoader());
		}
	};

	const fetchClientsList = async () => {
		try {
			dispatch(showLoader());
			const response = await getClients(search, page + 1, rowsPerPage);
			console.log(response);
			if (response && response.success) {
				setClientList(response.data);
				setTotalCount(response.pagination.total_items);
				dispatch(hideLoader());
			} else{
				const errorMsg = response?.payload || "Failed to fetch clients list";
				toast.error(errorMsg);
			}
		} catch (error) {
			dispatch(hideLoader());
			let err: any = error;
			const errorMsg = err?.payload || "Failed to fetch clients list";
			toast.error(errorMsg);
		} finally {
			dispatch(hideLoader());
		}
	};

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
		// reset => search, page, rowsPerPage when tab changed
		setSearch("");
		setPage(0);
		setRowsPerPage(10);
	};
	const handleDialogSubmit = async (data: Record<string, string>) => {
		dispatch(showLoader());
		try {
			if (value === 0) {
				// For Users
				const isNewUser = dialogTitle === "New User";
				const payload = isNewUser
					? [data]
					: filterDataByVisibleColumns([data]);
				const apiCall:any = isNewUser ? CreateUser : updateUser;

			const res =	await apiCall(payload);
				if(res?.success){
					toast.success(res?.payload)
					await fetchUserList();
				}else{
					const error = res?.payload["0"] || "something went wrong";
					toast.error(error);
				}
			} else if (value === 1) {
				// For Clients
				const isNewClient = dialogTitle === "New Client";
				const payload = [data];
				const apiCall = isNewClient ? Createclient : updateClient;
				const res =	await apiCall(payload);
				if(res?.success){
					toast.success(res?.payload)
					await fetchClientsList();
				}else{
					const error = res?.payload["0"] || "something went wrong";
					toast.error(error)
				}
			}
		} catch (error) {
			const err:any = error;
			const errorMsg = err?.payload["0"] || "something went wrong";
			toast.error(errorMsg)
		} finally {
			dispatch(hideLoader());
			setOpenDialog(false);
			setEditData(null);
		}
	};

	const handleEditUser = async (userData: Record<string, string>) => {
		setUserButton("Edit User");
		setDialogTitle("Edit User");
		setEditData(userData);
		setOpenDialog(true);
	};
	// deleting user
	const handleDeleteUser = async () => {
		dispatch(showLoader());
		const res = await DeleteUser({ user_ids: selected });
		if (res.success) {
			toast.success("User Deleted Successfully")
			await fetchUserList();
			dispatch(hideLoader());
		} else {
			const error = res?.payload["0"] || "Failed to delete Users"
			toast.error(error)
			dispatch(hideLoader());
		}
	};

	const handleUpdateUser = () => {
		if (selected.length === 1) {
			const userToEdit = userList.find(
				(user: any) => user.user_id === selected[0]
			);
			if (userToEdit) handleEditUser(userToEdit);
		} else {
			toast.error("Select one user to edit");
		}
	};
	const handleEditClient = async (userData: Record<string, string>) => {
		setUserButton("Update Client");
		setDialogTitle("Edit Client");
		setEditData(userData);
		setOpenDialog(true);
	};
	const handleUpdateClient = () => {
		if (selected.length === 1) {
			const userToEdit = clientList.find(
				(user: any) => user.client_id === selected[0]
			);
			if (userToEdit) handleEditClient(userToEdit);
		} else {
			toast.error("Select one user to edit");
		}
	};

	const handleDeleteClient = async () => {
		// dispatch(showLoader());
		// const res = await DeleteClient({ client_ids: selected });
		// if (res.success) {
		// 	toast.success("Client Deleted Successfully")
		// 	await fetchClientsList();
		// 	dispatch(hideLoader());
		// } else {
		// 	toast.error(res || "Failed to delete Clients")
		// 	dispatch(hideLoader());
		// }
	};

	const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		const target = e.target as HTMLInputElement;
		setSearchTerm(target.value);
	};

	return (
		<Grid container sx={{ width: "100%", justifyContent: "center", mt: 8 }}>
			<Grid
				sx={{
					background: "#fff",
					boxShadow:
						"0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12)",
					borderRadius: "8px",
					width: "100%",
				}}
			>
				{/* Tabs */}
				<Tabs value={value} onChange={handleChange}>
					<Tab label="User Management" />
					<Tab label="Client Management" />
				</Tabs>
				<Divider />

				{/* Tab Panel Content */}
				<TabPanel value={value} index={0}>
					{/* Search Bar and Add User Button */}
					<Box display="flex" justifyContent="space-between" p={2}>
						<TextField
							value={searchTerm}
							variant="outlined"
							size="small"
							placeholder="Search"
							fullWidth
							onChange={handleSearch}
							sx={{ maxWidth: 300 }}
						/>
						<Box display="flex">
							{/* delete can be multiple but edit has to be one at a time */}
							{selected.length > 0 && (
								<Button
									onClick={handleDeleteUser}
									sx={{ mx: 1, color: "#CB4141" }}
									startIcon={<DeleteIcon />}
								>
									Delete
								</Button>
							)}
							{selected.length === 1 && (
								<Button
									onClick={handleUpdateUser}
									sx={{ mx: 1 }}
									startIcon={<EditIcon />}
								>
									Edit User
								</Button>
							)}
							<Button
								variant="contained"
								color="primary"
								sx={{ width: "155px" }}
								disabled={selected.length > 0} // checkbox selected then it will be disabled
								onClick={() => {
									setDialogTitle("New User");
									setUserButton("Add User");
									setEditData(null);
									setOpenDialog(true);
								}}
							>
								Add User
							</Button>
						</Box>
					</Box>
					<DataTable
						data={userList}
						columns={userColumn}
						selected={selected}
						setSelected={setSelected}
						page={page}
						totalCount={totalCount}
						setPage={setPage}
						rowsPerPage={rowsPerPage}
						setRowsPerPage={setRowsPerPage}
					/>
					<ReusableDialog
						open={openDialog}
						onClose={() => setOpenDialog(false)}
						title={dialogTitle}
						fields={userColumn}
						editData={editData}
						onSubmit={handleDialogSubmit}
						buttonText={userButton}
					/>
				</TabPanel>

				<TabPanel value={value} index={1}>
					<Box display="flex" justifyContent="space-between" p={2}>
						<TextField
							value={searchTerm}
							variant="outlined"
							size="small"
							placeholder="Search"
							fullWidth
							onChange={handleSearch}
							sx={{ maxWidth: 300 }}
						/>
						<Box display="flex">
							{/* delete can be multiple but edit has to be one at a time */}
							{selected.length > 0 && (
								<Button
									onClick={handleDeleteClient}
									sx={{ mx: 1, color: "#CB4141" }}
									startIcon={<DeleteIcon />}
								>
									Delete
								</Button>
							)}
							{selected.length === 1 && (
								<Button
									onClick={handleUpdateClient}
									sx={{ mx: 1 }}
									startIcon={<EditIcon />}
								>
									Edit Client
								</Button>
							)}
							<Button
								variant="contained"
								color="primary"
								sx={{ width: "155px" }}
								disabled={selected.length > 0} // checkbox selected then it will be disabled
								onClick={() => {
									setUserButton("Create Client");
									setDialogTitle("New Client");
									setEditData(null);
									setOpenDialog(true);
								}}
							>
								Add Client
							</Button>
						</Box>
					</Box>
					<DataTable
						data={clientList}
						columns={columnsClientManagement}
						selected={selected}
						setSelected={setSelected}
						page={page}
						totalCount={totalCount}
						setPage={setPage}
						rowsPerPage={rowsPerPage}
						setRowsPerPage={setRowsPerPage}
					/>
					<ReusableDialog
						open={openDialog}
						onClose={() => setOpenDialog(false)}
						title={dialogTitle}
						fields={columnsClientManagement}
						editData={editData}
						onSubmit={handleDialogSubmit}
						buttonText={userButton}
					/>
				</TabPanel>
			</Grid>
		</Grid>
	);
};

export default UserManagement;
