import { createSlice } from "@reduxjs/toolkit";

interface User {
	username: string;
	role: String;
	email: string;
	access_token: string;
	first_name: string;
	initials: string;
	last_name: string;
	roles: [];
}

// Initial State
interface AuthState {
	user: User | null;
	isAuthenticated: boolean;
	isMfaConfigured: boolean;
}

const savedUser: User | null = JSON.parse(
	localStorage.getItem("user") || "null",
);
const initialState: AuthState = {
	user: savedUser,
	isAuthenticated: !!savedUser,
	isMfaConfigured:false,
};
const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		login: (state, action) => {
			state.isAuthenticated = true;
			state.user = action.payload;
		},
		logout: (state) => {
			state.isAuthenticated = false;
			localStorage.removeItem("user");
			state.user = null;
			state.isMfaConfigured = false;
		},
		preLogin:(state,action) => {
			state.isMfaConfigured = action.payload;
		}
	},
});

export const { login, logout,preLogin } = authSlice.actions;

export default authSlice.reducer;
