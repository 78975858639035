import * as React from "react";
interface SvgComponentProps {
	[key: string]: any;
}
const EditIcon: React.FC<SvgComponentProps> = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={18}
		height={19}
		fill="none"
		{...props}
	>
		<mask
			id="a"
			width={18}
			height={19}
			x={0}
			y={0}
			maskUnits="userSpaceOnUse"
			style={{
				maskType: "alpha",
			}}
		>
			<path fill="currentColor" d="M0 .828h18v18H0z" />
		</mask>
		<g mask="url(#a)">
			<path
				fill="currentColor"
				d="M3.75 15.078H4.8l6.469-6.469-1.05-1.05-6.469 6.47v1.05Zm10.725-7.556-3.188-3.15 1.05-1.05a1.44 1.44 0 0 1 1.06-.431c.419 0 .772.143 1.06.43l1.05 1.05c.287.288.437.635.45 1.041.012.407-.126.754-.413 1.041l-1.069 1.069Zm-1.088 1.106-7.95 7.95H2.25v-3.187l7.95-7.95 3.188 3.187Z"
			/>
		</g>
	</svg>
);
export default EditIcon;
