import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import TablePagination from "@mui/material/TablePagination";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Checkbox } from "@mui/material";

const StyledTableFooter = styled(TableFooter)(({ theme }) => ({
	borderRadius: "0 0 5px 5px",
	background: "#F5F5F5",
}));

interface TableColumn {
	id: string;
	label: string;
	align?: "left" | "right" | "center";
	render?: (row: any) => React.ReactNode;
}

interface UserData {
	email: string;
	first_name: string;
	last_name: string;
	is_mfa_configured: boolean;
	is_mfa_login_enabled: boolean;
	is_sso_login_enabled: boolean;
	role: string[];
	user_id: number;
	client_name: string;
	storage: string;
	domain: string;
	client_id: number;
}

interface DataTableProps {
	data: UserData[];
	columns: TableColumn[];
	selected: number[];
	totalCount: number;
	setSelected: React.Dispatch<React.SetStateAction<number[]>>;
	page: number;
	setPage: React.Dispatch<React.SetStateAction<number>>;
	rowsPerPage: number;
	setRowsPerPage: React.Dispatch<React.SetStateAction<number>>;
	onPageChange?: (
		event: React.MouseEvent<HTMLButtonElement>,
		newPage: number
	) => void;
	onRowsPerPageChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

interface TablePaginationActionsProps {
	count: number;
	page: number;
	rowsPerPage: number;
	totalCount: number;
	onPageChange: (
		event: React.MouseEvent<HTMLButtonElement>,
		newPage: number
	) => void;
}

const TablePaginationActions: React.FC<TablePaginationActionsProps> = (
	props
) => {
	const { count, totalCount, page, rowsPerPage, onPageChange } = props;

	const handleFirstPageButtonClick = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		onPageChange(event, 0);
	};

	const handleBackButtonClick = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		onPageChange(event, page - 1);
	};

	const handleNextButtonClick = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		onPageChange(event, page + 1);
	};

	const handleLastPageButtonClick = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	return (
		<Box sx={{ flexShrink: 0, ml: 2.5 }}>
			<IconButton
				onClick={handleFirstPageButtonClick}
				disabled={page === 0}
				aria-label="first page"
			>
				&lt;&lt;
			</IconButton>
			<IconButton
				onClick={handleBackButtonClick}
				disabled={page === 0}
				aria-label="previous page"
			>
				&lt;
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="next page"
			>
				&gt;
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="last page"
			>
				&gt;&gt;
			</IconButton>
		</Box>
	);
};

const DataTable: React.FC<DataTableProps> = ({
	data,
	columns,
	selected,
	setSelected,
	page,
	setPage,
	totalCount,
	rowsPerPage,
	setRowsPerPage,
	onPageChange,
	onRowsPerPageChange,
}) => {
	const theme = useTheme();
	const [rows, setRows] = useState<UserData[]>(data);

	useEffect(() => {
		setRows(Array.isArray(data) ? data : []);
		setSelected([]);
	}, [data]);

	const handleChangePage = (
		event: React.MouseEvent<HTMLButtonElement> | null,
		newPage: number
	) => {
		setPage(newPage);
		if (onPageChange)
			onPageChange(event as React.MouseEvent<HTMLButtonElement>, newPage);
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
		if (onRowsPerPageChange) onRowsPerPageChange(event);
	};

	const handleClick = (id: number) => {
		const selectedIndex = selected.indexOf(id);
		let newSelected: number[] = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}

		setSelected(newSelected);
	};
	return (
		<Box>
			<TableContainer
				component={Paper}
				sx={{ maxHeight: 500, overflowY: "auto" }}
			>
				<Table
					sx={{ minWidth: 500 }}
					aria-label="custom pagination table"
				>
					<TableHead>
						<TableRow>
							{columns.map((column) => (
								<TableCell
									key={column.id}
									sx={{
										border: "none",
										background: "#F5F5F5",
										fontWeight: 700,
										p: 2,
									}}
								>
									<Typography
										sx={{
											fontSize: "0.875rem",
											color: theme.palette.primary.light,
										}}
										variant="body1"
									>
										{column.label}
									</Typography>
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{rows.length > 0 ? (
							rows.map((row) => {
								const isItemSelected =
									selected.indexOf(
										row.user_id || row.client_id
									) !== -1;

								return (
									<TableRow
										hover
										aria-checked={isItemSelected}
										tabIndex={-1}
										key={row.user_id}
										selected={isItemSelected}
									>
										{columns.map((column, index) => (
											<TableCell
												key={column.id}
												align={column.align || "left"}
											>
												{index === 0 ? (
													<Checkbox
														color="primary"
														checked={isItemSelected}
														onChange={() =>
															handleClick(
																row.user_id ||
																	row.client_id
															)
														}
													/>
												) : (
													<Typography
														sx={{
															fontSize: "1rem",
															color: theme.palette
																.text.primary,
														}}
														variant="body2"
													>
														{column.render
															? column.render(row)
															: row[
																	column.id as keyof UserData
															  ]}
													</Typography>
												)}
											</TableCell>
										))}
									</TableRow>
								);
							})
						) : (
							<TableRow>
								<TableCell
									colSpan={columns.length + 1}
									align="center"
								>
									<Typography variant="body1">
										No data available
									</Typography>
								</TableCell>
							</TableRow>
						)}
					</TableBody>
					<StyledTableFooter>
						<TableRow>
							<TablePagination
								rowsPerPageOptions={[
									5,
									10,
									25,
									{ label: "All", value: totalCount },
								]}
								colSpan={columns.length + 1}
								count={totalCount}
								rowsPerPage={rowsPerPage}
								page={page}
								onPageChange={handleChangePage}
								onRowsPerPageChange={handleChangeRowsPerPage}
								ActionsComponent={TablePaginationActions as any}
							/>
						</TableRow>
					</StyledTableFooter>
				</Table>
			</TableContainer>
		</Box>
	);
};

export default DataTable;
