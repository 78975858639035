import React, {
	useRef,
	useState,
	forwardRef,
	useImperativeHandle,
	useEffect ,
	Ref,
} from "react";
import "./FileUpload.css";
import CloudUpload from "../../assets/images/CloudUpload.svg";
import CloseIcon from "../../assets/images/close.svg"; // Import your custom close icon
import { uploadFile } from "../../api"; // Ensure this line is present
import { Typography, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Completed from "../../assets/images/CheckMarkGreen.svg";
import Progress from "../../assets/images/ProgressStatus.svg";
import ProgressIcon from "../../assets/images/ProgressIcon";
import { toast } from "react-toastify";

interface FileUploadProps {
	onUploadComplete: () => void;
	onBeforeUpload: () => void;
	setIsUploading: (value: boolean) => void;
	setShowTable: (value: boolean) => void;
	isFilesthere: boolean;
}
const FileUpload = forwardRef(
	(
		{ onUploadComplete, onBeforeUpload, isFilesthere }: FileUploadProps,
		ref: Ref<any>,
	) => {
		const theme = useTheme();
		const inputRef = useRef<HTMLInputElement | null>(null);
		const [selectedFiles, setSelectedFiles] = useState<File[] | []>([]);
		const [uploadProgress, setUploadProgress] = useState<{
			[key: string]: string;
		}>({});
		const [uploadStatus, setUploadStatus] = useState<{
			[key: string]: string;
		}>({}); // Track upload status for each file
		const [isHovering, setIsHovering] = useState(false);
		const [isUploading, setIsUploading] = useState(false);
		const [isNext, setIsNext] = useState(false);
		const [isAllFilesUploaded, setIsAllFilesUploaded] = useState(false);
		useImperativeHandle(ref, () => ({
			onChooseFile,
			clearFileInput,
		}));

		const handleFileChange = (
			event: React.ChangeEvent<HTMLInputElement>,
		) => {
			if (event.target.files) {
				const files = Array.from(event.target.files);
				setSelectedFiles((prev) => {
					const updatedFiles = [...prev, ...files];
					if (updatedFiles.length > 10){
						toast.error("This demo is limited to 10 documents at a time");
						clearFileInput();
						return [];
					} else {
						handleUploadAll(updatedFiles); // Start uploading after file selection
					} 
					return updatedFiles;
				});
			}
		};

		const onChooseFile = () => {
			if (inputRef.current) {
				inputRef.current.click();
			} else {
				toast.error("Input reference is undefined");
			}
		};
		const clearFileInput = () => {
			if (inputRef.current) inputRef.current.value = "";
			setSelectedFiles([]);
			setUploadProgress({});
			setUploadStatus({});
			setIsUploading(false);
			setIsNext(false);
		};

		const handleUploadAll = async (files: any) => {
			onBeforeUpload();
			setIsUploading(true);
			for (const file of files) {
				const formData = new FormData();
				formData.append("file", file);
				setUploadStatus((prev) => ({
					...prev,
					[file.name]: "uploading",
				})); // Set status to uploading

				try {
					await uploadFile(formData, (percentComplete) => {
						setUploadProgress((prev) => ({
							...prev,
							[file.name]: percentComplete,
						}));
					});
					setUploadStatus((prev) => ({
						...prev,
						[file.name]: "done",
					})); // Set status to done
				} catch (error) {
					const err:any = error;
					toast.error(err);
					setUploadStatus((prev) => ({
						...prev,
						[file.name]: "error",
					})); // Set status to error if needed
				}
			}
			setIsUploading(false);
			const allFilesCompleted = Object.values(uploadStatus).every(
        (status) => status === "done" || status === "error"
      );
			if (allFilesCompleted) {
				setTimeout(() => {
					onUploadComplete();
				}, 500);
				
      }
			
		};

		// const cancelUpload = (fileName: any) => {
		// 	setSelectedFiles((prev) =>
		// 		prev.filter((file) => file.name !== fileName),
		// 	);
		// 	setUploadProgress((prev) => {
		// 		const newProgress: { [key: string]: any } = { ...prev };
		// 		delete newProgress[fileName];
		// 		return newProgress;
		// 	});
		// 	setUploadStatus((prev) => {
		// 		const newStatus: { [key: string]: any } = { ...prev };
		// 		delete newStatus[fileName as string];
		// 		return newStatus;
		// 	});
		// };
		// const cancelUpload = (fileName: any) => {
		// 	setSelectedFiles((prev) =>
		// 		prev.filter((file) => file.name !== fileName),
		// 	);
		// 	setUploadProgress((prev) => {
		// 		const newProgress: { [key: string]: any } = { ...prev };
		// 		delete newProgress[fileName];
		// 		return newProgress;
		// 	});
		// 	setUploadStatus((prev) => {
		// 		const newStatus: { [key: string]: any } = { ...prev };
		// 		delete newStatus[fileName as string];
		// 		return newStatus;
		// 	});
		// };

		// const cancelAllUploads = () => {
		// 	clearFileInput();
		// };

		// const showFileTable = () => {
		// 	// setIsNext(true);
		// 	clearFileInput();
		// };

		const dragEnter = (e: React.DragEvent<HTMLDivElement>) => {
			e.preventDefault();
			setIsHovering(true);
		};

		const dragLeave = (e: React.DragEvent<HTMLDivElement>) => {
			e.preventDefault();
			setIsHovering(false);
		};

		const dropFile = (e: React.DragEvent<HTMLDivElement>) => {
			e.preventDefault();
			const droppedFiles = e.dataTransfer.files;
			setIsHovering(false);

			if (droppedFiles.length > 0) {
				const files = Array.from(droppedFiles);
				if (droppedFiles.length > 10){
					toast.error("This demo is limited to 10 documents at a time")
					console.log("Limit reached")
				} else {
					setSelectedFiles((prev) => {
						const updatedFiles = [...prev, ...files];
						handleUploadAll(updatedFiles); // Start uploading after file drop
						return updatedFiles;
					});
				}
			}
		};

		const allUploadsComplete = Object.values(uploadStatus).every(
			(status) => status === "done" || status === "error",
		);

		return (
			<div>
				<input
					ref={inputRef}
					type="file"
					accept=".pdf"
					multiple
					onChange={handleFileChange}
					style={{ display: "none" }}
				/>

{!selectedFiles.length ? ( isFilesthere && <div
					className={`drop-zone ${isHovering ? "hovering" : ""}`}
					onDragEnter={dragEnter}
					onDragLeave={dragLeave}
					onDragOver={(e) => e.preventDefault()}
					onDrop={dropFile}
				>
					
						<Box
							display="flex"
							flexDirection="column"
							alignItems="center"
							gap={2}
						>
							<img src={CloudUpload} alt="upload-logo" />
							<Typography variant="body1">
								Drag and drop files here
							</Typography>
							<Typography
								sx={{ color: theme.palette.text.secondary }}
								variant="body1"
							>
								or
							</Typography>
							<Button
								sx={{
									fontSize: "0.875rem",
									width: 184,
									height: 38,
									backgroundColor: theme.palette.primary.main,
									color: "#ffffff",
								}}
								variant="contained"
								onClick={onChooseFile}
							>
								Upload File(s)
							</Button>
						</Box> 
						</div>
					) : (
						<div className="file-container">
							<Typography
								sx={{
									fontSize: "1rem",
									color: theme.palette.text.primary,
									textAlign: "left",
								}}
								variant="body1"
							>
								Contract Data Upload (total files
								{selectedFiles.length})
							</Typography>
							{selectedFiles.map((file) => (
								<div key={file.name} className="file-card">
									<div className="file-status-wrapper">
										<div className="upload-status">
											{uploadStatus[file.name] ===
											"uploading" ? (
												<div className="status-container">
													{/* <img
														src={Progress}
														alt="progress-logo"
													/> */}
													<Box
														component="div"
														sx={{
															color: theme.palette
																.primary.main,
														}}
													>
														<ProgressIcon />
													</Box>
													<Typography
														sx={{
															color: theme.palette
																.primary.main,
														}}
														variant="body2"
													>
														In progress
													</Typography>
												</div>
											) : uploadStatus[file.name] ===
											  "done" ? (
												<div className="status-container">
													<img
														src={Completed}
														alt="completed-logo"
													/>
													<Typography
														className="status-text green"
														variant="body2"
													>
														Completed
													</Typography>
												</div>
											) : null}
										</div>
									</div>
									<Typography variant="body1">
										{file.name}
									</Typography>
									<Typography variant="body2">
										{(file.size / 1024).toFixed(2)} KB
									</Typography>
									<div className="progress-bg">
										<div
											className="progress"
											style={{
												background:
													theme.palette.primary.main,
												width: `${
													uploadProgress[file.name] ||
													0
												}%`,
											}}
										/>
									</div>
									<Typography
										className="progress-percentage"
										variant="body2"
									>
										{uploadProgress[file.name] || 0}%
									</Typography>
									{/* <Button
									{/* <Button
										onClick={() => cancelUpload(file.name)}
										disabled={!isUploading}
										color="error"
										style={{ minWidth: "40px" }} // Optional: set a min width for better alignment
									>
										<img
											src={CloseIcon}
											alt="Cancel"
											style={{ height: "20px" }}
										/>
									</Button> */}
								</div>
							))}
							<Box
								display="flex"
								alignItems={"flex-end"}
								justifyContent={"flex-end"}
								gap={2}
								mt={2}
							>
								{/* {!isNext && (
									<Button
										sx={{
											fontSize: "0.875rem",
											width: 134,
											height: 38,
											borderRadius: "3px",
											border: "none",
											backgroundColor: "#FFF",
											color: theme.palette.primary.main,
											cursor: "pointer",
										}}
										variant="text"
										onClick={cancelAllUploads}
									>
										Back
									</Button>
								)} */}
								{/* {!allUploadsComplete && (
								{/* {!allUploadsComplete && (
									<Button
										sx={{
											fontSize: "0.875rem",
											width: 134,
											height: 38,
											borderRadius: "3px",
											border: "1px solid #323232",
											color: theme.palette.text.primary,
											background: "#FFF",
										}}
										variant="contained"
										onClick={cancelAllUploads}
									>
										Cancel All
									</Button>
								)} */}
								{/* {!isNext && (
									<Button
										sx={{
											fontSize: "0.875rem",
											width: 134,
											height: 38,
											borderRadius: "3px",
											border: "1px solid #323232",
											backgroundColor: isUploading
												? "#787878"
												: theme.palette.primary.main,
											color: "#ffffff",
											cursor: isUploading
												? "not-allowed"
												: "pointer",
										}}
										variant="contained"
										disabled={isUploading}
										onClick={showFileTable}
									>
										Next
									</Button>
								)} */}
							</Box>
						</div>
					)}
				
			</div>
		);
	},
);

export default FileUpload;
