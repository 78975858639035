import React, { useState, useEffect } from "react";
import { Typography, Box, Checkbox, FormControlLabel } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid2";
import Login_photo from "../../assets/images/Allianz_login.png";
import Logo from "../../assets/images/Allianz.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../store/authSlice";
import { authorization, getUserInfo } from "../../api";
import { showLoader, hideLoader } from "../../store/loaderSlice";
import { RootState } from "../../store/appStore";
import frontendSettings from "../../../frontend_settings.json";
import LoginStep1 from "../../components/Login/LoginStep1";
import LoginStep2 from "../../components/Login/LoginStep2";
import ForgotPassword from "../../components/Login/ForgotPassword";
import OtpScreen from "../../components/Login/LoginStep2/OtpScreen";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dialog from "../../components/Dialog/Dialog";
import { privacyPolicySections } from "../../constants/common";

const isDev =
	window.location.hostname === "localhost" ||
	window.location.hostname === "genairag.azurewebsites.net" ||
	window.location.hostname === "contractpulsedev.ai.kearney.com";
const isStage = window.location.hostname === "contractpulsestg.ai.kearney.com";

const __TENANT_ID__ = "5dc645ed-297f-4dca-b0af-2339c71c5388";
const __CLIENT_ID__ = isDev
	? "104d7c5b-35db-452c-af9c-df0a027cc2eb"
	: isStage
	? "c9d1d263-6ef7-4a19-894f-fdc5db5f8ba1"
	: "579e681f-902b-4228-90e1-efcf1151c6e8"; // default to PROD
const __REDIRECT_URI__ = `${window.location.origin}/`;

// const __TENANT_ID__ = import.meta.env.VITE_TENANT_ID;
// const __CLIENT_ID__ = import.meta.env.VITE_CLIENT_ID;
// const __REDIRECT_URI__ = import.meta.env.VITE_REDIRECT_URI;

const Login: React.FC = () => {
	const [isOtpScreen, setIsOtpScreen] = useState(false);
	const [isForgotPassword, setIsForgotPassword] = useState(false);
	const [userData, setUserData] = useState({ email: "", password: "" });
	const [privacyChecked, setPrivacyChecked] = useState(false); // State for the checkbox
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const theme = useTheme();
	const [dialogOpen, setDialogOpen] = useState(false);
	const [dialogTitle, setDialogTitle] = useState("");
	const isAuthenticated = useSelector(
		(state: RootState) => state.auth.isAuthenticated
	);

	useEffect(() => {
		const getAuthToken = async () => {
			const urlParams = new URLSearchParams(window.location.search);
			const code = urlParams.get("code");
			try {
				if (code) {
					dispatch(showLoader());
					const payload = { code, redirect_uri: __REDIRECT_URI__ };
					const response = await authorization(payload);
					const userInfo = await getUserInfo();

					const mergedData = {
						...response,
						...userInfo?.data,
						username: `${userInfo.data.first_name} ${userInfo?.data.last_name}`,
						initials: `${userInfo?.data.first_name[0]}${userInfo?.data.last_name[0]}`,
					};
					localStorage.setItem("user", JSON.stringify(mergedData));

					if (!response) {
						dispatch(hideLoader());
						navigate("/", { replace: true });
						toast.error("Something went wrong!");
						return;
					}
					dispatch(login(mergedData));
				}
			} catch (error) {
				navigate("/", { replace: true });
				dispatch(hideLoader());
				const err: any = error;
				toast.error("Something went wrong !");
			}
		};
		if (!isAuthenticated) getAuthToken();
	}, []);

	useEffect(() => {
		if (isAuthenticated) {
			navigate("/homepage", { replace: true });
			dispatch(hideLoader());
		}
	}, [isAuthenticated]);

	const handleOpenDialog = (data: string) => {
		setDialogTitle(data);
		setDialogOpen(true);
	};

	const handleCloseDialog = () => {
		setDialogOpen(false);
		setDialogTitle("");
	};
	return (
		<Grid
			container
			sx={{
				overflow: "hidden",
				background: theme.palette.background.default,
				height: "100vh",
			}}
		>
			<Grid size={{ xs: 12, md: 6 }}>
				<Grid>
					<Box
						component="img"
						sx={{
							ml: 2,
							mt: 2,
							width: "200px",
							height: "60px",
						}}
						src={
							frontendSettings
								? frontendSettings?.login?.photo
								: Logo
						}
						alt="Logo"
					/>
				</Grid>
				<Grid
					container
					sx={{ height: "80%" }}
					justifyContent="center"
					alignItems="center"
				>
					{!isOtpScreen && !isForgotPassword && (
						<Grid>
							<LoginStep1
								__TENANT_ID__={__TENANT_ID__}
								__CLIENT_ID__={__CLIENT_ID__}
								__REDIRECT_URI__={__REDIRECT_URI__}
								welcomeMessage={`Welcome to ${
									frontendSettings?.login?.SSO || ""
								}`}
								signInPrompt="Please Sign in to your account"
								privacyChecked={privacyChecked}
							/>
							<Box
								sx={{
									mt: 2,
									fontWeight: 400,
									color: theme.palette.secondary.light,
									textAlign: "center",
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									gap: "20px",
									pl: 1,
									pr: 1,
								}}
							>
								<Typography
									sx={{
										background:
											theme.palette.secondary.light,
										width: "100%",
										height: "1px",
									}}
								/>
								<Typography>or</Typography>
								<Typography
									sx={{
										background:
											theme.palette.secondary.light,
										width: "100%",
										height: "1px",
									}}
								/>
							</Box>
							<LoginStep2
								setIsOtpScreen={setIsOtpScreen}
								setIsForgotPassword={setIsForgotPassword}
								setUserData={setUserData}
								privacyChecked={privacyChecked}
							/>
							{/* <FormControlLabel
								control={
									<Checkbox
										checked={privacyChecked}
										onChange={(e) =>
											setPrivacyChecked(e.target.checked)
										}
										color="primary"
									/>
								}
								label="I agree to the Privacy Policy"
								labelPlacement="end"
							/>

							<Typography
								variant="body2"
								color="primary"
								onClick={() => {
									console.log("Privacy Policy clicked");
								}}
								style={{ cursor: "pointer" }}
							>
								Click here to read our Privacy Policy
							</Typography> */}
							<Box display={"flex"} alignItems="center">
								<Checkbox
									checked={privacyChecked}
									onChange={(e) =>
										setPrivacyChecked(e.target.checked)
									}
									color="primary"
								/>
								<Typography
									variant="body2"
									// color="primary"

									style={{ cursor: "pointer" }}
								>
									I accept the{" "}
									<span
										style={{
											color: theme.palette.primary.main,
											textDecoration: "underline",
										}}
										onClick={() =>
											handleOpenDialog(
												"Terms and Conditions"
											)
										}
									>
										Terms and Conditions
									</span>{" "}
									and{" "}
									<span
										style={{
											color: theme.palette.primary.main,
											textDecoration: "underline",
										}}
										onClick={() =>
											handleOpenDialog("Privacy Policy")
										}
									>
										Privacy Policy
									</span>
									.
								</Typography>
							</Box>
						</Grid>
					)}
					{isForgotPassword && (
						<ForgotPassword
							setIsForgotPassword={setIsForgotPassword}
						/>
					)}
					{isOtpScreen && (
						<OtpScreen
							setIsOtpScreen={setIsOtpScreen}
							userData={userData}
						/>
					)}
				</Grid>
			</Grid>
			<Grid size={{ xs: 12, md: 6 }}>
				<Box
					component="img"
					src={
						frontendSettings
							? frontendSettings?.login?.loginPhoto
							: Login_photo
					}
					sx={{
						backgroundPosition: "center center",
						backgroundRepeat: "no-repeat",
						backgroundSize: "contain",
						width: "100%",
						height: "100vh",
					}}
					alt="Login Photo"
				/>
			</Grid>
			{/* Dialog  for privay screen*/}
			<Dialog
				open={dialogOpen}
				onClose={handleCloseDialog}
				title={dialogTitle}
				sections={privacyPolicySections.data}
			/>
		</Grid>
	);
};

export default Login;
