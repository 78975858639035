import React, { useState, useEffect, useRef } from "react";
import { Box, Divider, Typography, Button, Tooltip } from "@mui/material";
import FileUpload from "../../components/Management/FileUpload";
import CustomPaginationActionsTable from "../../components/Management/FileTable";
import {
	listFiles,
	ListOfFiles,
	viewContractFile,
	downloadExport,
	getContractStatus,
	contractStatus,
} from "../../api";
import NotFound from "../../assets/images/Rafiki.svg";
import { useTheme } from "@mui/material/styles";
import { showLoader, hideLoader } from "../../store/loaderSlice";
import { useDispatch } from "react-redux";
import Grid from "@mui/material/Grid2";
import AI from "../../assets/images/AI.svg";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal"; // Import Modal
import CloseIcon from "../../assets/images/close.svg"; // Optional: for a close button
import { MenuItem, Select } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import LinearProgress, {
	LinearProgressProps,
} from "@mui/material/LinearProgress";
import DefaultUpload from "../../assets/images/DefaultUpload";
import VectorIcon from "../../assets/images/VectorIcon";
import ProgressIcon from "../../assets/images/ProgressIcon";
import Completed from "../../assets/images/CheckMarkGreen.svg";
import { setProgressStatus } from "../../store/progressStatus";

interface ViewConDataItem {
	parameter: string;
	value: string;
	reasoning: string;
	reference: string;
}
interface fileData {
	file_id: number;
	file_name: string;
	status: string;
}
const Upload = () => {
	const navigate = useNavigate();
	const [files, setFiles] = useState<ListOfFiles[]>([]);
	const [isUploading, setIsUploading] = useState(false); // State to track uploading status
	const [showTable, setShowTable] = useState(true);
	const [modalOpen, setModalOpen] = useState(false);
	const [progstatus, setProgStatus] = useState("Not started");
	const [contractId, setContractId] = useState<any>(null);
	const [progVal, setProgVal] = useState(0);
	const [viewConData, setViewConData] = useState<ViewConDataItem[] | null>(
		null
	);
	const [rows, setRows] = useState<any[]>([]);
	const theme = useTheme();
	const dispatch = useDispatch();
	const fileUploadRef = useRef<any>(null);
	const pollingIntervalRef = useRef<any>(null);
	const [ispolling, setIspolling] = useState(false);
	const handleUploads = () => {
		if (fileUploadRef.current) {
			fileUploadRef.current.onChooseFile();
		}
	};
	const [selectedFileId, setSelectedFileId] = useState<number | ''>('');

	const handleFileSelect = (event: any) => {
		const fileId = event.target.value;
		setSelectedFileId(fileId);
		
		const selectedFile = rows.find(row => row.file_id === fileId);
		if (selectedFile) {
			ViewContracts([selectedFile]); 
		}
	};

	function LinearProgressWithLabel(
		props: LinearProgressProps & { value: number }
	) {
		return (
			<Box sx={{ display: "flex", alignItems: "center" }}>
				<Box sx={{ width: "100%", mr: 1 }}>
					<LinearProgress variant="determinate" {...props} />
				</Box>
				<Box sx={{ minWidth: 35 }}>
					<Typography
						variant="body2"
						sx={{ color: "text.secondary" }}
					>{`${Math.round(props.value)}%`}</Typography>
				</Box>
			</Box>
		);
	}
	const handleClose = () => {
		setModalOpen(false);
	};
	const fetchStatus = async () => {
		try {
			const fetchedStatus: contractStatus[] = await getContractStatus();
			if (fetchedStatus && fetchedStatus.length > 0) {
				const response = fetchedStatus[0]; // fetching 1st result as per BE api

				setProgStatus(response.status);
				setProgVal(parseInt(response.percent_status) || 0);
				setContractId(response.contract_id);
				dispatch(
					setProgressStatus({
						percentStatus: parseInt(response.percent_status) || 0,
					})
				);
			}
		} catch (error) {
			const err:any = error;
			console.error("Error fetching status:", err);
		}
	};
	const fetchFiles = async () => {
		try {
			dispatch(showLoader());
			const fetchedFiles: ListOfFiles[] = await listFiles();
			if (fetchedFiles && fetchedFiles.length > 0) {
				setFiles(fetchedFiles);
				const fileData = fetchedFiles.map((f) => ({
					file_id: f.file_id,
					file_name: f.file_name,
				}));
				setRows(Array.isArray(fileData) ? fileData : []);
				if (fileData.length > 0) {
					setSelectedFileId(fileData[0].file_id);
				}
			}
			// else if (fetchedFiles && Array.isArray(fetchedFiles.data)) {
			// 	setFiles(fetchedFiles.data);
			// }
			else {
				console.warn("Fetched files is not an array:", fetchedFiles);
				setFiles([]);
			}
		} catch (error) {
			const err:any = error
			toast.error("Error fetching files:", err);
			setFiles([]);
			dispatch(hideLoader());
		}
		dispatch(hideLoader());
	};
	const handleRefresh = async () => {
		await fetchFiles();
		// await fetchStatus();
	};
	const ViewContracts = (file: fileData[]) => {
		handleView(file);
	};
	const handleView = async (file: fileData[]) => {
		// completedFiles = [];

		dispatch(showLoader());
		try {
			const res = await viewContractFile(file[0].file_id);
			setViewConData(res); // Assuming `res` contains the image data
			setModalOpen(true);
		} catch (error) {
			toast.error(
				`Failed to view contract for the file ${file[0].file_name}`
			);
			dispatch(hideLoader());
		}
		dispatch(hideLoader());
	};
	const handleDisableDownload = (file: fileData[]) => {
		return !(
			file[0]?.status === "Validation_pending" ||
			file[0]?.status === "Completed" ||
			file[0]?.status === ""
		);
	};
	const handleDownload = async (files: fileData[]) => {
		// Find the selected file from the array
		const selectedFile = files.find(file => file.file_id === selectedFileId);
		if (!selectedFile) {
			toast.error('No file selected');
			return;
		}
	
		dispatch(showLoader());
		if (handleDisableDownload([selectedFile])) {
			dispatch(hideLoader());
			return;
		}
	
		const anchor = document.createElement("a");
		try {
			const sasUrl = await downloadExport(
				selectedFile.file_name.split("/").at(0) || "",
				selectedFile.file_id
			);
			const response = await fetch(sasUrl);
			if (!response.ok) {
				throw new Error(`Failed to fetch: ${response.statusText}`);
			}
			const blob = await response.blob();
			const url = window.URL.createObjectURL(blob);
			anchor.href = url;
			anchor.download = selectedFile.file_name.replace(/\.pdf$/, ".xlsx");
			anchor.style.display = "none";
			document.body.appendChild(anchor);
			anchor.click();
			document.body.removeChild(anchor);
			toast.success(
				`Extra attributes ${selectedFile.file_name.replace(
					/\.pdf$/,
					".xlsx"
				)} downloaded successfully!`
			);
		} catch (error) {
			toast.error(
				`Failed to download extra attributes for file ${selectedFile.file_name}`
			);
			dispatch(hideLoader());
		}
		dispatch(hideLoader());
	};

	useEffect(() => {
		fetchFiles();
		fetchStatus();

		// Set up polling every 15 seconds
		pollingIntervalRef.current = setInterval(fetchStatus, 15000);

		// Cleanup interval on component unmount
		return () => {
			clearInterval(pollingIntervalRef.current);
			setIspolling(false);
		};
	}, [ispolling]);

	useEffect(() => {
		// Check if progress value reaches 100%
		if (progVal >= 100) {
			// Clear the interval when progress is complete
			clearInterval(pollingIntervalRef.current);
			console.log("Progress completed at 100%");
		}
	}, [progVal]);

	return (
		<Grid container sx={{ width: "100%", justifyContent: "center" }}>
			<Grid
				sx={{
					flex: 1,
					mt: 8,
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					background: "#fff",
					boxShadow:
						"0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12)",
					borderRadius: "8px",
					width: "100%",
				}}
			>
				<Grid
					container
					sx={{ width: "100%" }}
					justifyContent="space-between"
				>
					<Typography
						align="center"
						sx={{ fontSize: "1.15rem", px: 2, p: 1.5 }}
					>
						Contract Management - Upload
					</Typography>
				</Grid>
				<Divider sx={{ width: "100%" }} />
				<Grid
					justifyContent="center"
					alignItems="center"
					sx={{
						width: "100%",
						display: "flex",
						flexDirection: "row",
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							gap: "25px",
							m: "30px",
							width: "100%",
						}}
					>
						{files.length === 0 && showTable ? (
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
								}}
							>
								{/* <img
									src={NotFound}
									alt="Not Found"
									style={{
										width: "500px",
										height: "auto",
										marginBottom: "10px",
									}}
								/> */}
								<Box
									component="div"
									sx={{
										color: theme.palette.secondary.main,
									}}
								>
									<DefaultUpload />
								</Box>
								<Typography align="center">
									Upload up to 10 document(s) to get started
								</Typography>
								<Typography align="center">
									.pdf (under 2mb)
								</Typography>
							</Box>
						) : null}
						{showTable && !isUploading && files.length > 0 && (
							<Box
								display="flex"
								alignItems="center"
								justifyContent={"space-between"}
								mb={3}
							>
								<Typography variant="body1"></Typography>
								<Box display="flex" alignItems="center" gap={2}>
									<Button
										variant="outlined"
										sx={{
											fontSize: "0.875rem",
											width: 220,
											height: 38,
											borderRadius: "3px",
											background: "#FFF",
											color: theme.palette.primary.main,
											cursor: "pointer",
											borderColor:
												progVal < 100
													? "rgba(0, 0, 0, 0.38)"
													: theme.palette.primary
															.main,
										}}
										onClick={() =>
											navigate("/homepage/intelligence")
										}
										startIcon={<VectorIcon />}
									>
										Contract Insights
									</Button>
									<Button
										sx={{
											fontSize: "0.875rem",
											width: 200,
											height: 38,
											color: "#ffffff",
											backgroundColor:
												theme.palette.primary.main,
											cursor: "pointer",
											borderColor: "#ffffff",
										}}
										variant="contained"
										onClick={() => ViewContracts(files)}
									>
										View Results
									</Button>
									<Tooltip
										title={
											files?.length >= 10
											? 'Sorry, this demo is limited to 10 files'
											: 'Click to add files'
										}
										placement="top"
										>
										<span style={{ display: 'inline-block' }}>
											<Button
											sx={{
												fontSize: '0.875rem',
												width: 200,
												height: 38,
												backgroundColor: theme.palette.primary.main,
												color: '#ffffff',
											}}
											variant="contained"
											onClick={handleUploads}
											disabled={files?.length >= 10}
											>
											Add File(s)
											</Button>
										</span>
									</Tooltip>
								</Box>
							</Box>
						)}
						<FileUpload
							onUploadComplete={() => {
								fetchFiles();
								setIsUploading(false);
								setIspolling(true);
								if (fileUploadRef.current) {
									fileUploadRef.current.clearFileInput(); // Trigger clearFileInput when upload completes
								}
								setShowTable(true);
							}}
							onBeforeUpload={() => {
								setShowTable(false);
							}}
							setIsUploading={setIsUploading}
							setShowTable={setShowTable}
							ref={fileUploadRef}
							isFilesthere={files?.length == 0}
						/>
						{showTable && !isUploading && files.length > 0 && (
							<Box
								sx={{
									width: "100%",
									borderRadius: "6px",
									border: "1px dashed var(--kearney-black-60787878, #787878)",
									padding: "24px",
									display: "flex",
									alignItems: "center",
								}}
							>
								{progVal !== 100 && (
									<Box
										display="flex"
										alignItems="center"
										gap={1}
										sx={{ width: "10%" }}
									>
										<Box
											component="div"
											sx={{
												color: theme.palette.primary
													.main,
											}}
										>
											<ProgressIcon />
										</Box>
										<Typography
											sx={{
												color: theme.palette.primary
													.main,
											}}
											variant="body2"
										>
											In progress
										</Typography>
									</Box>
								)}
								{progstatus.toLowerCase() === "completed" && (
									<Box
										display="flex"
										alignItems="center"
										gap={1}
										sx={{ width: "10%" }}
									>
										<Box
											component="div"
											sx={{
												color: theme.palette.primary
													.main,
											}}
										>
											<img
												src={Completed}
												alt="completed-logo"
											/>
										</Box>
										<Typography
											sx={{
												color: theme.palette.primary
													.main,
											}}
											variant="body2"
										>
											Completed
										</Typography>
									</Box>
								)}

								<Box
									sx={{
										width: "100%",
										padding: "10px",
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
										border: "1px solid var(--kearney-black-20-d-2-d-2-d-2, #D2D2D2)",
										background:
											"var(--kearney-black-0-ffffff, #FFF)",
									}}
								>
									<Typography>
										Contract Extraction Progress
									</Typography>
									<Box sx={{ width: "85%" }}>
										<LinearProgressWithLabel
											value={progVal}
										/>
									</Box>
								</Box>
							</Box>
						)}
						{showTable && !isUploading && files.length > 0 && (
							<Box
								sx={{
									display: "flex",
									justifyContent: "flex-end",
									alignItems: "center",
								}}
							>
								<Button
									sx={{
										fontSize: "0.875rem",
										width: 100,
										height: 38,
										backgroundColor:
											theme.palette.primary.main,
										color: "#ffffff",
									}}
									variant="contained"
									onClick={handleRefresh}
								>
									Refresh
								</Button>
							</Box>
						)}
						{showTable && !isUploading && files.length > 0 && (
							<CustomPaginationActionsTable
								files={files}
								fetchFiles={fetchFiles}
							/>
						)}
					</Box>
				</Grid>
			</Grid>
			<Modal
				open={modalOpen}
				onClose={handleClose}
				aria-labelledby="modal-title"
				aria-describedby="modal-description"
			>
				<Box
					sx={{
						bgcolor: "background.paper",
						outline: "none",
						position: "relative",
						maxHeight: "90vh",
						overflowY: "auto",
						margin: "auto",
						mt: 5,
						width: 1120,
						alignItems: "center",
						borderRadius: "16px",
					}}
				>
					<Box
						display="flex"
						alignItems="center"
						justifyContent="center"
						sx={{
							borderBottom: `1px solid ${theme.palette.primary.main}`,
							paddingBottom: 2,
							paddingTop: 2,
						}}
					>
						<Typography
							sx={{
								position: "relative",
								fontSize: "1rem",
								color: theme.palette.text.primary,
							}}
							variant="body2"
						>
							View Results
						</Typography>{" "}
						{/* Use Typography */}
						<IconButton
							onClick={handleClose}
							sx={{ position: "absolute", top: 5, right: 5 }}
						>
							<img src={CloseIcon} alt="x" />
						</IconButton>
					</Box>
					<Box sx={{ p: 3 }}>
						<Box
							sx={{
								display: "flex",
								justifyContent: "space-between",
								mb: 3,
								mt: 1,
							}}
						>
							<Typography>Select Document</Typography>
							<Select
								variant="outlined"
								sx={{ minWidth: 300, color: "#1E1E1E", height: 40 }}
								value={selectedFileId}
								onChange={handleFileSelect}
							>
							{rows.map((row) => (
								<MenuItem key={row.file_id} value={row.file_id}>
									{row.file_name}
								</MenuItem>
							))}
							</Select>
							<Button
						sx={{
							fontSize: "0.875rem",
							width: 150,
							height: 38,
							backgroundColor: theme.palette.primary.main,
							color: "#ffffff",
						}}
						onClick={() => handleDownload(files)}
						disabled={!selectedFileId}
						variant="contained"
						color="primary"
					>
						Export Results
					</Button>
						</Box>
						<Table>
							<TableHead>
								<TableRow
									sx={{
										border: "none",
										background: "#F5F5F5",
										fontWeight: 700,
										fontSize: "0.875rem",
										color: theme.palette.text.primary,
									}}
								>
									<TableCell sx={{ width: "30%" }}>
										<Typography>Attribute</Typography>
									</TableCell>
									<TableCell sx={{ width: "25%" }}>
										<Typography>Value</Typography>
									</TableCell>
									<TableCell sx={{ width: "30%" }}>
										<Typography variant="body2">
											Reasoning
										</Typography>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{viewConData && viewConData.length > 0 ? (
									viewConData.map(
										(
											item: ViewConDataItem,
											index: number
										) => (
											<TableRow
												key={index}
												sx={{
													color: theme.palette.text
														.primary,
												}}
											>
												<TableCell
													sx={{ width: "30%" }}
												>
													{item.parameter}
												</TableCell>
												<TableCell
													sx={{ width: "25%" }}
												>
													{item.value}
												</TableCell>
												<TableCell
													sx={{ width: "30%" }}
												>
													{item.reasoning}
												</TableCell>
											</TableRow>
										)
									)
								) : (
									<TableRow>
										<TableCell colSpan={4} align="center">
											We're still processing your documents, check back later...
										</TableCell>
									</TableRow>
								)}
							</TableBody>
						</Table>
						<Box
							display={"flex"}
							justifyContent={"flex-end"}
							alignItems={"center"}
							sx={{ mt: 2, mb: 4 }}
						>
							<Button
								onClick={handleClose}
								sx={{
									fontSize: "0.875rem",
									width: 150,
									height: 38,
									backgroundColor: "#ffffff",
									color: theme.palette.primary.main,
									border: `1px solid ${theme.palette.primary.main}`,
								}}
								variant="contained"
								color="primary"
							>
								Cancel
							</Button>
						</Box>
					</Box>
				</Box>
			</Modal>
		</Grid>
	);
};

export default Upload;

{
}
