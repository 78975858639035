import React, { useState, useEffect } from "react";
import { Box, TextField, Button, Typography, Alert } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import BackArrow from "../../../assets/images/BackArrow.svg";
import { QRCodeSVG } from "qrcode.react";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../store/appStore";
import { useSelector, useDispatch } from "react-redux";
import {
	getEmailAuthcode,
	setupmfa,
	loginLocal,
	getUserInfo,
} from "../../../api";
import { toast } from "react-toastify";
import { showLoader, hideLoader } from "../../../store/loaderSlice";
import { login } from "../../../store/authSlice";

interface OtpScreenProps {
	setIsOtpScreen: (value: boolean) => void;
	userData: { email: string; password: string };
}

const OtpInput: React.FC<{
	value: string;
	onChange: (otp: string) => void;
	error: boolean;
}> = ({ value, onChange, error }) => {
	const handleChange = (
		index: number,
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const newOtp = value.split("");
		newOtp[index] = event.target.value.slice(-1); // Only take the last character
		onChange(newOtp.join(""));

		if (event.target.value && index < 5) {
			document.getElementById(`otp-input-${index + 1}`)?.focus();
		}
	};
  const handleKeyDown = (
		index: number,
		event: React.KeyboardEvent<HTMLInputElement>
	) => {
		if (event.key === "Backspace" && !value[index] && index > 0) {
			document.getElementById(`otp-input-${index - 1}`)?.focus();
		}
	};

	return (
		<Box display="flex" justifyContent="space-between" sx={{ mb: 2}}>
			{[...Array(6)].map((_, index) => (
				<TextField
					key={index}
					id={`otp-input-${index}`}
					value={value[index] || ""}
					onChange={(e) =>
						handleChange(
							index,
							e as React.ChangeEvent<HTMLInputElement>
						)
					}
          onKeyDown={(e) => handleKeyDown(index, e as React.KeyboardEvent<HTMLInputElement>)}
					variant="outlined"
					error={error}
          InputProps={{
						inputProps: {
							style: { 
                height: "40px",
                textAlign: 'center' }
						}
					}}
					sx={{
						maxLength: 1,
            textAlign: "center",
						flex: 1,
						mx: 0.5,
						"& .MuiOutlinedInput-root": {
							"& fieldset": {
								borderColor: error ? "red" : undefined,
							},
						},
					}}
				/>
			))}
		</Box>
	);
};

const OtpScreen: React.FC<OtpScreenProps> = ({ setIsOtpScreen, userData }) => {
	const theme = useTheme();
	const [otp, setOtp] = useState("");
	const [error, setError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [success, setSuccess] = useState("");
	const [isEmailcode, setIsEmailcode] = useState(false);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [mfaSecret, setMfaSecret] = useState<string>(""); // Store the mfaSecret here
	const isMfaConfigured = useSelector(
		(state: RootState) => state.auth.isMfaConfigured
	);

	useEffect(() => {
		if (!isMfaConfigured && mfaSecret === "") {
			setMfaSecret(generateMfaSecret()); // Only generate secret if MFA isn't configured and secret isn't set yet
		}
	}, [isMfaConfigured, mfaSecret]);

	// Function to generate MFA secret
	function generateMfaSecret(): string {
		const length = 31;
		const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ234567";
		const charString = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
		const result2 =
			charString[Math.round(Math.random() * (charString.length - 1))];
		let result = "";
		for (let i = length; i > 0; --i) {
			result += chars[Math.round(Math.random() * (chars.length - 1))];
		}
		return result2 + result;
	}

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		if (otp.length < 6) {
			setError(true);
			setErrorMessage("Complete the OTP.");
			return;
		}
		setError(false);
		const userInfo: any = { ...userData, code: otp };

		if (!isMfaConfigured && !isEmailcode) {
			dispatch(showLoader());
			userInfo.mfa_secret = mfaSecret;
			const mfaResp: any = await setupmfa(userInfo);
			if (mfaResp && mfaResp?.data?.access_token) {
				setOtp("");
				toast.success(mfaResp?.payload);
				const userData = {
					username: "",
					role: "",
					email: "",
					access_token: mfaResp?.data?.access_token,
				};
				localStorage.setItem("user", JSON.stringify(userData));
				const userInfo: any = await getUserInfo();
				const mergedData = {
					...mfaResp?.data,
					...userInfo?.data,
					username: `${userInfo.data.first_name} ${userInfo?.data.last_name}`,
					initials: `${userInfo?.data.first_name[0]}${userInfo?.data.last_name[0]}`,
				};
				localStorage.setItem("user", JSON.stringify(mergedData));
				dispatch(login(mergedData));
				dispatch(hideLoader());
			} else {
				setError(true);
				setErrorMessage(
					"Incorrect code entered! Please check and try again."
				);
				dispatch(hideLoader());
			}
		} else {
			await verifyOtp();
		}
	};

	const verifyOtp = async () => {
		try {
			dispatch(showLoader());
			const userInfoLocal: any = {
				...userData,
				code: otp,
				code_source: isEmailcode ? "mail" : "auth_app",
			};
			const loginResp: any = await loginLocal(userInfoLocal);

			if (loginResp && loginResp?.data && loginResp?.data?.access_token) {
				const userData = {
					username: "",
					role: "",
					email: "",
					access_token: loginResp?.data?.access_token,
				};
				localStorage.setItem("user", JSON.stringify(userData));
				const userInfo: any = await getUserInfo();
				const mergedData = {
					...loginResp?.data,
					...userInfo?.data,
					username: `${userInfo?.data.first_name} ${userInfo?.data.last_name}`,
					initials: `${userInfo?.data.first_name[0]}${userInfo?.data.last_name[0]}`,
				};
				localStorage.setItem("user", JSON.stringify(mergedData));
				dispatch(login(mergedData));
			} else {
				toast.error(
					loginResp?.payload || "Something went wrong"
				);
				dispatch(hideLoader());
			}
		} catch (error) {
      dispatch(hideLoader());
      const err:any = error;
      toast.error(
        err?.payload || "Something went wrong"
      );
		}
	};

	const handleBack = () => {
		setIsOtpScreen(false);
		setIsEmailcode(false);
		setOtp("");
	};

	const handleRxEmailCode = async () => {
		try {
			dispatch(showLoader());
			const mailResp = await getEmailAuthcode(userData);
			if (mailResp && mailResp.success) {
				setIsEmailcode(true);
				setOtp("")
				toast.success(mailResp?.message);
				dispatch(hideLoader());
			} else {
				toast.error(
					mailResp?.payload || "Something went wrong"
				);
				dispatch(hideLoader());
			}
		} catch (error) {
			setIsEmailcode(false);
      dispatch(hideLoader());
      const err:any = error;
      toast.error(
        err?.payload || "Something went wrong"
      );
		}
	};

	return (
		<Box
			textAlign="left"
			sx={{
				width: "500px",
				mx: "auto",
				color: theme.palette.primary.light,
			}}
		>
			<Box
				display="flex"
				alignItems="center"
				mb={5}
				gap={1}
				sx={{ cursor: "pointer" }}
				onClick={handleBack}
			>
				<img src={BackArrow} alt="Back" />
				<Typography
					variant="body1"
					sx={{
						fontSize: "1.25rem",
						color: theme.palette.text.primary,
					}}
				>
					Back
				</Typography>
			</Box>
			{!isMfaConfigured && !isEmailcode &&  (
				<Box sx={{ mb: 1 }}>
					<Typography
						variant="body1"
						sx={{
							mb: 2,
							fontSize: "1.23rem",
							color: theme.palette.primary.light,
						}}
					>
						Scan the QR code with an authentication application,
						such as Microsoft Authenticator, on your phone.
					</Typography>
					<QRCodeSVG
						value={`otpauth://totp/Contract Intelligence(${userData.email})?secret=${mfaSecret}&algorithm=SHA1&digits=6&period=30`}
						size={150}
					/>
				</Box>
			)}
			{!isEmailcode && (
				<Typography
					variant="body1"
					sx={{
						mb: 2,
						fontSize: "1.23rem",
						color: theme.palette.primary.light,
					}}
				>
					Please enter the code seen on the Authenticator App
				</Typography>
			)}
			{isEmailcode && (
        <Box>
				<Typography
					variant="body1"
					sx={{
						mb: 2,
						fontSize: "1.23rem",
						color: theme.palette.primary.light,
					}}
				>
					Please enter the code we have sent to your Email ID{" "}
					{userData.email}
				</Typography>
        {/* <Typography
						sx={{
              mb: 1,
							textAlign: "left",
							color: theme.palette.primary.main,
							cursor: "pointer",
							fontSize: "1.25rem",
							textDecoration: "none",
						}}
						onClick={handleRxEmailCode}
					>
						Resend Email
					</Typography> */}
          </Box>
			)}
			<form onSubmit={handleSubmit}>
				<OtpInput value={otp} onChange={setOtp} error={error} />
				{error && (
					<Alert severity="error" sx={{ mb: 2 }}>
						{errorMessage}
					</Alert>
				)}
				{success && (
					<Alert severity="success" sx={{ mb: 2 }}>
						{success}
					</Alert>
				)}
				
					<Typography
						sx={{
							textAlign: "left",
							color: theme.palette.primary.main,
							cursor: "pointer",
							fontSize: "1.25rem",
							textDecoration: "underline",
						}}
						onClick={handleRxEmailCode}
					>
						{isEmailcode?"Resend Email": "Receive code via Email ID"}
					</Typography>
				
				<Button
					type="submit"
					variant="contained"
					color="primary"
					sx={{ mt: 3 ,
            height: 50,
            fontSize: "1.15rem",
            width: "100%",
            textTransform: "none",
          }}
					fullWidth
				>
					Submit
				</Button>
			</form>
		</Box>
	);
};

export default OtpScreen;
