import * as React from "react";
interface HomeIconProps {
	[key: string]: any;
}
const HomeIcon: React.FC<HomeIconProps> = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={30}
		height={30}
		fill="none"
		{...props}
	>
		<mask id="a" fill="#fff">
			<path d="M13.31 2.594a2.7 2.7 0 0 1 3.38 0l9.3 7.462A2.7 2.7 0 0 1 27 12.162v13.536a2.7 2.7 0 0 1-2.7 2.7h-3.6a2.7 2.7 0 0 1-2.7-2.7v-7.2a.9.9 0 0 0-.9-.9h-4.2a.9.9 0 0 0-.9.9v7.2a2.7 2.7 0 0 1-2.7 2.7H5.7a2.7 2.7 0 0 1-2.7-2.7V12.162a2.7 2.7 0 0 1 1.01-2.106l9.3-7.462Zm2.253 1.404a.9.9 0 0 0-1.126 0l-9.3 7.462a.9.9 0 0 0-.337.702v13.536a.9.9 0 0 0 .9.9h3.6a.9.9 0 0 0 .9-.9v-7.2a2.7 2.7 0 0 1 2.7-2.7h4.2a2.7 2.7 0 0 1 2.7 2.7v7.2a.9.9 0 0 0 .9.9h3.6a.9.9 0 0 0 .9-.9V12.162a.9.9 0 0 0-.337-.702l-9.3-7.462Z" />
		</mask>
		<path
			fill="currentColor"
			d="M13.31 2.594a2.7 2.7 0 0 1 3.38 0l9.3 7.462A2.7 2.7 0 0 1 27 12.162v13.536a2.7 2.7 0 0 1-2.7 2.7h-3.6a2.7 2.7 0 0 1-2.7-2.7v-7.2a.9.9 0 0 0-.9-.9h-4.2a.9.9 0 0 0-.9.9v7.2a2.7 2.7 0 0 1-2.7 2.7H5.7a2.7 2.7 0 0 1-2.7-2.7V12.162a2.7 2.7 0 0 1 1.01-2.106l9.3-7.462Zm2.253 1.404a.9.9 0 0 0-1.126 0l-9.3 7.462a.9.9 0 0 0-.337.702v13.536a.9.9 0 0 0 .9.9h3.6a.9.9 0 0 0 .9-.9v-7.2a2.7 2.7 0 0 1 2.7-2.7h4.2a2.7 2.7 0 0 1 2.7 2.7v7.2a.9.9 0 0 0 .9.9h3.6a.9.9 0 0 0 .9-.9V12.162a.9.9 0 0 0-.337-.702l-9.3-7.462Z"
		/>
	</svg>
);
export default HomeIcon;
