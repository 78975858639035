import * as React from "react";
interface SvgComponentProps {
	[key: string]: any;
}
const Arrow: React.FC<SvgComponentProps> = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={32}
		height={20}
		fill="none"
		{...props}
	>
		<path
			fill="currentColor"
			d="M21.125 0c-.303 0-.605.12-.827.357a1.102 1.102 0 0 0 .064 1.57L29.216 10l-8.854 8.072a1.102 1.102 0 0 0-.064 1.57c.422.451 1.133.48 1.59.063l9.75-8.889a1.105 1.105 0 0 0 0-1.632l-9.75-8.89A1.125 1.125 0 0 0 21.125 0Z"
		/>
		<path
			fill="currentColor"
			d="M30.737 9H1.263C.565 9 0 9.447 0 10c0 .553.565 1 1.263 1h29.474c.698 0 1.263-.447 1.263-1 0-.553-.565-1-1.263-1Z"
		/>
	</svg>
);
export default Arrow;
