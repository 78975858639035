import { FormEvent, useEffect, useMemo, useState, useContext } from "react";
import { useBoolean } from "@fluentui/react-hooks";
import {
	Checkbox,
	DefaultButton,
	Dialog,
	FontIcon,
	Stack,
	Text,
	TextField,
} from "@fluentui/react";
import DOMPurify from "dompurify";
import { AppStateContext } from "../../state/AppProvider";

import styles from "./Answer.module.css";

import {
	AskResponse,
	Citation,
	Feedback,
	historyMessageFeedback,
} from "../../api";
import { parseAnswer } from "./AnswerParser";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import supersub from "remark-supersub";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { nord } from "react-syntax-highlighter/dist/esm/styles/prism";
import { ThumbDislike20Filled, ThumbLike20Filled } from "@fluentui/react-icons";
import { XSSAllowTags } from "../../constants/xssAllowTags";
import ThumbsUpIcon from "../../assets/images/ThumbsUpIcon";
import ThumbsDownIcon from "../../assets/images/ThumbsDownIcon";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ChevronIcon from "../../assets/images/ChevronIcon";
import UpIcon from "../../assets/images/UpIcon";

interface Props {
	answer: AskResponse;
	onCitationClicked: (citedDocument: Citation) => void;
}

export const Answer = ({ answer, onCitationClicked }: Props) => {
	const theme = useTheme();

	const initializeAnswerFeedback = (answer: AskResponse) => {
		if (answer.message_id == undefined) return undefined;
		if (answer.feedback == undefined) return undefined;
		if (answer.feedback.split(",").length > 1) return Feedback.Negative;
		if (Object.values(Feedback).includes(answer.feedback))
			return answer.feedback;
		return Feedback.Neutral;
	};

	const [isRefAccordionOpen, { toggle: toggleIsRefAccordionOpen }] =
		useBoolean(false);
	const [reasoningOpen, setResoningOpen] = useState<boolean>(false);
	const filePathTruncationLimit = 50;

	const parsedAnswer = useMemo(() => parseAnswer(answer), [answer]);
	const [chevronIsExpanded, setChevronIsExpanded] =
		useState(isRefAccordionOpen);
	const [feedbackState, setFeedbackState] = useState(
		initializeAnswerFeedback(answer),
	);
	const [isFeedbackDialogOpen, setIsFeedbackDialogOpen] = useState(false);
	const [
		showReportInappropriateFeedback,
		setShowReportInappropriateFeedback,
	] = useState(false);
	const [negativeFeedbackList, setNegativeFeedbackList] = useState<
		Feedback[]
	>([]);
	const [additionalFeedback, setAdditionalFeedback] = useState<string>("");
	const appStateContext = useContext(AppStateContext);
	const FEEDBACK_ENABLED =
		appStateContext?.state.frontendSettings?.feedback_enabled &&
		appStateContext?.state.isCosmosDBAvailable?.cosmosDB;
	const SANITIZE_ANSWER =
		appStateContext?.state.frontendSettings?.sanitize_answer;
	const convID = appStateContext?.state.currentChat?.id;

	const handleChevronClick = () => {
		setChevronIsExpanded(!chevronIsExpanded);
		toggleIsRefAccordionOpen();
	};
	const handleChevronClickReasoning = () => {
		setResoningOpen(!reasoningOpen);
		// toggleIsRefAccordionOpen();
	};

	useEffect(() => {
		setChevronIsExpanded(isRefAccordionOpen);
	}, [isRefAccordionOpen]);

	useEffect(() => {
		if (answer.message_id == undefined) return;

		let currentFeedbackState;
		if (
			appStateContext?.state.feedbackState &&
			appStateContext?.state.feedbackState[answer.message_id]
		) {
			currentFeedbackState =
				appStateContext?.state.feedbackState[answer.message_id];
		} else {
			currentFeedbackState = initializeAnswerFeedback(answer);
		}
		setFeedbackState(currentFeedbackState);
	}, [
		appStateContext?.state.feedbackState,
		feedbackState,
		answer.message_id,
	]);

	const createCitationFilepath = (
		citation: Citation,
		index: number,
		truncate: boolean = false,
	) => {
		let citationFilename = "";
		if (citation.title) {
			citationFilename = citation.title;
		} else {
			citationFilename = `Citation ${index}`;
		}
		return citationFilename;
	};

	const onLikeResponseClicked = async () => {
		if (answer.message_id == undefined) return;

		let newFeedbackState = feedbackState;
		// Set or unset the thumbs up state
		if (feedbackState == Feedback.Positive) {
			newFeedbackState = Feedback.Neutral;
		} else {
			newFeedbackState = Feedback.Positive;
		}
		appStateContext?.dispatch({
			type: "SET_FEEDBACK_STATE",
			payload: {
				answerId: answer.message_id,
				feedback: newFeedbackState,
			},
		});
		setFeedbackState(newFeedbackState);

		// Update message feedback in db
		await historyMessageFeedback(
			convID,
			answer.message_id,
			newFeedbackState,
			additionalFeedback,
		);
	};

	const onDislikeResponseClicked = async () => {
		if (answer.message_id == undefined) return;

		let newFeedbackState = feedbackState;
		if (
			feedbackState === undefined ||
			feedbackState === Feedback.Neutral ||
			feedbackState === Feedback.Positive
		) {
			newFeedbackState = Feedback.Negative;
			setFeedbackState(newFeedbackState);
			setIsFeedbackDialogOpen(true);
		} else {
			// Reset negative feedback to neutral
			newFeedbackState = Feedback.Neutral;
			setFeedbackState(newFeedbackState);
			await historyMessageFeedback(
				convID,
				answer.message_id,
				Feedback.Neutral,
				"",
			);
			setAdditionalFeedback("");
		}
		appStateContext?.dispatch({
			type: "SET_FEEDBACK_STATE",
			payload: {
				answerId: answer.message_id,
				feedback: newFeedbackState,
			},
		});
	};

	const updateFeedbackList = (
		ev?: FormEvent<HTMLElement | HTMLInputElement>,
		checked?: boolean,
	) => {
		if (answer.message_id == undefined) return;
		let selectedFeedback = (ev?.target as HTMLInputElement)?.id as Feedback;

		let feedbackList = negativeFeedbackList.slice();
		if (checked) {
			feedbackList.push(selectedFeedback);
		} else {
			feedbackList = feedbackList.filter((f) => f !== selectedFeedback);
		}
		setNegativeFeedbackList(feedbackList);
	};

	const updateAdditionalFeedback = (
		ev?: FormEvent<HTMLElement | HTMLInputElement>,
		checked?: boolean,
	) => {
		if (answer.message_id == undefined) return;
		const newAdditionalFeedbackInput = document.getElementById(
			Feedback.AdditionalDetails,
		) as HTMLInputElement;
		const newAdditionalFeedback = newAdditionalFeedbackInput.value;
		setAdditionalFeedback(newAdditionalFeedback);
	};

	const onSubmitNegativeFeedback = async () => {
		if (answer.message_id == undefined) return;
		await historyMessageFeedback(
			convID,
			answer.message_id,
			negativeFeedbackList.join(","),
			additionalFeedback,
		);
		resetFeedbackDialog();
	};

	const resetFeedbackDialog = () => {
		setIsFeedbackDialogOpen(false);
		setShowReportInappropriateFeedback(false);
		setNegativeFeedbackList([]);
	};

	const UnhelpfulFeedbackContent = () => {
		return (
			<>
				<div>Why wasn't this response helpful?</div>
				<Stack tokens={{ childrenGap: 4 }}>
					<Checkbox
						label="Citations are missing"
						id={Feedback.MissingCitation}
						defaultChecked={negativeFeedbackList.includes(
							Feedback.MissingCitation,
						)}
						onChange={updateFeedbackList}
					></Checkbox>
					<Checkbox
						label="Citations are wrong"
						id={Feedback.WrongCitation}
						defaultChecked={negativeFeedbackList.includes(
							Feedback.WrongCitation,
						)}
						onChange={updateFeedbackList}
					></Checkbox>
					<Checkbox
						label="The response is not from my data"
						id={Feedback.OutOfScope}
						defaultChecked={negativeFeedbackList.includes(
							Feedback.OutOfScope,
						)}
						onChange={updateFeedbackList}
					></Checkbox>
					<Checkbox
						label="Inaccurate or irrelevant"
						id={Feedback.InaccurateOrIrrelevant}
						defaultChecked={negativeFeedbackList.includes(
							Feedback.InaccurateOrIrrelevant,
						)}
						onChange={updateFeedbackList}
					></Checkbox>
					<Checkbox
						label="Other"
						id={Feedback.OtherUnhelpful}
						defaultChecked={negativeFeedbackList.includes(
							Feedback.OtherUnhelpful,
						)}
						onChange={updateFeedbackList}
					></Checkbox>
					<TextField
						label="Please provide additional details"
						id={Feedback.AdditionalDetails}
						defaultValue={additionalFeedback}
						onBlur={updateAdditionalFeedback}
						placeholder="Enter your feedback here"
						multiline
						ariaLabel="Additional feedback text area"
					/>
				</Stack>
				<div
					onClick={() => setShowReportInappropriateFeedback(true)}
					style={{ color: "#115EA3", cursor: "pointer" }}
				>
					Report inappropriate content
				</div>
			</>
		);
	};

	const ReportInappropriateFeedbackContent = () => {
		return (
			<>
				<div>
					The content is <span style={{ color: "red" }}>*</span>
				</div>
				<Stack tokens={{ childrenGap: 4 }}>
					<Checkbox
						label="Hate speech, stereotyping, demeaning"
						id={Feedback.HateSpeech}
						defaultChecked={negativeFeedbackList.includes(
							Feedback.HateSpeech,
						)}
						onChange={updateFeedbackList}
					></Checkbox>
					<Checkbox
						label="Violent: glorification of violence, self-harm"
						id={Feedback.Violent}
						defaultChecked={negativeFeedbackList.includes(
							Feedback.Violent,
						)}
						onChange={updateFeedbackList}
					></Checkbox>
					<Checkbox
						label="Sexual: explicit content, grooming"
						id={Feedback.Sexual}
						defaultChecked={negativeFeedbackList.includes(
							Feedback.Sexual,
						)}
						onChange={updateFeedbackList}
					></Checkbox>
					<Checkbox
						label="Manipulative: devious, emotional, pushy, bullying"
						defaultChecked={negativeFeedbackList.includes(
							Feedback.Manipulative,
						)}
						id={Feedback.Manipulative}
						onChange={updateFeedbackList}
					></Checkbox>
					<Checkbox
						label="Other"
						id={Feedback.OtherHarmful}
						defaultChecked={negativeFeedbackList.includes(
							Feedback.OtherHarmful,
						)}
						onChange={updateFeedbackList}
					></Checkbox>
					<TextField
						label="Please provide additional details"
						placeholder="Enter your feedback here"
						multiline
						ariaLabel="Additional feedback text area"
					/>
				</Stack>
			</>
		);
	};

	const components = {
		code({ node, ...props }: { node: any; [key: string]: any }) {
			let language;
			if (props.className) {
				const match = props.className.match(/language-(\w+)/);
				language = match ? match[1] : undefined;
			}
			const codeString = node.children[0].value ?? "";
			return (
				<SyntaxHighlighter
					style={nord}
					language={language}
					PreTag="div"
					{...props}
				>
					{codeString}
				</SyntaxHighlighter>
			);
		},
	};

	return (
		<>
			<Stack className={styles.answerContainer} tabIndex={0}>
				<Stack.Item>
					<Stack horizontal grow>
						<Stack.Item grow>
							<ReactMarkdown
								linkTarget="_blank"
								remarkPlugins={[remarkGfm, supersub]}
								children={
									SANITIZE_ANSWER
										? DOMPurify.sanitize(
												parsedAnswer.markdownFormatText,
												{ ALLOWED_TAGS: XSSAllowTags },
										  )
										: parsedAnswer.markdownFormatText
								}
								className={styles.answerText}
								components={components}
							/>
						</Stack.Item>
					</Stack>
				</Stack.Item>
				<Stack horizontal className={styles.answerFooter}>
					{!!answer.citations.length && (
						<Stack.Item
							onKeyDown={(e) =>
								e.key === "Enter" || e.key === " "
									? toggleIsRefAccordionOpen()
									: null
							}
						>
							<Stack style={{ width: "100%" }}>
								<Stack
									horizontal
									horizontalAlign="start"
									verticalAlign="center"
								>
									<Text
										className={styles.accordionTitle}
										onClick={handleChevronClickReasoning}
										// aria-label="Open references"
										tabIndex={0}
										role="button"
									>
										<span>Reasoning</span>
									</Text>
									<Box
										component="div"
										className={styles.accordionIcon}
										onClick={handleChevronClickReasoning}
										sx={{
											mx: 1,
											color: theme.palette.primary.main,
											cursor: "pointer",
										}}
									>
										{reasoningOpen ? (
											<UpIcon />
										) : (
											<ChevronIcon />
										)}
									</Box>
								</Stack>
							</Stack>
						</Stack.Item>
					)}
					{/* <Stack.Item className={styles.answerDisclaimerContainer}>
						<span className={styles.answerDisclaimer}>
							AI-generated content may be incorrect
						</span>
					</Stack.Item> */}
					<Stack.Item className={styles.answerDisclaimerContainer}>
						<Box
							sx={{
								color: theme.palette.primary.main,
							}}
						>
							<ThumbsUpIcon
								onClick={() => onLikeResponseClicked()}
							/>
							<ThumbsDownIcon
								onClick={() => onDislikeResponseClicked()}
							/>
						</Box>
					</Stack.Item>
				</Stack>
				{!reasoningOpen ? (
					""
				) : (
					<Text style={{ marginLeft: "10px", marginBottom: "20px" }}>
						{answer?.citations[0]?.reasoning}
					</Text>
				)}
				{!chevronIsExpanded ? (
					<Stack.Item
						onKeyDown={(e) =>
							e.key === "Enter" || e.key === " "
								? toggleIsRefAccordionOpen()
								: null
						}
					>
						<Stack style={{ width: "100%" }}>
							<Stack
								horizontal
								horizontalAlign="start"
								verticalAlign="center"
							>
								<Text
									className={styles.accordionTitle}
									onClick={toggleIsRefAccordionOpen}
									aria-label="Open references"
									tabIndex={0}
									role="button"
								>
									<span>
										{answer.citations.length >= 1
											? answer.citations.length +
											  " references"
											: "Reference"}
									</span>
								</Text>
								{/* <FontIcon
									className={styles.accordionIcon}
									onClick={handleChevronClick}
									iconName={
										chevronIsExpanded
											? "ChevronDown"
											: "ChevronRight"
									}
								/> */}
								<Box
									component="div"
									className={styles.accordionIcon}
									onClick={handleChevronClick}
									sx={{
										mx: 1,
										color: theme.palette.primary.main,
										cursor: "pointer",
									}}
								>
									{chevronIsExpanded ? (
										<UpIcon />
									) : (
										<ChevronIcon />
									)}
								</Box>
							</Stack>
						</Stack>
					</Stack.Item>
				) : (
					chevronIsExpanded && (
						<>
							<Stack.Item
								onKeyDown={(e) =>
									e.key === "Enter" || e.key === " "
										? toggleIsRefAccordionOpen()
										: null
								}
							>
								<Stack style={{ width: "100%" }}>
									<Stack
										horizontal
										horizontalAlign="start"
										verticalAlign="center"
									>
										<Text
											className={styles.accordionTitle}
											onClick={toggleIsRefAccordionOpen}
											aria-label="Open references"
											tabIndex={0}
											role="button"
										>
											<span>
												{answer.citations.length >= 1
													? answer.citations.length +
													  " references"
													: "Reference"}
											</span>
										</Text>
										{/* <FontIcon
											className={styles.accordionIcon}
											onClick={handleChevronClick}
											iconName={
												chevronIsExpanded
													? "ChevronDown"
													: "ChevronRight"
											}
										/> */}
										<Box
											component="div"
											className={styles.accordionIcon}
											onClick={handleChevronClick}
											sx={{
												mx: 1,
												color: theme.palette.primary
													.main,
												cursor: "pointer",
											}}
										>
											{chevronIsExpanded ? (
												<UpIcon />
											) : (
												<ChevronIcon />
											)}
										</Box>
									</Stack>
								</Stack>
							</Stack.Item>
							{chevronIsExpanded && (
								<div className={styles.citationWrapper}>
									{answer.citations.map((citation, idx) => {
										return (
											<span
												title={createCitationFilepath(
													citation,
													++idx,
												)}
												tabIndex={0}
												role="link"
												key={idx}
												onClick={() =>
													onCitationClicked(citation)
												}
												onKeyDown={(e) =>
													e.key === "Enter" ||
													e.key === " "
														? onCitationClicked(
																citation,
														  )
														: null
												}
												className={
													styles.citationContainer
												}
												style={{
													color: theme.palette.primary
														.main,
												}}
												aria-label={createCitationFilepath(
													citation,
													idx,
												)}
											>
												<div
													className={styles.citation}
												>
													{idx}
												</div>
												<span className={styles.trim}>
													{createCitationFilepath(
														citation,
														idx,
														true,
													)}
												</span>
											</span>
										);
									})}
								</div>
							)}
						</>
					)
				)}
			</Stack>
			<Dialog
				onDismiss={() => {
					resetFeedbackDialog();
					setFeedbackState(Feedback.Neutral);
				}}
				hidden={!isFeedbackDialogOpen}
				styles={{
					main: [
						{
							selectors: {
								["@media (min-width: 480px)"]: {
									maxWidth: "600px",
									background: "#FFFFFF",
									boxShadow:
										"0px 14px 28.8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.2)",
									borderRadius: "8px",
									maxHeight: "600px",
									minHeight: "100px",
								},
							},
						},
					],
				}}
				dialogContentProps={{
					title: "Submit Feedback",
					showCloseButton: true,
				}}
			>
				<Stack tokens={{ childrenGap: 4 }}>
					<div>Your feedback will improve this experience.</div>

					{!showReportInappropriateFeedback ? (
						<UnhelpfulFeedbackContent />
					) : (
						<ReportInappropriateFeedbackContent />
					)}

					<div>
						By pressing submit, your feedback will be visible to the
						application owner.
					</div>

					<DefaultButton
						disabled={negativeFeedbackList.length < 1}
						onClick={onSubmitNegativeFeedback}
					>
						Submit
					</DefaultButton>
				</Stack>
			</Dialog>
		</>
	);
};
