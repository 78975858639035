import React, { useState } from "react";
import { Button, Typography, Box, TextField, Alert } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import BackArrow from "../../../assets/images/BackArrow.svg";
import Completed from "../../../assets/images/CheckMarkGreen.svg";
import { passwordEmailGenerator } from "../../../api";
import { toast } from "react-toastify";
import { showLoader, hideLoader } from "../../../store/loaderSlice";
import { useDispatch } from "react-redux";


interface ForgotPasswordProps {
  setIsForgotPassword: (value: boolean) => void;
}

const ForgotPassword: React.FC<ForgotPasswordProps> = ({setIsForgotPassword}) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [isMailSent, setIsMailSent] = useState(false);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        // Basic validation
        if (!email) {
            setError("Email address is required.");
            return;
        }
        if (!/\S+@\S+\.\S+/.test(email)) {
            setError("Please enter a valid email address.");
            return;
        }
        setError(""); // Clear error
        try {
            dispatch(showLoader())
            const resp:any = await passwordEmailGenerator(email); 
            if(resp.success){    
                toast.success(resp?.payload);
                setIsMailSent(true)
                dispatch(hideLoader())
            }  else{
                const err = resp?.payload || 'Something went wrong';
                toast.error(err);
                dispatch(hideLoader())
            }
        }
        catch(error){
            const err:any = error;
            toast.error(err?.detail?.error?.payload || 'Something went wrong');
            dispatch(hideLoader())
            
        }
    };

    const handleBack = () => {
      setIsForgotPassword(false);// Navigate to home when back button is clicked
  };

    return (
        <Box textAlign="left" sx={{ width: "500px", mx: "auto",color:theme.palette.primary.light}}>
            <Box display="flex" alignItems="center" mb={4} gap={1} sx={{cursor:"pointer"}} onClick={handleBack}>
                <img src={BackArrow} alt="Back"  />
                <Typography variant="body1" sx={{ fontSize: "1.25rem",color:theme.palette.text.primary }}>
                    Back
                </Typography>
            </Box>
       {isMailSent &&     <Box>
            <Box display="flex" alignItems="center" gap={1} mb={3}>
            <img src={Completed} alt="done" /> <Typography>Link sent successfully!</Typography>
            </Box>
            <Typography variant="body1" sx={{fontSize:"1.25rem",mb: 2}}>
            We have sent a link to your registered email address for resetting your account password.
            </Typography>
            </Box>}
         {!isMailSent &&   <Box>
            <Typography variant="body1" sx={{fontSize:"1.25rem",mb: 2}}>
                Forgot Password?
            </Typography>
            <Typography variant="body1" sx={{fontSize:"1.25rem", mb: 2}}>
                Please enter the email address associated with your account.
            </Typography>
            <form onSubmit={handleSubmit}>
                <TextField
                    label="Email Address"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                {error && (
                    <Alert severity="error" style={{ marginTop: 16 }}>
                        {error}
                    </Alert>
                )}
                {success && (
                    <Alert severity="success" style={{ marginTop: 16 }}>
                        {success}
                    </Alert>
                )}
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{
                        height: 50,
                        fontSize: "1.15rem",
                        width: "100%",
                        mt: 4,
                        textTransform: "none",
                    }}
                >
                    Submit
                </Button>
            </form>
            </Box>}
            
        </Box>
    );
};

export default ForgotPassword;
