import * as React from "react";
interface SvgComponentProps {
	[key: string]: any;
}
const DefaultUpload: React.FC<SvgComponentProps> = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={500}
		height={370}
		fill="none"
		{...props}
	>
		<path
			fill="#EBEBEB"
			d="M500 325.15H0v.249h500v-.249ZM111.658 333.483h-33.12v.249h33.12v-.249ZM163.5 340.643h-30.17v.249h30.17v-.249ZM95.1 342.699H56.85v.249H95.1v-.249ZM449.901 342.163h-15.9v.249h15.9v-.249ZM430.79 342.163h-39.32v.249h39.32v-.249ZM280.33 333.354H250v.249h30.33v-.249ZM356.77 333.354h-72.1v.249h72.1v-.249ZM237 280.857H43.91a5.729 5.729 0 0 1-4.031-1.665 5.65 5.65 0 0 1-1.669-4.006V5.621a5.651 5.651 0 0 1 1.686-3.974A5.73 5.73 0 0 1 43.91 0H237c1.514 0 2.967.597 4.038 1.66a5.654 5.654 0 0 1 1.672 4.01v269.516a5.655 5.655 0 0 1-1.672 4.01 5.734 5.734 0 0 1-4.038 1.661ZM43.91.199a5.48 5.48 0 0 0-3.854 1.591 5.404 5.404 0 0 0-1.596 3.831v269.565c0 1.436.574 2.814 1.596 3.831a5.478 5.478 0 0 0 3.854 1.591H237a5.484 5.484 0 0 0 3.858-1.591 5.416 5.416 0 0 0 1.602-3.831V5.621a5.417 5.417 0 0 0-1.602-3.831A5.49 5.49 0 0 0 237 .199H43.91ZM453.31 280.857h-193.1a5.743 5.743 0 0 1-4.035-1.664 5.664 5.664 0 0 1-1.675-4.007V5.621a5.662 5.662 0 0 1 1.693-3.975A5.74 5.74 0 0 1 260.21 0h193.1a5.73 5.73 0 0 1 4.007 1.65A5.651 5.651 0 0 1 459 5.621v269.565c0 1.501-.599 2.94-1.665 4.003a5.73 5.73 0 0 1-4.025 1.668ZM260.21.199a5.49 5.49 0 0 0-3.858 1.59 5.417 5.417 0 0 0-1.602 3.832v269.565a5.416 5.416 0 0 0 1.602 3.831 5.484 5.484 0 0 0 3.858 1.591h193.1a5.484 5.484 0 0 0 3.858-1.591 5.416 5.416 0 0 0 1.602-3.831V5.621a5.417 5.417 0 0 0-1.602-3.831A5.49 5.49 0 0 0 453.31.199h-193.1Z"
		/>
		<path
			fill="#E6E6E6"
			d="M293.391 209.759h-28.77V325.15h28.77V209.759Z"
		/>
		<path
			fill="#F5F5F5"
			d="M281.43 325.15h11.96v-19.902h-24.5l12.54 19.902Z"
		/>
		<path fill="#E6E6E6" d="M85.62 209.759H56.85V325.15h28.77V209.759Z" />
		<path
			fill="#F5F5F5"
			d="M293.381 209.759h-218.2v108.539h218.2V209.759Z"
		/>
		<path
			fill="#EBEBEB"
			d="M284.611 243.078H84.001v-23.736h200.61v23.736Zm-199.61-.993h198.61v-21.749H85.001v21.749Z"
		/>
		<path
			fill="#F5F5F5"
			d="M87.14 325.15H75.18v-19.902h24.5L87.14 325.15Z"
		/>
		<path
			fill="#EBEBEB"
			d="M284.611 308.714H84.001v-56.608h200.61v56.608Zm-199.61-.993h198.61v-54.622H85.001v54.622Z"
		/>
		<path fill="#E6E6E6" d="M407.87 23.358H180.49v156.378h227.38V23.358Z" />
		<path fill="#F5F5F5" d="M412.05 23.358H184.67v156.378h227.38V23.358Z" />
		<path fill="#fff" d="M404.539 171.851V31.264h-212.37V171.85h212.37Z" />
		<path fill="#E6E6E6" d="M404.549 171.841V31.254h-3.16V171.84h3.16Z" />
		<path fill="#F5F5F5" d="M408.941 43.847h-221.17v5.303h221.17v-5.303Z" />
		<g fill="#F0F0F0" opacity={0.5}>
			<path d="M408.329 18.135h-8.98v173.688h8.98V18.135ZM396.918 18.135h-8.98v173.688h8.98V18.135ZM385.508 18.135h-8.98v173.688h8.98V18.135ZM374.109 18.135h-8.98v173.688h8.98V18.135ZM362.699 18.135h-8.98v173.688h8.98V18.135ZM351.288 18.135h-8.98v173.688h8.98V18.135ZM339.888 18.135h-8.98v173.688h8.98V18.135ZM328.479 18.135h-8.98v173.688h8.98V18.135ZM317.068 18.135h-8.98v173.688h8.98V18.135ZM305.658 18.135h-8.98v173.688h8.98V18.135ZM294.26 18.135h-8.98v173.688h8.98V18.135ZM282.849 18.135h-8.98v173.688h8.98V18.135ZM271.439 18.135h-8.98v173.688h8.98V18.135ZM260.028 18.135h-8.98v173.688h8.98V18.135ZM248.628 18.135h-8.98v173.688h8.98V18.135Z" />
		</g>
		<path fill="#F0F0F0" d="M128.251 42.039h-65.9v119.017h65.9V42.039Z" />
		<path fill="#F5F5F5" d="M142.4 42.039H66.6v119.017h75.8V42.039Z" />
		<path fill="#fff" d="M69.69 44.86v113.375h69.619V44.86h-69.62Z" />
		<path
			fill="#F5F5F5"
			d="M88.25 67.423v68.248a2.75 2.75 0 0 0 2.76 2.741H118a2.75 2.75 0 0 0 2.76-2.741V67.423a2.75 2.75 0 0 0-2.76-2.74H91.01a2.75 2.75 0 0 0-2.76 2.74Z"
		/>
		<path
			fill="#EBEBEB"
			d="m383.909 141.312 13.48 32.992c-.39 95.43 3.95 100.932 4 117.348h-34c-1.26-79.877 10.67-61.921 16.52-150.34Z"
		/>
		<path
			fill="#EBEBEB"
			d="M402.909 238.768c-27.39-74.892-90.45-51.702-87.62-24.401 2.83 27.301 103.37 67.473 87.62 24.401Z"
		/>
		<path
			fill="#EBEBEB"
			d="M392.549 281.949c-14.94-48.256-54.68-40.38-54.68-18.204 0 22.177 63.28 45.962 54.68 18.204ZM391.49 194.832c-23.46-64.553-81.89-55.615-77.18-28.413 4.71 27.201 90.69 65.516 77.18 28.413Z"
		/>
		<path
			fill="#EBEBEB"
			d="M391.65 165.902c2.17-43.956-41.66-62.051-38.62-35.753 3.04 26.299 37.37 61.028 38.62 35.753ZM388.38 210.901c9.25-54.165 65.42-74.763 59.14-44.105-6.28 30.658-64.47 75.259-59.14 44.105Z"
		/>
		<path
			fill="#EBEBEB"
			d="M383.65 180.283c-10.91-56.797 39.55-82.033 41-54.622 1.45 27.41-34.73 87.286-41 54.622ZM379.27 244.439c22.29-54.732 70-41.156 65.21-16.248-4.79 24.908-78.03 47.73-65.21 16.248Z"
		/>
		<path
			fill="#EBEBEB"
			d="M390.269 282.654c-.5-35.306 31.78-44.432 32.16-23.974.38 20.459-31.87 44.284-32.16 23.974Z"
		/>
		<path
			fill="#E0E0E0"
			d="M380.412 325.399a37.295 37.295 0 0 1-23.277-8.05 36.837 36.837 0 0 1-13.073-20.751 74.875 74.875 0 0 0-3.79-12.911l-.27-.675h80.83l-.28.675a74.755 74.755 0 0 0-3.78 12.911 36.849 36.849 0 0 1-13.079 20.752 37.305 37.305 0 0 1-23.281 8.049Z"
		/>
		<path
			fill="#F5F5F5"
			d="M249.998 370c107.083 0 193.89-5.033 193.89-11.242 0-6.209-86.807-11.242-193.89-11.242-107.082 0-193.89 5.033-193.89 11.242 0 6.209 86.808 11.242 193.89 11.242Z"
		/>
		<path
			fill="currentColor"
			d="m129.372 157.848-.63-.765a66.966 66.966 0 0 1 8.2-5.681l.51.864a65.859 65.859 0 0 0-8.08 5.582ZM108.83 186.231l-.93-.347a68.208 68.208 0 0 1 15.67-24.183l.71.705a67.314 67.314 0 0 0-15.45 23.825Z"
		/>
		<path
			fill="currentColor"
			d="M302.571 229.999a52.613 52.613 0 0 0-6.395-24.68 53.048 53.048 0 0 0-17.269-18.85 53.51 53.51 0 0 0-24.138-8.646 53.622 53.622 0 0 0-25.408 3.562 64.131 64.131 0 0 0-27.013-28.08 64.756 64.756 0 0 0-38.393-7.374 64.579 64.579 0 0 0-35.608 16.051 63.841 63.841 0 0 0-19.686 33.555c-14.373.9-27.828 7.324-37.514 17.909-9.685 10.585-14.839 24.5-14.37 38.795.47 14.295 6.526 27.846 16.885 37.782 10.36 9.936 24.207 15.475 38.609 15.443h176.32a39.448 39.448 0 0 0 25.165-9.014 38.955 38.955 0 0 0 13.516-22.928 38.776 38.776 0 0 0-4.402-26.207 39.2 39.2 0 0 0-20.279-17.328l-.02.01Z"
		/>
		<path
			fill="#263238"
			d="M262.912 212.222h-141.1a4.37 4.37 0 0 1-3.059-1.269 4.31 4.31 0 0 1-1.271-3.042 4.293 4.293 0 0 1 1.272-3.037 4.355 4.355 0 0 1 3.058-1.263h141.1a4.356 4.356 0 0 1 3.059 1.263 4.288 4.288 0 0 1 1.271 3.037 4.304 4.304 0 0 1-1.27 3.042 4.37 4.37 0 0 1-3.06 1.269Z"
		/>
		<path
			fill="#fff"
			d="M241.632 270.419h-98.54a14.609 14.609 0 0 1-10.295-4.235 14.41 14.41 0 0 1-4.265-10.225 14.41 14.41 0 0 1 4.265-10.225 14.613 14.613 0 0 1 10.295-4.235h98.54c3.862 0 7.565 1.524 10.296 4.235a14.413 14.413 0 0 1 4.264 10.225c0 3.835-1.534 7.513-4.264 10.225a14.611 14.611 0 0 1-10.296 4.235Zm-98.54-27.927a13.606 13.606 0 0 0-9.588 3.945 13.42 13.42 0 0 0-3.972 9.522c0 3.572 1.429 6.997 3.972 9.523a13.61 13.61 0 0 0 9.588 3.944h98.54a13.61 13.61 0 0 0 9.589-3.944 13.42 13.42 0 0 0 3.971-9.523c0-3.571-1.428-6.997-3.971-9.522a13.607 13.607 0 0 0-9.589-3.945h-98.54Z"
		/>
		<path
			fill="#fff"
			d="M166.11 266.943h-23a11.136 11.136 0 0 1-7.841-3.211 10.988 10.988 0 0 1-3.269-7.773 10.983 10.983 0 0 1 3.248-7.762 11.134 11.134 0 0 1 7.812-3.232h23c2.932.01 5.74 1.172 7.812 3.232a10.983 10.983 0 0 1 3.248 7.762 10.984 10.984 0 0 1-3.233 7.738 11.142 11.142 0 0 1-7.777 3.246Z"
		/>
		<path
			fill="currentColor"
			d="m266.939 128.739-18.71 79.172h-111.62l31.22-132.135 77.29 18.005 21.82 34.958Z"
		/>
		<path
			fill="#FAFAFA"
			d="m266.939 128.739-18.71 79.172h-111.62l31.22-132.135 77.29 18.005 21.82 34.958Z"
			opacity={0.8}
		/>
		<path
			fill="currentColor"
			d="m252.382 143.457-84.78-19.763a2.25 2.25 0 0 1-1.721-1.824 2.254 2.254 0 0 1 1.039-2.28 2.286 2.286 0 0 1 1.712-.286l84.79 19.734c.585.139 1.09.502 1.407 1.01.316.508.418 1.119.283 1.701a2.274 2.274 0 0 1-1.011 1.413 2.3 2.3 0 0 1-1.719.295ZM249.141 157.163l-84.78-19.764a2.262 2.262 0 0 1-1.721-1.832 2.225 2.225 0 0 1 .031-.879 2.263 2.263 0 0 1 1.017-1.397 2.287 2.287 0 0 1 1.713-.281l84.78 19.763a2.27 2.27 0 0 1 1.406 1.004 2.24 2.24 0 0 1 .284 1.697 2.268 2.268 0 0 1-1.014 1.404 2.296 2.296 0 0 1-1.716.285ZM245.901 170.818l-84.78-19.753a2.285 2.285 0 0 1-1.407-1.01 2.255 2.255 0 0 1-.283-1.701c.143-.58.509-1.08 1.02-1.394a2.3 2.3 0 0 1 1.71-.285l84.78 19.753a2.279 2.279 0 0 1 1.411 1.008 2.247 2.247 0 0 1-.735 3.105 2.299 2.299 0 0 1-1.716.277ZM242.671 184.563l-84.79-19.704a2.287 2.287 0 0 1-1.403-1.012 2.264 2.264 0 0 1-.287-1.699 2.263 2.263 0 0 1 1.017-1.397 2.288 2.288 0 0 1 1.713-.281l84.78 19.753a2.287 2.287 0 0 1 1.41 1.009c.318.508.422 1.119.29 1.702a2.257 2.257 0 0 1-1.029 1.37 2.281 2.281 0 0 1-1.701.259ZM205.72 190.412l-51.07-11.917a2.27 2.27 0 0 1-1.385-1.019 2.243 2.243 0 0 1-.265-1.692 2.235 2.235 0 0 1 1.009-1.4 2.275 2.275 0 0 1 1.711-.279l51.08 11.918a2.265 2.265 0 0 1 1.721 1.832c.048.292.037.591-.031.879a2.266 2.266 0 0 1-2.77 1.678ZM266.941 128.739l-28.51-6.644 6.69-28.314 21.82 34.958Z"
		/>
		<path
			fill="#263238"
			d="m286.09 203.919-23.7 154.839h4.68l3.13-20.488h60.38l-3.13 20.488h4.68l23.7-154.839h-69.74Zm45.12 130.249h-60.38l3.37-21.968h60.38l-3.37 21.968Zm4-26.07h-60.39l3.37-21.968h60.38l-3.36 21.968Zm4-26.069h-60.4l3.37-21.968h60.38l-3.35 21.968Zm4-26.07h-60.4l3.36-21.978h60.38l-3.34 21.978ZM286.8 229.88l3.36-21.968h60.38l-3.36 21.968H286.8Z"
		/>
		<path
			fill="#263238"
			d="M365.579 203.919h-69.74l23.7 154.839h4.68l-3.14-20.488h60.38l3.14 20.488h4.68l-23.7-154.839Zm-64.45 3.973h60.38l3.36 21.968h-60.38l-3.36-21.968Zm4 26.069h60.37l3.36 21.978h-60.38l-3.35-21.978Zm4 26.08h60.38l3.34 22.008h-60.38l-3.34-22.008Zm4 26.07h60.38l3.36 21.968h-60.41l-3.33-21.968Zm7.35 48.037-3.36-21.968h60.38l3.36 21.968h-60.38Z"
		/>
		<path
			fill="#fff"
			d="M365.579 203.919h-69.74l23.7 154.839h4.68l-3.14-20.488h60.38l3.14 20.488h4.68l-23.7-154.839Zm-64.45 3.973h60.38l3.36 21.968h-60.38l-3.36-21.968Zm4 26.069h60.37l3.36 21.978h-60.38l-3.35-21.978Zm4 26.08h60.38l3.34 22.008h-60.38l-3.34-22.008Zm4 26.07h60.38l3.36 21.968h-60.41l-3.33-21.968Zm7.35 48.037-3.36-21.968h60.38l3.36 21.968h-60.38Z"
			opacity={0.4}
		/>
		<path
			fill="#7F3E3B"
			d="M335.699 53.212c2 1.53 3.76 3.059 5.58 4.648 1.82 1.589 3.56 3.217 5.28 4.896 3.49 3.397 6.751 7.02 9.76 10.845l1.12 1.5.56.744c.317.44.601.9.85 1.38a13.214 13.214 0 0 1 1.38 5.145 26.538 26.538 0 0 1-.85 8.193 55.137 55.137 0 0 1-5.54 13.706l-5.11-2.066a95.79 95.79 0 0 0 2.17-13.02c.19-1.89.129-3.797-.18-5.67a3.902 3.902 0 0 0-.59-1.5c-.05-.05-.07-.05-.1-.08l-.54-.606-1.08-1.221c-3-3.218-6.16-6.356-9.5-9.375a372.99 372.99 0 0 0-10.16-8.879l6.95-8.64Z"
		/>
		<path
			fill="#7F3E3B"
			d="m350.759 100.157-5.75 3.973 7 4.21s3.61-3.426 2.06-6.604l-3.31-1.579Z"
		/>
		<path
			fill="#7F3E3B"
			d="m342.15 110.654 5.3 2.95 4.6-5.313-7.04-4.201-2.86 6.564Z"
		/>
		<path
			fill="#263238"
			d="M296.55 32.455c.16.646-.05 1.252-.47 1.361-.42.11-.9-.328-1.07-.993-.17-.666.05-1.251.47-1.36.42-.11.9.317 1.07.992Z"
		/>
		<path
			fill="#630F0F"
			d="M296.56 33.697a23.56 23.56 0 0 1-1.67 6.276 3.894 3.894 0 0 0 3.24-.208l-1.57-6.068Z"
		/>
		<path
			fill="#263238"
			d="M297.921 28.314a.392.392 0 0 1-.24 0 3.162 3.162 0 0 0-2.83.189.393.393 0 0 1-.504-.115.386.386 0 0 1 .054-.51 3.897 3.897 0 0 1 3.57-.289.387.387 0 0 1-.05.735v-.01Z"
		/>
		<path
			fill="#7F3E3B"
			d="M317.05 34.263c.93 6.058 2.24 15.344 7.6 17.688 0 0 .52 6.296-10.07 10.08-11.64 4.161-7.64-3.744-7.64-3.744 5.81-3.784 3.95-8.402 1.25-12.404l8.86-11.62Z"
		/>
		<path
			fill="#263238"
			d="M325.65 50.65c0-1.897-1.95-5.185-3.92-4.966-2.17.248-10.45 3.277-13.47 6.048-3.02 2.77-.88 7.627-.88 7.627l18.27-8.71Z"
		/>
		<path
			fill="currentColor"
			d="M389.5 280.261c-.52.536-1.11.993-1.57.924-.2 0-.46-.14-.59-.576a.782.782 0 0 1 .09-.666c.56-.943 3-1.43 3.07-1.45a.189.189 0 0 1 .1.009c.032.012.06.033.08.061a.182.182 0 0 1 0 .188 9.3 9.3 0 0 1-1.18 1.51Zm-1.64-.308a.733.733 0 0 0-.14.169.492.492 0 0 0-.05.387c.1.308.25.328.3.338.51.059 1.56-.993 2.19-1.917a5.65 5.65 0 0 0-2.3 1.023Z"
		/>
		<path
			fill="currentColor"
			d="M390.66 278.781a.184.184 0 0 1-.07.05c-.83.258-3 .189-3.49-.487a.568.568 0 0 1 .06-.705.93.93 0 0 1 .68-.377c1.09-.129 2.74 1.211 2.81 1.271a.164.164 0 0 1 .051.071.165.165 0 0 1 .009.088.188.188 0 0 1-.05.089Zm-3.17-.993-.05.06c-.14.178-.08.258-.05.298.28.397 1.81.576 2.75.427a4.149 4.149 0 0 0-2.26-.993.55.55 0 0 0-.39.208ZM328.999 301.017c-.94 0-1.85-.129-2.16-.546a.66.66 0 0 1 0-.755.86.86 0 0 1 .55-.407c1.22-.328 3.79 1.122 3.9 1.192a.159.159 0 0 1 .08.169.174.174 0 0 1-.045.098.18.18 0 0 1-.095.051c-.736.129-1.482.195-2.23.198Zm-1.16-1.41a1.21 1.21 0 0 0-.34 0 .52.52 0 0 0-.34.238c-.12.219-.08.318 0 .388.34.467 2.19.476 3.56.288a7.917 7.917 0 0 0-2.87-.914h-.01Z"
		/>
		<path
			fill="currentColor"
			d="M331.23 300.819a.105.105 0 0 1-.07 0c-.89-.388-2.64-1.987-2.51-2.791 0-.199.17-.437.65-.487a1.299 1.299 0 0 1 1 .298 4.823 4.823 0 0 1 1.14 2.811.17.17 0 0 1-.012.089.18.18 0 0 1-.058.07.178.178 0 0 1-.068.02.193.193 0 0 1-.072-.01Zm-1.79-2.98h-.11c-.31 0-.33.149-.34.189-.08.496 1.12 1.738 2 2.274a3.951 3.951 0 0 0-1-2.244.92.92 0 0 0-.55-.199v-.02Z"
		/>
		<path
			fill="#7F3E3B"
			d="M339.309 300.65h-7.35l-.73-16.903h7.35l.73 16.903ZM398.181 275.901l-6.8 2.453-8.64-15.135 6.8-2.453 8.64 15.135Z"
		/>
		<path
			fill="#263238"
			d="m390.311 277.689 5.74-5.899a.656.656 0 0 1 .79-.1l5.7 3.476a1.07 1.07 0 0 1 .19 1.669c-2 1.986-3.13 2.91-5.65 5.502-1.56 1.589-6.18 6.951-8.32 9.196-2.14 2.244-4.62.377-3.94-.715 3.05-4.896 4.89-9.554 5-11.967.013-.434.187-.848.49-1.162ZM332 299.806h8a.636.636 0 0 1 .63.496l1.45 6.485a1.039 1.039 0 0 1-.211.904 1.053 1.053 0 0 1-.849.388c-2.89 0-7.07-.209-10.71-.209-4.25 0-6.11.228-11.1.228-3 0-3.85-3.029-2.59-3.307 5.75-1.251 8.62-1.38 13.59-4.419a3.455 3.455 0 0 1 1.79-.566Z"
		/>
		<path
			fill="#7F3E3B"
			d="M312.05 67.702c-1.13 2.83-2.33 5.462-3.57 8.163-1.24 2.702-2.56 5.323-3.92 7.945a121.189 121.189 0 0 1-9.45 15.503 62.686 62.686 0 0 1-3 3.724c-.55.626-1.11 1.202-1.77 1.847a15.682 15.682 0 0 1-2.49 1.907 29.078 29.078 0 0 1-4.62 2.274c-1.5.606-3 1.103-4.47 1.54a92.337 92.337 0 0 1-17.75 3.277l-1.12-5.363c5.35-1.916 10.79-3.913 15.8-6.137a75.508 75.508 0 0 0 3.6-1.768 20.3 20.3 0 0 0 3-1.897c.325-.243.623-.52.89-.824.35-.407.77-.884 1.15-1.37.77-.994 1.53-2.047 2.26-3.169 1.49-2.205 2.87-4.598 4.2-7.031s2.63-4.966 3.87-7.449c2.49-5.035 4.9-10.249 7.12-15.343l10.27 4.17Z"
		/>
		<path
			fill="currentColor"
			d="M328.82 48.852c10.54-.814 20 14.708 20 14.708l-7.57 12.216a54.7 54.7 0 0 1-15-8.869c-7.17-6.187-4.63-17.489 2.57-18.055Z"
		/>
		<path
			fill="#000"
			d="m328.16 61.931 11.09 14.828-.5.606s-3.82-2.98-8.24-7.29l-2.35-8.144Z"
			opacity={0.3}
		/>
		<path
			fill="currentColor"
			d="M299.591 60.422c-.59 5.115 8 27.738 10 54.572l39.11-3.823c-.28-5.145-3.14-26.815-8.32-51.276-1.43-6.753-6.7-11.917-13.51-10.596h-.19a144.156 144.156 0 0 0-16.18 4.121 71.976 71.976 0 0 0-8.76 3.844c-.593.31-1.099.76-1.474 1.311a4.023 4.023 0 0 0-.676 1.847Z"
		/>
		<path
			fill="#000"
			d="M303.551 79.212c.66 2.731 1.35 5.69 2 8.839 5.95-1.4 4.8-14.5 4.92-14.669l-6.92 5.83Z"
			opacity={0.3}
		/>
		<path
			fill="currentColor"
			d="M312.331 62.954c3 10.22-8.1 23.836-8.1 23.836l-11.18-7.151s-.08-9.365 3.49-16.675c4.53-9.256 13.65-7.3 15.79-.01Z"
		/>
		<path
			fill="#7F3E3B"
			d="m260 107.546-4.49-.606.07 9.316s6.05.615 9.41-3.526l-.66-1.748a5.383 5.383 0 0 0-1.688-2.311 5.446 5.446 0 0 0-2.642-1.125Z"
		/>
		<path
			fill="#7F3E3B"
			d="m247.841 109.095 2.87 7.588 4.91-.427-.07-9.316-7.71 2.155Z"
		/>
		<path
			fill="#000"
			d="m331.229 283.757.41 9.494h7.35l-.41-9.494h-7.35ZM389.541 260.766l-6.8 2.453 4.78 8.382 6.48-3.039-4.46-7.796Z"
			opacity={0.2}
		/>
		<path
			fill="#7F3E3B"
			d="M317.761 25.603c1.55 8.014 2.87 12.622-.1 17.697-4.46 7.628-15.2 6.505-19.12-.883-3.54-6.664-5.48-18.642 1.46-23.756a11.273 11.273 0 0 1 10.835-1.461 11.193 11.193 0 0 1 4.565 3.305 11.092 11.092 0 0 1 2.36 5.098Z"
		/>
		<path
			fill="#263238"
			d="M304.169 30.956c-4.92 2.443-5-5.363-4.61-7.022-1.85 1.768-4.35 3.546-8.89 1.6-5.58-2.394-4-8.65 0-8.571 0 0-3-5.661 3.56-6.257 2.7-.09 5.392.322 7.94 1.212 0 0 2.43-6.327 8.35-3.258 3.13 1.619 5.63 6.406 5.63 6.406s12.75 1.877 5.83 8.491c1.57 3.396.74 14.45-3.31 19.177-1.77 2.066-16.92 1.46-14.5-11.778Z"
		/>
		<path
			fill="#263238"
			d="M313.939 18.293s2.31-3.168 2.5-10.477c5.39 5.82-2.5 10.477-2.5 10.477Z"
		/>
		<path
			fill="#7F3E3B"
			d="M306.559 30.509a9.144 9.144 0 0 1-1.56 5.72c-1.5 2.215-3.54 1.262-4.2-1.072-.6-2.096-.54-5.78 1.49-7.17 2.03-1.391 4.06.079 4.27 2.522Z"
		/>
		<path
			fill="#263238"
			d="M323.48 113.644s13.8 54.622 20.42 75.011c7.24 22.286 38.66 80.711 38.66 80.711l12.33-5.333s-23.78-53.192-28.86-75.478c-5.51-24.152-17.31-77.404-17.31-77.404l-25.24 2.493Z"
		/>
		<path
			fill="currentColor"
			d="M378.911 265.334c-.05 0 4 5.75 4 5.75l13.85-6.078-2.8-5.69-15.05 6.018Z"
		/>
		<path
			fill="#000"
			d="M330.691 132.901c9.78.923 12.28 36.557 12.94 54.949-4.05-12.652-10.56-37.113-15.08-54.483a3.345 3.345 0 0 1 2.14-.466Z"
			opacity={0.3}
		/>
		<path
			fill="#263238"
			d="M309.611 114.994s1.59 56.132 4.58 78.199c3.11 22.952 14.61 96.334 14.61 96.334h12.45s-2.88-74.107-4.57-96.781c-1.84-24.719-1.36-80.284-1.36-80.284l-25.71 2.532Z"
		/>
		<path
			fill="currentColor"
			d="M326.321 283.757c-.05 0 1 7.478 1 7.478l15.13-.516.14-6.952-16.27-.01Z"
		/>
	</svg>
);
export default DefaultUpload;
