export  const columnsUserManagement = [
	{
		id: "checkbox",
		name: "Select",
		label: "Select",
		type: "checkbox",
		visible: false,
		required: false,
	},
	{
		id: "first_name",
		name: "First Name",
		label: "First Name",
		type: "text",
		required: true,
		visible: true,
	},
	{
		id: "last_name",
		name: "Last Name",
		label: "Last Name",
		type: "text",
		required: true,
		visible: true,
	},
	{
		id: "email",
		name: "Email Address",
		label: "Email Address",
		type: "text",
		required: true,
		visible: true,
	},
	{
		id: "role_list",
		name: "User Role",
		label: "User Role",
		type: "multiselect",
		required: true,
		options: ["Admin", "User"],
		visible: true,
	},
	{
		id: "client_name",
		name: "Client Name",
		label: "Client Name",
		type: "select",
		required: true,
		options: ["Client 1", "Client 2"],
		visible: true,
	},
	{
		id: "login_method",
		name: "Login Methods",
		label: "Login Methods",
		type: "select",
		required: true,
		options: ["SSO", "Email"],
		visible: true,
	},
	{
		id: "verification",
		name: "Verification",
		label: "Verification",
		type: "text",
		visible: false,
		required: false,
	},
];

export const columnsClientManagement = [
	{
		id: "checkbox",
		name: "Select",
		label: "Select",
		type: "checkbox",
		visible: false,
		required: false,
	},
	{
		id: "client_name",
		name: "Client Name",
		label: "Client Name",
		type: "text",
		required: true,
		visible: true,
	},
	{
		id: "storage_class",
		name: "Storage Class",
		label: "Storage Class",
		type: "text",
		required: true,
		visible: true,
	},
	{
		id: "email_domain",
		name: "Domain Name",
		label: "Domain Name",
		type: "text",
		required: true,
		visible: true,
	},
];


interface DataItem {
	[key: string]: any; // allow dynamic keys for data items
}


export const filterDataByVisibleColumns = (data:DataItem[]) => {
	// Get the list of keys to keep based on visible columns
	const allowedKeys = [
		"user_id",
		...columnsUserManagement
			.filter((ele) => ele.visible)
			.map((ele) => ele.id),
	];
  
	// Filter out keys from each data object that are not in the allowedKeys list
	return data.map((item) =>
	  Object.keys(item)
		.filter((key) => allowedKeys.includes(key))
		.reduce((filteredItem:DataItem, key) => {
		  filteredItem[key] = item[key];
		  return filteredItem;
		}, {})
	);
  };

  export const privacyPolicySections = {
	data: [{
		title: "1. Information We Collect",
		description: "We may collect the following types of information from users:",
		list: [
		  { title: "Personal Information:", content: "Name, email address, phone number, and other contact details." },
		  { title: "Usage Information:", content: "Information about your interaction with our website, such as IP address, browser type, device information, and pages visited." },
		  { title: "Cookies and Tracking Technologies:", content: "We may use cookies and similar technologies to track your activity on our website and collect information to improve our services." },
		],
	  },
	  {
		title: "2. How We Use Your Information",
		list: [
		  { content: "To provide and improve our services." },
		  { content: "To personalize your experience on our website." },
		  { content: "To communicate with you regarding updates, promotions, or customer service." },
		  { content: "To analyze website usage and improve website performance." },
		  { content: "To comply with legal obligations." },
		],
	  },
	  {
		  title : "3. Sharing of Information",
		  list: [
			  { content: "We do not sell or rent your personal information. We mayshare your information in the following cases" },
			  { content: "Service Providers: With trusted third-party vendors who assist us in operating our website and services." },
			],
	  }
	// Add more sections as needed
	],
	Version : '1.0'
};
  