import * as React from "react";

interface SvgComponentProps {
	[key: string]: any;
}

const RightArrowIcon: React.FC<SvgComponentProps> = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={32}
		height={32}
		fill="none"
		{...props}
	>
		<g filter="url(#a)">
			<circle cx={16} cy={12} r={12} fill="#fff" />
			<circle cx={16} cy={12} r={11.5} stroke="currentColor" />
		</g>
		<path
			fill="currentColor"
			d="m12.295 16.59 4.58-4.59-4.58-4.59L13.705 6l6 6-6 6-1.41-1.41Z"
		/>
		<defs>
			<filter
				id="a"
				width={32}
				height={32}
				x={0}
				y={0}
				colorInterpolationFilters="sRGB"
				filterUnits="userSpaceOnUse"
			>
				<feFlood floodOpacity={0} result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					result="hardAlpha"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
				/>
				<feOffset dy={4} />
				<feGaussianBlur stdDeviation={2} />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
				<feBlend
					in2="BackgroundImageFix"
					result="effect1_dropShadow_210_215"
				/>
				<feBlend
					in="SourceGraphic"
					in2="effect1_dropShadow_210_215"
					result="shape"
				/>
			</filter>
		</defs>
	</svg>
);
export default RightArrowIcon;
