import React, { useState } from "react";
import { Button, Typography, Box, TextField, Alert } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import BackArrow from "../../../assets/images/BackArrow.svg";
import Grid from "@mui/material/Grid2";
import { showLoader, hideLoader } from "../../../store/loaderSlice";
import frontendSettings from "../../../../frontend_settings.json";
import { toast } from "react-toastify";
import Login_photo from "../../../assets/images/Allianz_login.png";
import Logo from "../../../assets/images/Allianz.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { reset_password, verifyPassword } from "../../../api";
interface CreatePasswordProps {
	pageType: string;
	token: string;
}

const CreatePassword: React.FC<CreatePasswordProps> = ({ pageType, token }) => {
	const theme = useTheme();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [newPassword, setNewPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [error, setError] = useState("");
	const [success, setSuccess] = useState("");
	const handleBack = () => {
		navigate("/", { replace: true });
	};
	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		// Basic validation
		if (!newPassword) {
			setError("New password is required.");
			return;
		}
		if (!confirmPassword) {
			setError("Please confirm your password.");
			return;
		}
		if (newPassword !== confirmPassword) {
			setError("Passwords do not match.");
			return;
		}
		if (!isValidPassword(newPassword)) {
			setError("Password does not meet the requirements.");
			return;
		}
		setError(""); // Clear error
		// setSuccess("Your password has been successfully created.");
		try {
			dispatch(showLoader());
			const payload = {
				password: newPassword,
				token: token,
			};
			let response: any;
			if (pageType === "verify") {
				response = await verifyPassword(payload);
			} else if (pageType === "reset-password") {
				response = await reset_password(payload);
			}
			if (response?.success) {
				toast.success(
					response?.payload
				);
				dispatch(hideLoader());
				setTimeout(() => {
					navigate("/", { replace: true });
				}, 4000);
			} else {
				const err =
					response?.payload || "Something went wrong";
				toast.error(err);
				dispatch(hideLoader());
			}
		} catch (error) {
			const err: any = error;
			toast.error(err?.payload || "Something went wrong");
			dispatch(hideLoader());
		}
	};

	const isValidPassword = (password: string) => {
		const minLength = 8;
		const hasUpperCase = /[A-Z]/.test(password);
		const hasLowerCase = /[a-z]/.test(password);
		const hasNumbers = /\d/.test(password);
		const hasSpecialChar =
			/[!@#\$%\^&\*\(\)_\+\-=\[\]\{\};:'"\\|,.<>\/?]+/.test(password);
		return (
			password.length >= minLength &&
			hasUpperCase &&
			hasLowerCase &&
			hasNumbers &&
			hasSpecialChar
		);
	};

	return (
		<Grid
			container
			sx={{
				overflow: "hidden",
				background: theme.palette.background.default,
				height: "100vh",
			}}
		>
			<Grid size={{ xs: 12, md: 6 }}>
				<Grid>
					<Box
						component="img"
						sx={{
							ml: 2,
							mt: 2,
							width: "200px",
							height: "60px",
						}}
						src={
							frontendSettings
								? frontendSettings?.login?.photo
								: Logo
						}
						alt="Logo"
					/>
				</Grid>
				<Grid
					container
					sx={{ height: "80%" }}
					justifyContent="center"
					alignItems="center"
				>
					<Box
						textAlign="left"
						sx={{
							width: "500px",
							padding: "20px",
							color: theme.palette.primary.light,
						}}
					>
						<Box
							display="flex"
							alignItems="center"
							mb={4}
							gap={1}
							sx={{ cursor: "pointer" }}
							onClick={handleBack}
						>
							<img src={BackArrow} alt="Back" />
							<Typography
								variant="body1"
								sx={{
									fontSize: "1.25rem",
									color: theme.palette.text.primary,
								}}
							>
								Back
							</Typography>
						</Box>
						<Typography
							variant="body1"
							sx={{ fontSize: "1.25rem", mb: 2 }}
						>
							Create New Password
						</Typography>
						<Typography
							variant="body1"
							sx={{ fontSize: "1.25rem", mb: 2 }}
						>
							Your new password should be different from
							previously used passwords.
						</Typography>
						<form onSubmit={handleSubmit}>
							<TextField
								label="New Password"
								variant="outlined"
								type="password"
								fullWidth
								margin="normal"
								value={newPassword}
								onChange={(e) => setNewPassword(e.target.value)}
							/>
							<TextField
								label="Confirm Password"
								variant="outlined"
								type="password"
								fullWidth
								margin="normal"
								value={confirmPassword}
								onChange={(e) =>
									setConfirmPassword(e.target.value)
								}
							/>
							{error && (
								<Alert
									severity="error"
									style={{ marginTop: 16 }}
								>
									{error}
								</Alert>
							)}
							{success && (
								<Alert
									severity="success"
									style={{ marginTop: 16 }}
								>
									{success}
								</Alert>
							)}
							<Typography
								variant="body2"
								sx={{
									mb: 1,
									color: theme.palette.primary.light,
								}}
							>
								Password must contain:
							</Typography>
							<ul>
								<li>
									At least 8 characters—the more characters,
									the better
								</li>
								<li>
									A mixture of both uppercase and lowercase
									letters
								</li>
								<li>A mixture of letters and numbers</li>
								<li>
									Inclusion of at least one special character,
									e.g., ! @ # etc.
								</li>
							</ul>
							<Button
								type="submit"
								variant="contained"
								color="primary"
								sx={{
									height: 50,
									fontSize: "1.15rem",
									width: "100%",
									mt: 3,
									textTransform: "none",
								}}
							>
								Submit
							</Button>
						</form>
					</Box>
				</Grid>
			</Grid>
			<Grid size={{ xs: 12, md: 6 }}>
				<Box
					component="img"
					src={
						frontendSettings
							? frontendSettings?.login?.loginPhoto
							: Login_photo
					}
					sx={{
						backgroundPosition: "center center",
						backgroundRepeat: "no-repeat",
						backgroundSize: "contain",
						width: "100%",
						height: "100vh",
					}}
					alt="Login Photo"
				/>
			</Grid>
		</Grid>
	);
};

export default CreatePassword;
