import * as React from "react";
interface SvgComponentProps {
	[key: string]: any;
}
const SendIcon: React.FC<SvgComponentProps> = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={24}
		height={24}
		fill="none"
		{...props}
	>
		<path
			fill="currentColor"
			d="M1.79.625C.827.165-.231 1.045.044 2.076L2.06 9.61a1 1 0 0 0 .824.732l9.884 1.412c.286.04.286.454 0 .495l-9.883 1.411a1 1 0 0 0-.824.732L.044 21.928c-.275 1.03.783 1.91 1.746 1.451L23.288 13.13c.949-.452.949-1.804 0-2.256L1.79.624Z"
		/>
	</svg>
);
export default SendIcon;
