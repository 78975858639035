// src/components/SSOLogin.tsx
import React from "react";
import { Button, Typography, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

interface LoginStep1Props {
	welcomeMessage: string;
	signInPrompt: string;
	__TENANT_ID__: string;
	__CLIENT_ID__: string;
	__REDIRECT_URI__: string;
	privacyChecked: boolean;
}
const LoginStep1: React.FC<LoginStep1Props> = ({
	welcomeMessage,
	signInPrompt,
	__TENANT_ID__,
	__CLIENT_ID__,
	__REDIRECT_URI__,
	privacyChecked,
}) => {
	const theme = useTheme();
	const url = `https://login.microsoftonline.com/${
		__TENANT_ID__ || ""
	}/oauth2/v2.0/authorize?
        client_id=${__CLIENT_ID__ || ""}
        &response_type=code
        &redirect_uri=${__REDIRECT_URI__}
        &response_mode=query
        &scope=openid%20offline_access%20https%3A%2F%2Fgraph.microsoft.com%2FUser.read
        &state=12345`;

	return (
		<Box textAlign="center">
			<Typography
				sx={{
					fontWeight: 600,
					fontSize: "2rem",
					color: theme.palette.text.primary,
				}}
				variant="h4"
				gutterBottom
			>
				{welcomeMessage}
			</Typography>
			<Typography
				sx={{
					fontWeight: 400,
					color: theme.palette.text.secondary,
				}}
				variant="body1"
				gutterBottom
			>
				{signInPrompt}
			</Typography>
			<Button
				sx={{
					height: 50,
					fontSize: "1.15rem",
					width: "500px",
					mt: 3,
					textTransform: "none",
					backgroundColor: theme.palette.secondary.dark,
				}}
				fullWidth
				variant="contained"
				href={url}
				disabled={!privacyChecked}
			>
				Login with SSO
			</Button>
		</Box>
	);
};

export default LoginStep1;
