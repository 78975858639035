import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./authSlice";
import loaderReducer from "./loaderSlice";
import progressReducer from "./progressStatus"

const appStore = configureStore({
	reducer: {
		auth: authReducer,
		loader: loaderReducer,
		progress: progressReducer
	},
});
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof appStore.getState>;
export type AppDispatch = typeof appStore.dispatch;

export default appStore;
