import * as React from "react";
interface SvgComponentProps {
	[key: string]: any;
}
const HistoryIcon: React.FC<SvgComponentProps> = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={30}
		height={30}
		fill="none"
		{...props}
	>
		<path
			fill="currentColor"
			d="M14.958 27c-3.068 0-5.742-1.006-8.021-3.02-2.28-2.012-3.592-4.535-3.937-7.568L5.5 16c.358 2.438 1.128 4.871 2.972 6.476 1.845 1.604 4.006 2.406 6.486 2.406 2.762 0 5.106-.959 7.03-2.876 1.925-1.918 2.887-4.253 2.887-7.006s-.962-5.088-2.887-7.006c-1.924-1.918-4.268-2.876-7.03-2.876a9.495 9.495 0 0 0-4.351 1.027A10.7 10.7 0 0 0 7.11 8.973h3.705v2.117H3.624V3.924h2.125v3.345a12.02 12.02 0 0 1 4.13-3.148A11.89 11.89 0 0 1 14.958 3c1.67 0 3.237.315 4.697.945 1.46.63 2.733 1.486 3.82 2.568a12.148 12.148 0 0 1 2.577 3.807c.632 1.455.948 3.015.948 4.68 0 1.665-.316 3.225-.948 4.68a12.148 12.148 0 0 1-2.578 3.807 12.195 12.195 0 0 1-3.82 2.568c-1.46.63-3.025.945-4.696.945Zm4.253-6.315-5.275-5.256V7.94h2.125v6.63l4.643 4.626-1.493 1.488Z"
		/>
	</svg>
);
export default HistoryIcon;
