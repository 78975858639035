import * as React from "react";
interface SvgComponentProps {
	[key: string]: any;
}
const ThumbsDownIcon: React.FC<SvgComponentProps> = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={30}
		height={30}
		fill="none"
		{...props}
	>
		<path fill="#fff" d="M0 0h20v20H0z" />
		<path
			fill="currentColor"
			d="M5.571 3h7.858v9.286l-5 5-.893-.893a.94.94 0 0 1-.206-.34 1.169 1.169 0 0 1-.08-.41v-.25l.786-3.107H3.429a1.37 1.37 0 0 1-1-.429 1.37 1.37 0 0 1-.429-1V9.43c0-.084.012-.173.036-.268.024-.096.047-.185.071-.268L4.25 3.857c.107-.238.286-.44.536-.607.25-.167.512-.25.785-.25ZM12 4.429H5.571l-2.142 5v1.428h6.428l-.964 3.929L12 11.679v-7.25Zm1.429 7.857v-1.429h2.142V4.43H13.43V3H17v9.286h-3.571Z"
		/>
	</svg>
);
export default ThumbsDownIcon;
