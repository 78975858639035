import * as React from "react";
interface SvgComponentProps {
	[key: string]: any;
}
const ProgressIcon: React.FC<SvgComponentProps> = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={20}
		height={24}
		fill="none"
		{...props}
	>
		<path
			fill="currentColor"
			d="M10 2C4.477 2 0 6.477 0 12s4.477 10 10 10 10-4.477 10-10c-.006-5.52-4.48-9.994-10-10Z"
		/>
		<path
			fill="white"
			d="M13.922 10.923a.833.833 0 0 1-1.178 0l-1.91-1.911V17a.834.834 0 0 1-1.668 0V9.012l-1.91 1.911a.833.833 0 1 1-1.178-1.179L9.41 6.412a.834.834 0 0 1 1.181 0l3.333 3.333a.832.832 0 0 1-.002 1.178Z"
		/>
	</svg>
);
export default ProgressIcon;
