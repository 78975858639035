import ReactDOM from "react-dom/client";
import React, { ReactNode, useEffect, useState } from "react";
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import { initializeIcons } from "@fluentui/react";

import "./index.css";
// @ts-ignore */}
import Layout from "./pages/layout/Layout";
import NoPage from "./pages/NoPage";
import Chat from "./pages/chat/Chat";
import Upload from "./pages/upload/Upload";
import Login from "./pages/login/Login";
import { createTheme, ThemeProvider } from "@mui/material";
// import BulkUpload from "./components/upload/Upload";
import { Provider, useDispatch, useSelector } from "react-redux";
import appStore from "./store/appStore";
import { RootState } from "./store/appStore";
import DefaultLayout from "./components/defaultLayout/DefaultLayout";
import Homepage from "./pages/homepage/Homepage";
import Loader from "./components/loader/loader";
import { AppStateProvider } from "./state/AppProvider";
import ErrorBoundary from "./components/error/ErrorBoundary";
import frontendSettings from "../frontend_settings.json";
import UserManagement from "./pages/userManagement/userManagement";
import Verify from "./pages/verify/verify";
import ResetPassword from "./pages/resetPassword/resetPassword";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

initializeIcons();
interface PrivateRouteProps {
	children: ReactNode;
	requiredRole?: string;
}
export default function App() {
	const muiTheme = createTheme(frontendSettings.config);

	return (
		<AppStateProvider>
			<Provider store={appStore}>
				<BrowserRouter>
					<ThemeProvider theme={muiTheme}>
						<Loader />
						<ErrorBoundary>
							<Routes>
								{/* Login route - no defaultLayout */}
								<Route path="/" element={<Login />} />
								<Route path="/verify" element={<Verify />} />
								<Route
									path="/reset-password"
									element={<ResetPassword />}
								/>

								{/* Routes with defaultLayout */}
								<Route
									path="/homepage"
									element={
										<PrivateRoute>
											<DefaultLayout />
										</PrivateRoute>
									}
								>
									<Route
										index
										element={
											<PrivateRoute>
												<Homepage />
											</PrivateRoute>
										}
									/>
									<Route
										path="intelligence"
										element={
											<PrivateRoute>
												<Chat />
											</PrivateRoute>
										}
									/>
									<Route
										path="management"
										element={
											<PrivateRoute>
												<Upload />
											</PrivateRoute>
										}
									/>
									<Route
										path="user"
										element={
											<PrivateRoute requiredRole="Admin">
												<UserManagement />
											</PrivateRoute>
										}
									/>
								</Route>
								<Route
									path="*"
									element={
										<PrivateRoute>
											<NoPage />
										</PrivateRoute>
									}
								/>
							</Routes>
						</ErrorBoundary>
						<ToastContainer
							position="bottom-right"
							autoClose={5000}
							hideProgressBar={false}
						/>
					</ThemeProvider>
				</BrowserRouter>
			</Provider>
		</AppStateProvider>
	);
}
// PrivateRoute to protect routes based on authentication
const PrivateRoute: React.FC<PrivateRouteProps> = ({ children,requiredRole }) => {
	const userInfo:any = useSelector((state: RootState) => state.auth.user);
	const { isAuthenticated} = useSelector((state: RootState) => state.auth);
	  // If the user is not authenticated, redirect to login
		if (!isAuthenticated) {
			return <Navigate to="/" />;
		}
	
  // If a requiredRole is passed and the user does not have the required role, redirect
  if (requiredRole && !userInfo?.roles?.includes(requiredRole)) {
    return <Navigate to="/homepage" />;  // Redirect to homepage or an Unauthorized page
  }
	
		return <>{children}</>;
};

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
	<App />
);
