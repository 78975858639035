export const historyPanelMockLists = [
	{
		id: "ee60641d-94a1-43d4-8c69-180bcaf69773",
		title: "Initial Greeting",
		type: "thread",
		updatedAt: "2024-10-11T11:40:48.474397",
		createdAt: "2024-10-11T11:40:48.474397",
		userId: "test",
	},
	{
		id: "0acd1ff8-695f-4b20-969c-06a51b28c9f2",
		title: "Initial Greeting",
		type: "thread",
		updatedAt: "2024-10-11T11:35:45.632836",
		createdAt: "2024-10-11T11:35:45.632836",
		userId: "test",
	},
	{
		id: "28f5a61f-5f42-48bb-8121-e1032870478c",
		title: "Simple Greeting",
		type: "thread",
		updatedAt: "2024-10-11T11:35:19.500006",
		createdAt: "2024-10-11T11:35:19.500006",
		userId: "test",
	},
	{
		id: "265f09cb-0463-4034-91e1-bff15bbba960",
		title: "Request for Information",
		type: "thread",
		updatedAt: "2024-10-11T09:27:17.329509",
		createdAt: "2024-10-11T09:27:17.329509",
		userId: "test",
	},
	{
		id: "e974e212-1296-4772-9185-4a97e183a120",
		title: "Demand for Accurate Response",
		type: "thread",
		updatedAt: "2024-10-11T06:50:12.144181",
		createdAt: "2024-10-11T06:50:12.144181",
		userId: "test",
	},
	{
		id: "933f1971-13e1-45ff-bda0-cd711c5e588f",
		title: "Order Update Inquiry",
		type: "thread",
		updatedAt: "2024-10-11T06:44:51.441324",
		createdAt: "2024-10-11T06:44:51.441324",
		userId: "test",
	},
	{
		id: "1bb09a1e-b572-40e2-9f84-7a5043eb102b",
		title: "Initial Greeting",
		type: "thread",
		updatedAt: "2024-10-10T13:54:43.350158",
		createdAt: "2024-10-10T13:54:43.350158",
		userId: "test",
	},
	{
		id: "8dd64225-fd78-4ca1-81ce-0f3d63f2cb24",
		title: "Requesting Vendor List",
		type: "thread",
		updatedAt: "2024-10-10T13:39:37.670045",
		createdAt: "2024-10-10T13:39:37.670045",
		userId: "test",
	},
];
