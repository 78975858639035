import {
	UserInfo,
	ConversationRequest,
	Conversation,
	ChatMessage,
	CosmosDBHealth,
	CosmosDBStatus,
	AuthorizationPayload,
	History,
	ListOfFiles,
	UserData,
	UserMfaSetupInfo,
	UserLocalInfo,
	contractStatus,
} from "./models";
import { chatHistorySampleData } from "../constants/chatHistory";
import appStore from "../store/appStore";
import { toast } from "react-toastify";

const headers = {
	Authorization: `Bearer`,
};
const host = window.location.hostname === 'localhost' ? "http://localhost:8000/api" : "/api";

// refresh token related logic
export async function refreshAccessToken(): Promise<any> {
	const user = JSON.parse(localStorage.getItem("user") || "{}");
	const response = await fetch(host + "/auth/refresh-token", {
		method: "POST",
		headers: {
			Authorization: `Bearer ${user.access_token}`,
		},
	});
	const data = await response.json();
	if (data.message === "ExpiredRefreshError") {
		window.location.href = "/";
		localStorage.removeItem("user");
		return;
	}
	const updatedUser = {
		...user,
		access_token: data.access_token,
	};
	localStorage.setItem("user", JSON.stringify(updatedUser));

	return updatedUser;
}

async function fetchWithAuth(
	url: string,
	options: RequestInit,
): Promise<Response> {
	const user = JSON.parse(localStorage.getItem("user") || "{}");
	let token = user.access_token;

	// Set the Authorization header
	const headers = {
		...options.headers,
		Authorization: `Bearer ${token}`,
	};
	// Make the initial fetch request
	let response = await fetch(url, { ...options, headers });

	// Check if the response indicates an expired token
	if (response.status === 401) {
		const errorResponse = await response.json();
		if (errorResponse.payload === "Token Expired" || errorResponse.msg === 'ExpiredAccessError') {
			// Attempt to refresh the token
			try {
				await refreshAccessToken(); // Refresh the token
				const updatedUser = JSON.parse(
					localStorage.getItem("user") || "{}",
				);
				token = updatedUser.access_token; // Get the new access token

				// Retry the original request with the new token
				const retryHeaders = {
					...options.headers,
					Authorization: `Bearer ${token}`,
				};
				response = await fetch(url, {
					...options,
					headers: retryHeaders,
				});
			} catch (refreshError) {
				const err:any = refreshError;
				toast.error("Failed to refresh token:", err);

				throw refreshError; // Propagate the error if token refresh fails
			}
		}
	}

	// Return the final response
	return response;
}

export async function conversationApi(
	options: ConversationRequest,
	abortSignal: AbortSignal,
): Promise<Response> {
	const response = await fetchWithAuth(host + "/conversation", {
		method: "POST",
		body: JSON.stringify({
			messages: options.messages,
		}),
		signal: abortSignal,
	});

	return response;
}
export async function authorization(
	payload: AuthorizationPayload,
): Promise<any> {
	try {
		// Log the payload being sent
		// Perform the fetch request
		const response = await fetch(host + "/auth/getAToken", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(payload),
		});
		// Check if the response is successful
		if (!response.ok) {
			return null;
			throw new Error(`HTTP error! status: ${response.status}`);
		}

		// Parse the JSON from the response
		const data = await response.json();
		const userData = {
			username: "",
			role: "",
			email: "",
			access_token: data.access_token,
		};
		localStorage.setItem("user", JSON.stringify(userData));

		return userData;
	} catch (error) {
		// Log and throw the error to handle it in the caller
		const err:any = error;
		toast.error("Error during API request:", err);
		throw error;
	}
}

export const getUserInfo = async (): Promise<any> => {
	const response = await fetchWithAuth(
		// pass workspace as query params
		host + `/auth/identity`,
		{
			method: "GET",
		},
	);
	const payload = await response.json();
	return payload;
};

export const getCitationData = async (fileId: string, pageNumber: string) => {
	const response = await fetchWithAuth(
		// pass workspace as query params
		host + `/chat/citation?file_id=${fileId}&page_label=${pageNumber}`,
		{
			method: "GET",
		},
	)
		.then(async (res) => {
			const payload = await res.json();

			return payload;
		})
		.catch((err) => {
			console.error("There was an issue fetching your data.");
			return null;
		});
	return response;
};

export const historyList = async (offset = 0): Promise<History[] | null> => {
	const response = await fetchWithAuth(
		// pass workspace as query params
		host + `/chat/history/list?offset=${offset}`,
		{
			method: "GET",
		},
	)
		.then(async (res) => {
			const payload = await res.json();
			return payload;
		})
		.catch((err) => {
			console.error("There was an issue fetching your data.");
			return null;
		});
	return response;
};

export const historyRead = async (convId: string): Promise<any> => {
	const response = await fetchWithAuth(host + "/chat/history/read", {
		method: "POST",
		body: JSON.stringify({
			conversation_id: convId,
		}),
	})
		.then(async (res) => {
			if (!res) {
				return [];
			}
			const payload = await res.json();
			let messages: ChatMessage[] = [];
			if (payload?.messages) {
				payload.messages.forEach((msg: any) => {
					const message: ChatMessage = {
						id: msg.id,
						role: msg.role,
						date: msg.createdAt,
						content: msg.content,
						feedback: msg.feedback ?? undefined,
					};
					messages.push(message);
				});
			}
			return messages;
		})
		.catch((err) => {
			console.error("There was an issue fetching your data.");
			return [];
		});
	return response;
};

export const historyGenerate = async (
	options: ConversationRequest,
	abortSignal: AbortSignal,
	fileID : string,
	convId?: string,
): Promise<Response> => {
	let body;
	if (convId) {
		body = JSON.stringify({
			conversation_id: convId,
			messages: options.messages,
			contract_workspace: "",
			file_id: fileID,
		});
	} else {
		body = JSON.stringify({
			messages: options.messages,
			contract_workspace: "",
			file_id: fileID,
		});
	}
	const response = await fetchWithAuth(host + "/chat/history/generate", {
		method: "POST",
		body: body,
		signal: abortSignal,
	})
		.then((res) => {
			return res;
		})
		.catch((err) => {
			console.error("There was an issue fetching your data.");
			return new Response();
		});
	return response;
};

export const historyUpdate = async (
	messages: ChatMessage[],
	convId: string,
): Promise<Response> => {
	const response = await fetchWithAuth(host + "/chat/history/update", {
		method: "POST",
		body: JSON.stringify({
			conversation_id: convId,
			messages: messages,
		}),
	})
		.then(async (res) => {
			return res;
		})
		.catch((err) => {
			console.error("There was an issue fetching your data.");
			let errRes: Response = {
				...new Response(),
				ok: false,
				status: 500,
			};
			return errRes;
		});
	return response;
};

export const historyDelete = async (convId: string): Promise<Response> => {
	const response = await fetchWithAuth(host + "/chat/history/delete", {
		method: "DELETE",
		body: JSON.stringify({
			conversation_id: convId,
		}),
		headers: {
			"Content-Type": "application/json",
		},
	})
		.then((res) => {
			return res;
		})
		.catch((err) => {
			console.error("There was an issue fetching your data.");
			let errRes: Response = {
				...new Response(),
				ok: false,
				status: 500,
			};
			return errRes;
		});
	return response;
};

export const historyDeleteAll = async (): Promise<Response> => {
	const response = await fetchWithAuth(host + "/chat/history/delete_all", {
		method: "DELETE",
		body: JSON.stringify({}),
	})
		.then((res) => {
			return res;
		})
		.catch((err) => {
			console.error("There was an issue fetching your data.");
			let errRes: Response = {
				...new Response(),
				ok: false,
				status: 500,
			};
			return errRes;
		});
	return response;
};

export const historyClear = async (convId: string): Promise<Response> => {
	const response = await fetchWithAuth(host + "/chat/history/clear", {
		method: "POST",
		body: JSON.stringify({
			conversation_id: convId,
		}),
		headers: {
			"Content-Type": "application/json",
		},
	})
		.then((res) => {
			return res;
		})
		.catch((err) => {
			console.error("There was an issue fetching your data.");
			let errRes: Response = {
				...new Response(),
				ok: false,
				status: 500,
			};
			return errRes;
		});
	return response;
};

export const historyRename = async (
	convId: string,
	title: string,
): Promise<Response> => {
	const response = await fetchWithAuth(host + "/chat/history/rename", {
		method: "POST",
		body: JSON.stringify({
			conversation_id: convId,
			title: title,
		}),
		headers: {
			"Content-Type": "application/json",
		},
	})
		.then((res) => {
			return res;
		})
		.catch((err) => {
			console.error("There was an issue fetching your data.");
			let errRes: Response = {
				...new Response(),
				ok: false,
				status: 500,
			};
			return errRes;
		});
	return response;
};

export const historyEnsure = async (): Promise<CosmosDBHealth> => {
	const response = await fetchWithAuth(host + "/chat/history/ensure", {
		method: "GET",
	})
		.then(async (res) => {
			let respJson = await res.json();
			let formattedResponse;
			if (respJson.message) {
				formattedResponse = CosmosDBStatus.Working;
			} else {
				if (res.status === 500) {
					formattedResponse = CosmosDBStatus.NotWorking;
				} else if (res.status === 401) {
					formattedResponse = CosmosDBStatus.InvalidCredentials;
				} else if (res.status === 422) {
					formattedResponse = respJson.error;
				} else {
					formattedResponse = CosmosDBStatus.NotConfigured;
				}
			}
			if (!res.ok) {
				return {
					cosmosDB: false,
					status: formattedResponse,
				};
			} else {
				return {
					cosmosDB: true,
					status: formattedResponse,
				};
			}
		})
		.catch((err) => {
			console.error("There was an issue fetching your data.");
			console.log(err);
			return {
				cosmosDB: false,
				status: err,
			};
		});
	return response;
};

export const frontendSettings = async (): Promise<Response | null> => {
	const response = await fetchWithAuth(host + "/frontend_settings", {
		method: "GET",
	})
		.then((res) => {
			return res.json();
		})
		.catch((err) => {
			console.error("There was an issue fetching your data.");
			return null;
		});

	return response;
};
export const historyMessageFeedback = async (
	convId: string | undefined,
	messageId: string,
	feedback: string,
	additionalFeedback: string,
): Promise<Response> => {
	// const response = await fetch(host + "/history/message_feedback", {
	const response = await fetchWithAuth(
		host + "/chat/threads/" + convId + "/messages/" + messageId,
		{
			method: "POST",
			body: JSON.stringify({
				conversation_id: convId,
				message_id: messageId,
				message_feedback: feedback,
				additional_feedback: additionalFeedback,
			}),
		},
	)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			console.error("There was an issue logging feedback.");
			let errRes: Response = {
				...new Response(),
				ok: false,
				status: 500,
			};
			return errRes;
		});
	return response;
};

export async function downloadExport(
	site_index: string,
	fileId: number,
): Promise<string> {
	const response = await fetchWithAuth(
		host + "/" + "default" + "/download_export/" + fileId,
		{
			method: "GET",
		},
	);
	if (!response.ok) {
		throw new Error(
			`Failed to download file (file_id: ${fileId}): ${response.status}`,
		);
	}
	const responseJson = await response.json();
	return responseJson.sasUrl;
}


export async function listReadyFiles() {
	const response = await fetchWithAuth(host + "/default/ready_file", {
		method: "GET",
	});
	if (!response.ok) {
		throw new Error(`Failed to get files: ${response.status}`);
	}

	const responseJson = await response.json();

	const newFiles: any[] = [];
	Object.keys(responseJson).forEach((fileKey) => {
		const file = responseJson[fileKey];

		if (file["Metadata"]) {
			file["Metadata"] = JSON.parse(file["Metadata"]);
		}
		newFiles.push(file);
	});
	return responseJson;
}

export async function listFiles(): Promise<ListOfFiles[]> {
	const response = await fetchWithAuth(host + "/default/file", {
		method: "GET",
	});
	if (!response.ok) {
		toast.error(`Failed to get files: ${response.status}`)
		throw new Error(`Failed to get files: ${response.status}`);
	}

	const responseJson = await response.json();

	const newFiles: any[] = [];
	Object.keys(responseJson).forEach((fileKey) => {
		const file = responseJson[fileKey];

		if (file["Metadata"]) {
			file["Metadata"] = JSON.parse(file["Metadata"]);
		}
		newFiles.push(file);
	});
	return responseJson;
}

export async function uploadFile(
	formData: FormData,
	onProgress: (percent: number) => void,
) {
	const response = await fetchWithAuth(host + `/default/file`, {
		method: "POST",
		body: formData,
	});

	if (!response.ok) {
		throw new Error(`Failed to upload file: ${response.status}`);
	}

	// Check if response body is available
	const reader = response.body?.getReader();
	if (!reader) {
		throw new Error("Failed to get response body for progress tracking.");
	}

	const contentLength = response.headers.get("Content-Length");
	const totalLength = contentLength ? parseInt(contentLength, 10) : null;

	let receivedLength = 0;
	const chunks = [];

	while (true) {
		const { done, value } = await reader.read();
		if (done) break;
		chunks.push(value);
		receivedLength += value.length;

		if (totalLength) {
			const percentComplete = (receivedLength / totalLength) * 100;
			onProgress(percentComplete);
		}
	}

	return new Response(new Blob(chunks)); // Handle the response as needed
}

export async function viewContractFile(fileId: number) {
	const response = await fetchWithAuth(host + "/default/contract/" + fileId, {
		method: "GET",
	});
	if (!response.ok) {
		throw new Error(`Failed to view contract: ${response.status}`);
	}

	const responseJson = await response.json();

	return responseJson;
}

export async function DeleteFile(fileId: number) {
	const response = await fetchWithAuth(host + "/default/file/" + fileId, {
		method: "DELETE",
	});
	if (!response.ok) {
		throw new Error(`Failed to Delete File: ${response.status}`);
	}

	const responseJson = await response.json();

	return responseJson;
}

export async function getContractStatus(): Promise<contractStatus[]> {
	try {
		const response = await fetchWithAuth(host + "/default/contract", {
			method: "GET",
		});

		if (!response.ok) {
			toast.error(`Failed to get status: ${response.status}`);
			throw new Error(`Failed to get status: ${response.status}`);
		}

		const responseJson = await response.json();
		
		return responseJson;
	} catch (error) {
		console.error("Error fetching contract status:", error);
		toast.error("An error occurred while fetching contract status.");
		throw error; 
	}
}
export const prelogin = async (
	UserData: UserData
): Promise<any> => {
	try{
		const response = await fetch(host + "/auth/pre-login", {
			method: "POST",
			body: JSON.stringify({
				email: UserData.email,
				password: UserData.password,
			}),
			headers: {
				"Content-Type": "application/json",
			},
		})
		if(response){
			return response.json();
		}
	}
	catch(error:any){
		return error.response;
	}

	
};


export const getEmailAuthcode = async (
	UserData: UserData
): Promise<any> => {
	try{
	const response = await fetch(host + "/auth/mfa-code/mail", {
		method: "POST",
		body: JSON.stringify({
			email: UserData.email,
			password: UserData.password,
		}),
		headers: {
			"Content-Type": "application/json",
		},
	});	
	if(response){
		return response.json();
	}
	}
	catch(error:any){
		return error.response;
	}
}

export const setupmfa = async (
	UserMfaSetupInfo: UserMfaSetupInfo
): Promise<any> => {
	try {
		const response = await fetch(host + "/auth/mfa-setup", {
			method: "POST",
			body: JSON.stringify({
				email: UserMfaSetupInfo.email,
				password: UserMfaSetupInfo.password,
				code: UserMfaSetupInfo.code,
				mfa_secret: UserMfaSetupInfo.mfa_secret,
			}),
			headers: {
				"Content-Type": "application/json",
			},
		})
		if(response){
			return response.json();
		}
	}
	catch(error:any){
		return error.response;
	}
};

export const loginLocal = async (
	UserLocalInfo: UserLocalInfo
): Promise<any> => {
	try {
	const response = await fetch(host + "/auth/login", {
		method: "POST",
		body: JSON.stringify({
			email: UserLocalInfo.email,
			password: UserLocalInfo.password,
			code: UserLocalInfo.code,
			code_source: UserLocalInfo.code_source,
		}),
		headers: {
			"Content-Type": "application/json",
		},
	})		
	if(response){
		return response.json();
	}
}
catch(error:any){
	return error.response;
}

};

export const passwordEmailGenerator = async (userMail: string): Promise<any> => {
  try {
    const response = await fetch(`${host}/auth/forgot-password?email=${userMail}`, {
      method: "GET",
		headers: {
			"Content-Type": "application/json",
		},
    });

		if(response){
			return response.json();
		}
	}
	catch(error:any){
		return error.response;
	}
};

export const getUserList = async (search:string,page:number,rowsPerPage:number): Promise<any> => {
	try{
		const response = await fetchWithAuth(
			// pass workspace as query params
			// make page_size dynamic
			host + `/user-mgmt/users?page_number=${page}&page_size=${rowsPerPage}&name=${search}`,
			{
				method: "GET",
			},
		);
		if(response){
			return response.json();
		}
	}
	catch(error:any){
		return error.response;
	}

};

export const getUserListDropdowns = async (): Promise<any> => {
	try{
	const response = await fetchWithAuth(
		// pass workspace as query params
		host + `/user-mgmt/user-creation-data`,
		{
			method: "GET",
		},
	);		
	if(response){
		return response.json();
	}
}
catch(error:any){
	return error.response;
}
};

export const DeleteUser = async (val:any): Promise<any> => {
	try {
		const response = await fetchWithAuth(host + "/user-mgmt/users", {
			method: "DELETE",
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(val), // val = {user_ids : [23]}
		});

		if(response){
			return response.json();
		}
	}
	catch(error:any){
		return error.response;
	}
};

export const CreateUser = async (form_data:any): Promise<any> => {
	try {
		const response = await fetchWithAuth(host + "/user-mgmt/users", {
			method: "POST",
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(form_data),
		});

		if(response){
			return response.json();
		}
	}
	catch(error:any){
		return error.response;
	}
};

export const reset_password = async (verify_reset_Data:any): Promise<any> => {
	try {
		const response = await fetch(host + "/auth/forgot-password", {
			method: "POST",
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${verify_reset_Data.token}`,
			},
			body: JSON.stringify(
				{new_password:verify_reset_Data.password}),
		});

		if(response){
			return response.json();
		}
	}
	catch(error:any){
		return error.response;
	}
}
export const verifyPassword = async (verify_reset_Data:any): Promise<any> => {
	try {
		const response = await fetch(host + "/auth/verify", {
			method: "POST",
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${verify_reset_Data.token} `,
			},
			body: JSON.stringify(
				{new_password:verify_reset_Data.password}),
		});

		if(response){
			return response.json();
		}
	}
	catch(error:any){
		return error.response;
	}
}
export const updateUser = async (form_data:any): Promise<any> => {
	try {
		const response = await fetchWithAuth(host + "/user-mgmt/users", {
			method: "PUT",
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(form_data), 
		});

		if(response){
			return response.json();
		}
	}
	catch(error:any){
		return error.response;
	}
};

export const getClients = async (search:string,page:number,rowsPerPage:number): Promise<any> => {
	try{
	const response = await fetchWithAuth(
		// pass workspace as query params
		// make page_size dynamic
		host + `/client-mgmt/clients?page_number=${page}&page_size=${rowsPerPage}&name=${search}`,
		{
			method: "GET",
		},
	);		
	if(response){
		return response.json();
	}
}
catch(error:any){
	return error.response;
}
};

export const Createclient = async (form_data:any): Promise<any> => {
	try {
		const response = await fetchWithAuth(host + "/client-mgmt/clients", {
			method: "POST",
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(form_data),
		});
		
		if(response){
			return response.json();
		}
	}
	catch(error:any){
		return error.response;
	}
};
export const updateClient = async (form_data:any): Promise<any> => {
	try {
		const response = await fetchWithAuth(host + "/client-mgmt/clients", {
			method: "PUT",
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(form_data), 
		});

		if(response){
			return response.json();
		}
	}
	catch(error:any){
		return error.response;
	}
};
export const DeleteClient = async (val:any): Promise<any> => {
	try {
		const response = await fetchWithAuth(host + "/client-mgmt/clients", {
			method: "DELETE",
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(val), 
		});

		if(response){
			return response.json();
		}
	}
	catch(error:any){
		return error.response;
	}
};