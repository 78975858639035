import * as React from "react";
interface SvgComponentProps {
	[key: string]: any;
}
const ThumbsUpIcon: React.FC<SvgComponentProps> = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={30}
		height={30}
		fill="none"
		{...props}
	>
		<path fill="#fff" d="M0 0h20v20H0z" />
		<path
			fill="currentColor"
			d="M13.429 17.286H5.57V8l5-5 .893.893a.937.937 0 0 1 .206.34c.053.142.08.279.08.41v.25L10.964 8h4.607c.381 0 .715.143 1 .429.286.285.429.619.429 1v1.428c0 .084-.012.173-.036.268a8.424 8.424 0 0 1-.071.268l-2.143 5.036c-.107.238-.286.44-.536.607-.25.166-.512.25-.785.25ZM7 15.857h6.429l2.142-5V9.43H9.143l.964-3.929L7 8.607v7.25ZM5.571 8v1.429H3.43v6.428H5.57v1.429H2V8h3.571Z"
		/>
	</svg>
);
export default ThumbsUpIcon;
