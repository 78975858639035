import React, { useState } from "react";
import { Button, Typography, Box, TextField, Alert } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import { showLoader, hideLoader } from "../../../store/loaderSlice";
import { prelogin } from "../../../api";
import { preLogin } from "../../../store/authSlice";
import { toast } from "react-toastify";

interface UserData {
	email: string;
	password: string;
}
interface LoginStep2Props {
	setIsOtpScreen: (value: boolean) => void;
	setIsForgotPassword: (value: boolean) => void;
	setUserData: (data: { email: string; password: string }) => void; // Pass the user data as an object
	privacyChecked: boolean;
}

const LoginStep2: React.FC<LoginStep2Props> = ({
	setIsOtpScreen,
	setIsForgotPassword,
	setUserData,
	privacyChecked,
}) => {
	const theme = useTheme();
	const [userData, setUserDataState] = useState<{
		email: string;
		password: string;
	}>({
		email: "",
		password: "",
	}); // userData as an object with email and password
	const [error, setError] = useState("");
	const dispatch = useDispatch();

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault();
		const { email, password } = userData; // Destructure the object
		// Basic validation
		if (!email || !password) {
			setError("Both fields are required.");
			return;
		}
		if (!/\S+@\S+\.\S+/.test(email)) {
			setError("Please enter a valid email address.");
			return;
		}
		if (password.length < 6) {
			setError("Password must be at least 6 characters.");
			return;
		}
		setError(""); // Clear error
		// Save the user data to the parent component
		setUserData({ email, password }); // Pass user data to parent
		userPreloginAction(userData);
	};
	const userPreloginAction = async (userData: UserData) => {
		dispatch(showLoader());
		try {
			const resp: any = await prelogin(userData);

			if (resp && resp.success) {
				dispatch(preLogin(resp?.data?.mfa_configured));
				setIsOtpScreen(true);
				dispatch(hideLoader());
			} else {
				toast.error(
					resp?.payload || "something went wrong"
				);
				dispatch(hideLoader());
			}
		} catch (error) {
			const err: any = error;
			toast.error(err?.payload || "something went wrong");
			dispatch(hideLoader());
		}
	};

	const handleForgotPassword = () => {
		setIsForgotPassword(true); // Set isForgotPassword to true
	};

	const handleChange = (field: "email" | "password", value: string) => {
		setUserDataState((prev) => ({ ...prev, [field]: value })); // Update user data object
	};

	return (
		<Box textAlign="center" sx={{ width: "500px" }}>
			<form onSubmit={handleSubmit}>
				<TextField
					label="Email ID"
					variant="outlined"
					fullWidth
					margin="normal"
					value={userData.email} // email is the key of the object
					onChange={(e) => handleChange("email", e.target.value)} // Update email
				/>
				<TextField
					label="Password"
					variant="outlined"
					type="password"
					fullWidth
					margin="normal"
					value={userData.password} // password is the key of the object
					onChange={(e) => handleChange("password", e.target.value)} // Update password
				/>
				{error && (
					<Alert severity="error" style={{ marginTop: 16 }}>
						{error}
					</Alert>
				)}
				<Typography
					sx={{
						textAlign: "right",
						color: theme.palette.primary.main,
						cursor: "pointer",
					}}
					onClick={handleForgotPassword}
				>
					Forgot Password?
				</Typography>
				<Button
					type="submit"
					variant="contained"
					color="primary"
					disabled={!privacyChecked}
					sx={{
						height: 50,
						fontSize: "1.15rem",
						width: "100%",
						mt: 3,
						textTransform: "none",
					}}
				>
					Sign in
				</Button>
			</form>
		</Box>
	);
};

export default LoginStep2;
