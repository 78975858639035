import React, { useState, ChangeEvent, useEffect } from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	TextField,
	Select,
	MenuItem,
	Button,
	DialogActions,
	FormControl,
	InputLabel,
	FormHelperText,
} from "@mui/material";

// Define the type for each field
type Field = {
	id: string;
	name: string;
	label: string;
	type: string;
	required: boolean;
	options?: string[];
	visible: boolean;
};

// Define the props for ReusableDialog
interface ReusableDialogProps {
	open: boolean;
	onClose: () => void;
	title: string;
	fields: Field[];
	editData?: Record<string, string> | null;
	onSubmit: (data: Record<string, string>) => void;
	buttonText: string;
}

// Generic Dialog Component
const ReusableDialog: React.FC<ReusableDialogProps> = ({
	open,
	onClose,
	title,
	fields,
	editData,
	onSubmit,
	buttonText,
}) => {
	const [formData, setFormData] = useState<Record<string, string>>({});
	const [formErrors, setFormErrors] = useState<Record<string, string>>({});

	useEffect(() => {
		if (editData) {
			setFormData(editData);
		} else {
			setFormData({});
		}
	}, [editData]);

	// Handle field changes
	const handleChange = (field: string, value: string) => {
		setFormData((prev) => ({ ...prev, [field]: value }));
	};

	// Submit form data
	const handleSubmit = () => {
		if (validateForm()) {
			onSubmit(formData);
			onClose();
			setFormData({});
			setFormErrors({});
		}
	};

	// Validate form data
	const validateForm = () => {
		const errors: Record<string, string> = {};
		fields.forEach((field) => {
			if (field.required && !formData[field.id]) {
				errors[field.id] = `${field.label} is required`;
			} else if (field.id === "email" && formData[field.id]) {
				// Email validation regex pattern
				const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
				if (!emailPattern.test(formData[field.id])) {
					errors[field.id] = "Invalid email format";
				}
			}
		});
		setFormErrors(errors);
		return Object.keys(errors).length === 0;
	};

	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth
			maxWidth="lg"
			sx={{
				"& .MuiDialog-paper": {
					minHeight: "350px",
					borderRadius: 2,
				},
			}}
		>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent>
				<p>Enter user details below</p>
				<div style={{ display: "flex", flexWrap: "wrap", gap: "1rem" }}>
					{fields.map(
						(field, index) =>
							field.visible && (
								<FormControl
									key={index}
									required={field.required}
								>
									{field.type === "text" && (
										<TextField
											label={field.label}
											sx={{ width: "350px" }}
											value={formData[field.id] || ""}
											onChange={(
												e: ChangeEvent<HTMLInputElement>
											) =>
												handleChange(
													field.id,
													e.target.value
												)
											}
											required={field.required}
											error={
												formErrors[field.id]
													? true
													: false
											}
											helperText={formErrors[field.id]}
										/>
									)}
									{field.type === "select" &&
										field.options && (
											<>
												<InputLabel>
													{field.label}
												</InputLabel>
												<Select
													label={field.label}
													sx={{ width: "350px" }}
													value={
														formData[field.id] || ""
													}
													onChange={(e) =>
														handleChange(
															field.id,
															e.target
																.value as string
														)
													}
													required={field.required}
													error={
														formErrors[field.id]
															? true
															: false
													}
												>
													{field.options.map(
														(option) => (
															<MenuItem
																key={option}
																value={option}
															>
																{option}
															</MenuItem>
														)
													)}
												</Select>
												{formErrors[field.id] && (
													<FormHelperText
														sx={{ color: "red" }}
													>
														{formErrors[field.id]}
													</FormHelperText>
												)}
											</>
										)}
									{field.type === "multiselect" &&
										field.options && (
											<>
												<InputLabel>
													{field.label}
												</InputLabel>
												<Select
													label={field.label}
													sx={{ width: "350px" }}
													multiple
													value={
														(formData[field.id] ||
															[]) as string[]
													}
													onChange={(e) =>
														handleChange(
															field.id,
															e.target
																.value as string
														)
													}
													required={field.required}
													error={
														formErrors[field.id]
															? true
															: false
													}
													renderValue={(selected) =>
														(
															selected as string[]
														).join(", ")
													}
												>
													{field.options.map(
														(option) => (
															<MenuItem
																key={option}
																value={option}
															>
																{option}
															</MenuItem>
														)
													)}
												</Select>
												{formErrors[field.id] && (
													<FormHelperText
														sx={{ color: "red" }}
													>
														{formErrors[field.id]}
													</FormHelperText>
												)}
											</>
										)}
								</FormControl>
							)
					)}
				</div>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={onClose}
					variant="outlined"
					color="error"
					sx={{ width: "155px", mr: 1 }}
				>
					Cancel
				</Button>
				<Button
					variant="contained"
					color="primary"
					onClick={handleSubmit}
					sx={{ width: "155px" }}
				>
					{buttonText}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ReusableDialog;
