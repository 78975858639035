import React, { ErrorInfo } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Error caught by Error Boundary:", error, errorInfo);
  }

  handleReset = () => {
    this.setState({ hasError: false });
  };

  render() {
    if (this.state.hasError) {
      return (
        <ErrorBoundaryFallback onReset={this.handleReset} />
      );
    }

    return this.props.children; 
  }
}

// Create a fallback component to handle the UI
const ErrorBoundaryFallback: React.FC<{ onReset: () => void }> = ({ onReset }) => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    onReset(); // Reset the error state
    navigate('/homepage'); // Navigate to the home page
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        bgcolor: 'background.default',
        p: 3,
        borderRadius: 2,
        boxShadow: 3,
      }}
    >
      <Typography variant="h4" color="error" gutterBottom>
        Oops! Something went wrong.
      </Typography>
      <Typography variant="body1" color="textSecondary" gutterBottom>
        We encountered an unexpected error. Please try again later.
      </Typography>
      <Button variant="contained" color="primary" onClick={handleGoHome}>
        Go Back to Home
      </Button>
    </Box>
  );
};

export default ErrorBoundary;