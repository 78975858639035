import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import TablePagination from "@mui/material/TablePagination";
import { styled } from "@mui/material/styles";
import { DeleteFile } from "../../api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Typography from "@mui/material/Typography"; // Import Typography
import { showLoader, hideLoader } from "../../store/loaderSlice";
import { useDispatch } from "react-redux";
import DeleteIcon from "../../assets/images/Delete.svg";
import SuccessIcon from "../../assets/images/Completed.svg";
import ProcessingIcon from "../../assets/images/Processing.svg";
import FailedIcon from "../../assets/images/FailedIcon.svg";
import HourGlassIcon from "../../assets/images/HourGlass.svg";

const StyledTableFooter = styled(TableFooter)(({ theme }) => ({
	borderRadius: "0 0 5px 5px",
	background: "#F5F5F5",
}));

function TablePaginationActions(props: {
	count: number;
	page: number;
	rowsPerPage: number;
	onPageChange: (
		event: React.MouseEvent<HTMLButtonElement>,
		page: number
	) => void;
}) {
	const { count, page, rowsPerPage, onPageChange } = props;

	const handleFirstPageButtonClick = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		onPageChange(event, 0);
	};

	const handleBackButtonClick = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		onPageChange(event, page - 1);
	};

	const handleNextButtonClick = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		onPageChange(event, page + 1);
	};

	const handleLastPageButtonClick = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	return (
		<Box sx={{ flexShrink: 0, ml: 2.5 }}>
			<IconButton
				onClick={handleFirstPageButtonClick}
				disabled={page === 0}
				aria-label="first page"
			>
				&lt;&lt;
			</IconButton>
			<IconButton
				onClick={handleBackButtonClick}
				disabled={page === 0}
				aria-label="previous page"
			>
				&lt;
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="next page"
			>
				&gt;
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="last page"
			>
				&gt;&gt;
			</IconButton>
		</Box>
	);
}

TablePaginationActions.propTypes = {
	count: PropTypes.number.isRequired,
	onPageChange: PropTypes.func.isRequired,
	page: PropTypes.number.isRequired,
	rowsPerPage: PropTypes.number.isRequired,
};
export default function CustomPaginationActionsTable({
	files,
	fetchFiles,
}: any) {
	const theme = useTheme();
	const [page, setPage] = useState<number>(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const [rows, setRows] = useState<any[]>([]);
	const dispatch = useDispatch();
	const formatDate = (dateString: string): string => {
		const date = new Date(dateString);
		return date.toISOString().split("T")[0]; // Formats to 'YYYY-MM-DD'
	};

	useEffect(() => {
		setRows(Array.isArray(files) ? files : []); // Ensure rows is always an array
	}, [files]);

	const handleChangePage = (
		event: React.MouseEvent<HTMLButtonElement> | null,
		newPage: number
	) => {
		setPage(newPage);
	};
	const handleDelete = async (item: any) => {
		try {
			dispatch(showLoader());

			await DeleteFile(item.file_id);
			setRows((prevRows) =>
				prevRows.filter((row) => row.file_id !== item.file_id)
			);
			await fetchFiles();
			toast.success(`Successfully deleted the file ${item.file_name}`);
		} catch (error) {
			toast.error(`Failed to delete the file ${item.file_name}`);
			dispatch(hideLoader());
		}
		dispatch(hideLoader());
	};
	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<
			HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
		>
	) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const paginatedRows = rows.slice(
		page * rowsPerPage,
		page * rowsPerPage + rowsPerPage
	);
	const renderStatusIcon = (status: string) => {
		switch (status) {
			case "Completed":
				return (
					<img
						src={SuccessIcon}
						alt="Success"
						style={{ width: 20, marginRight: 4 }}
					/>
				);
			case "New":
				return (
					<img
						src={HourGlassIcon}
						alt="Success"
						style={{ width: 20, marginRight: 4 }}
					/>
				);
			case "Processing":
				return (
					<img
						src={ProcessingIcon}
						alt="Processing"
						style={{ width: 20, marginRight: 4 }}
					/>
				);
			case "Failed":
				return (
					<img
						src={FailedIcon}
						alt="Failed"
						style={{ width: 20, marginRight: 4 }}
					/>
				);
			default:
				return null;
		}
	};
	return (
		<Box>
			<TableContainer component={Paper}>
				<Table
					sx={{ minWidth: 500 }}
					aria-label="custom pagination table"
				>
					<TableHead>
						<TableRow>
							{[
								"Document ID",
								"Document Name",
								"Document Type",
								"Process Status",
								"Upload Date",
								"Delete",
							].map((header) => (
								<TableCell
									key={header}
									sx={{
										border: "none",
										background: "#F5F5F5",
										fontWeight: 700,
									}}
								>
									<Typography
										sx={{
											fontSize: "0.875rem",
											color: theme.palette.primary.light,
										}}
										variant="body1"
									>
										{header}
									</Typography>{" "}
									{/* Use Typography here */}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{paginatedRows.length > 0 ? (
							paginatedRows.map((row) => (
								<TableRow key={row.file_id}>
									<TableCell component="th" scope="row">
										<Typography
											sx={{
												fontSize: "1rem",
												color: theme.palette.text
													.primary,
											}}
											variant="body2"
										>
											{row.file_id}
										</Typography>{" "}
										{/* Use Typography */}
									</TableCell>
									<TableCell component="th" scope="row">
										<Typography
											sx={{
												fontSize: "1rem",
												color: theme.palette.text
													.primary,
											}}
											variant="body2"
										>
											{row.file_name}
										</Typography>{" "}
										{/* Use Typography */}
									</TableCell>
									<TableCell align="left">
										<Typography
											sx={{
												fontSize: "1rem",
												color: theme.palette.text
													.primary,
											}}
											variant="body2"
										>
											{row.file_type || "N/A"}
										</Typography>{" "}
										{/* Use Typography */}
									</TableCell>
									<TableCell align="left">
										<div
											style={{
												display: "flex",
												alignItems: "center",
											}}
										>
											{renderStatusIcon(row.status)}
											<Typography
												sx={{
													fontSize: "1rem",
													color: theme.palette.text
														.primary,
												}}
												variant="body2"
											>
												{row.status}
											</Typography>
										</div>
									</TableCell>
									<TableCell align="left">
										<div
											style={{
												display: "flex",
												gap: "25px",
											}}
										>
											{formatDate(row.created_at)}
										</div>
									</TableCell>
									<TableCell align="left">
										<div
											style={{
												display: "flex",
												cursor: "pointer",
											}}
											onClick={() => {
												handleDelete(row);
											}}
										>
											<img
												src={DeleteIcon}
												alt="Delete"
											/>
										</div>
									</TableCell>
								</TableRow>
							))
						) : (
							<TableRow>
								<TableCell colSpan={6} align="center">
									<Typography variant="body1">
										No data available
									</Typography>{" "}
									{/* Use Typography */}
								</TableCell>
							</TableRow>
						)}
					</TableBody>
					<StyledTableFooter>
						<TableRow>
							<TablePagination
								rowsPerPageOptions={[
									5,
									10,
									25,
									{ label: "All", value: -1 },
								]}
								colSpan={6}
								count={rows.length}
								rowsPerPage={rowsPerPage}
								page={page}
								onPageChange={handleChangePage}
								onRowsPerPageChange={handleChangeRowsPerPage}
								ActionsComponent={TablePaginationActions}
							/>
						</TableRow>
					</StyledTableFooter>
				</Table>
			</TableContainer>
		</Box>
	);
}

CustomPaginationActionsTable.propTypes = {
	files: PropTypes.array.isRequired,
	fetchFiles: PropTypes.func.isRequired,
};
