import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid2";
import { Box, Typography, CardContent, Card } from "@mui/material";
import Login_photo from "../../assets/images/BgImage.png";
import { useTheme } from "@emotion/react";
import StorageIcon from "../../assets/images/StorageIcon";
import VectorIcon from "../../assets/images/VectorIcon";
import Arrow from "../../assets/images/Arrow";
import { useNavigate } from "react-router-dom";
import { contractStatus, getContractStatus } from "../../api";
import frontendSettings from "../../../frontend_settings.json";
import { hideLoader, showLoader } from "../../store/loaderSlice";
import { useDispatch } from "react-redux";

const Homepage: React.FC = () => {
	const theme: any = useTheme();
	const navigate = useNavigate();
	const [progVal,setProgVal] = useState(0);
	const dispatch = useDispatch();

	useEffect(() => {
		fetchStatus();
	}, []);

	const fetchStatus = async() => {
		try {
			dispatch(showLoader());
			const fetchedStatus: contractStatus[] = await getContractStatus();
			if (fetchedStatus && fetchedStatus.length > 0) {
				const response = fetchedStatus[0] // fetching 1st result as per BE api 
				setProgVal(parseInt(response.percent_status) || 0);
			}	
		}
		catch (error) {
			console.error("Error fetching status:", error);
		
			dispatch(hideLoader());
		}
		dispatch(hideLoader());
			}

	return (
		<Grid sx={{ background: "#fff" }}>
			{/* Header Section */}
			<Card
				sx={{
					backgroundImage: `url(${
						frontendSettings && frontendSettings.login.loginPhoto
					})`,
					backgroundSize: "cover",
					backgroundRepeat: "no-repeat",
					backgroundPosition: "center",
					borderRadius: 3,
					padding: "10px",
					textAlign: "left",
					color: "#fff",
					height: "200px",
					display: "flex",
					alignItems: "center",
					mt: 8,
					mb: 2,
				}}
			>
				<CardContent>
					<Typography
						variant="h5"
						fontWeight="bold"
						align="left"
						sx={{ color: theme.palette.primary.main }}
					>
						Welcome to Contract Intelligence
					</Typography>
					<Typography
						variant="body1"
						sx={{ mt: 2, color: theme.palette.primary.main }}
					>
						Reading contracts made seamless
						<br />
					</Typography>
				</CardContent>
			</Card>

			{/* Statistics Section */}

			{/* Explore Section */}
			<Box
				sx={{ padding: 4, backgroundColor: "#f4f4f4", borderRadius: 2 }}
			>
				{/* Main Title */}
				<Typography
					variant="h5"
					gutterBottom
					align="center"
					color="primary"
					sx={{ pb: 2 }}
				>
					Let’s explore the application
				</Typography>

				{/* Two Cards Side by Side */}
				<Grid container justifyContent="center" spacing={3}>
					{/* Card 1 */}
					<Grid size={{ xs: 12, md: 5 }}>
						<Card
							variant="outlined"
							sx={{
								maxWidth: "600px",
								height: "223px",
								px: 5,
								borderRadius: 3,
								borderColor: theme.palette.primary.main,
								position: "relative",
								cursor: "pointer",
							}}
							onClick={() => navigate("/homepage/management")}
						>
							<CardContent>
								<Box
									display="flex"
									alignItems="center"
									justifyContent="center"
								>
									<Box
										sx={{
											color: theme.palette.primary.main,
											my: 1,
										}}
									>
										<StorageIcon />
									</Box>
								</Box>
								<Typography
									variant="h6"
									color="primary"
									gutterBottom
									align="center"
									sx={{ color: theme.palette.primary.main }}
								>
									Upload Your Contract Documents
								</Typography>
								<Typography
									variant="body2"
									align="center"
									sx={{
										color: theme.palette.primary.main,
										width: "90%",
									}}
								>
									Upload your contract documents using the
									Contract Management tab to get started. Your
									contract documents will be automatically
									processed and you will be notified once
									completed.
								</Typography>
								<Box
									sx={{
										position: "absolute",
										right: "20px",
										bottom: "100px",
										color: theme.palette.primary.main,
									}}
								>
									<Arrow />
								</Box>
							</CardContent>
						</Card>
					</Grid>

					{/* Card 2 */}
					<Grid size={{ xs: 12, md: 5 }}>
						<Card
							variant="outlined"
							sx={{
								maxWidth: "600px",
								height: "223px",
								borderRadius: 3,
								borderColor: theme.palette.primary.main,
								position: "relative",
								cursor: progVal < 100 ? "not-allowed" : "pointer",  // Change cursor based on the disabled state
								opacity: progVal < 100 ? 0.5 : 1,  // Optional: Add opacity to visually indicate disabled state
							}}
							onClick={() => progVal === 100 && navigate("/homepage/intelligence")}
						>
							<CardContent>
								<Box
									display="flex"
									alignItems="center"
									justifyContent="center"
								>
									<Box
										sx={{
											color: theme.palette.primary.main,
											my: 1,
										}}
									>
										<VectorIcon />
									</Box>
								</Box>
								<Typography
									variant="h6"
									color="primary"
									gutterBottom
									align="center"
									sx={{ color: theme.palette.primary.main }}
								>
									Explore Your Contract Insights
								</Typography>
								<Typography
									variant="body2"
									align="center"
									sx={{
										color: theme.palette.primary.main,
										width: "90%",
									}}
								>
									Use the Contract Insights tab to explore,
									ask questions, and perform ad-hoc analysis
									of your contracts easily with our GenAI
									Chatbot.
								</Typography>
								<Box
									sx={{
										position: "absolute",
										right: "20px",
										bottom: "100px",
										color: theme.palette.primary.main,
									}}
								>
									<Arrow />
								</Box>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</Box>
		</Grid>
	);
};

export default Homepage;
