import * as React from "react";
interface SvgComponentProps {
	[key: string]: any;
}
const DeleteIcon: React.FC<SvgComponentProps> = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={26}
		height={26}
		fill="none"
		{...props}
	>
		<mask
			id="a"
			width={16}
			height={21}
			x={5}
			y={3}
			maskUnits="userSpaceOnUse"
			style={{
				maskType: "luminance",
			}}
		>
			<path
				fill="#fff"
				fillRule="evenodd"
				d="M5.688 3.252h14.97V23.56H5.688V3.252Z"
				clipRule="evenodd"
			/>
		</mask>
		<g mask="url(#a)">
			<path
				fill="#CB4141"
				fillRule="evenodd"
				d="m6.826 7.925.332-1.102c.008-.026.029-.042.053-.042h11.924c.024 0 .046.016.053.042l.333 1.102H6.826Zm10.688 13.85c-.024.334-.279.595-.58.595H9.6c-.3 0-.556-.26-.58-.594l-.924-12.66h10.342l-.924 12.66ZM11.686 5.593h2.974v-1.15h-2.974v1.15Zm8.926 2.2-.405-1.345c-.154-.512-.585-.856-1.072-.856h-3.401V4.364c0-.613-.45-1.112-1.004-1.112h-3.115c-.553 0-1.003.499-1.003 1.112V5.59H7.21c-.487 0-.918.344-1.072.856l-.405 1.344a1.1 1.1 0 0 0 .124.906.888.888 0 0 0 .738.418h.423l.931 12.757c.07.947.794 1.688 1.65 1.688h7.334c.857 0 1.582-.741 1.651-1.688l.93-12.757h.235a.887.887 0 0 0 .737-.418 1.1 1.1 0 0 0 .125-.906Z"
				clipRule="evenodd"
			/>
		</g>
		<path
			fill="#CB4141"
			fillRule="evenodd"
			d="M15.801 21.734h.029c.283 0 .52-.246.535-.564l.505-10.725c.015-.328-.212-.608-.509-.625-.297-.017-.549.235-.564.563l-.504 10.725c-.015.328.211.609.508.626ZM10.004 21.172c.015.317.252.563.535.563l.03-.001c.296-.019.523-.298.507-.627l-.528-10.725c-.017-.329-.27-.58-.566-.562-.296.018-.523.298-.507.626l.529 10.726ZM13.179 21.735c.297 0 .537-.267.537-.596V10.414c0-.329-.24-.595-.537-.595-.297 0-.537.266-.537.595V21.14c0 .33.24.596.537.596Z"
			clipRule="evenodd"
		/>
	</svg>
);
export default DeleteIcon;
