import {
	useRef,
	useState,
	useEffect,
	useContext,
	useLayoutEffect,
	SyntheticEvent,
} from "react";
import {
	CommandBarButton,
	IconButton,
	Dialog,
	DialogType,
	Stack,
} from "@fluentui/react";
import {
	SquareRegular,
	ShieldLockRegular,
	ErrorCircleRegular,
} from "@fluentui/react-icons";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import uuid from "react-uuid";
import { isEmpty } from "lodash";
import DOMPurify from "dompurify";

import styles from "./Chat.module.css";
import Kearney from "../../assets/images/Kearney.svg";
import { XSSAllowTags } from "../../constants/xssAllowTags";
import { chatHistorySampleData } from "../../constants/chatHistory";

import {
	ChatMessage,
	ConversationRequest,
	conversationApi,
	Citation,
	ToolMessageContent,
	ChatResponse,
	getUserInfo,
	Conversation,
	historyGenerate,
	historyUpdate,
	historyClear,
	ChatHistoryLoadingState,
	CosmosDBStatus,
	ErrorMessage,
	historyRead,
	historyList,
	getCitationData,
	History,
	historyDelete,
	listReadyFiles,
} from "../../api";
import { Answer } from "../../components/Answer";
import { AppStateContext } from "../../state/AppProvider";
import { useBoolean } from "@fluentui/react-hooks";
import Grid from "@mui/material/Grid2";
import {
	Avatar,
	Box,
	Button,
	Divider,
	FormControl,
	InputAdornment,
	InputLabel,
	List,
	ListItem,
	ListItemButton,
	MenuItem,
	NativeSelect,
	Select,
	TextField,
	Typography,
	SelectChangeEvent,
} from "@mui/material";
import HistoryIcon from "../../assets/images/HistoryIcon";
import { useTheme } from "@mui/material/styles";
import FrameIcon from "../../assets/images/FrameIcon";
import SendIcon from "../../assets/images/SendIcon";
import CancelIcon from "../../assets/images/close.svg";
import { historyPanelMockLists } from "../../constants/historyPanelMockList";
import { useDispatch } from "react-redux";
import VectorIcon from "../../assets/images/VectorIcon";
import { hideLoader, showLoader } from "../../store/loaderSlice";
import { useLocation } from "react-router-dom";
import DeleteIcon from "../../assets/images/Delete.svg";
import { toast } from "react-toastify";
const enum messageStatus {
	NotRunning = "Not Running",
	Processing = "Processing",
	Done = "Done",
}

const Chat = () => {
	const theme = useTheme();
	const inputRef = useRef<HTMLInputElement>(null);
	const location = useLocation();
	const dispatch = useDispatch();
	const appStateContext = useContext(AppStateContext);
	const ui = appStateContext?.state.frontendSettings?.ui;
	const AUTH_ENABLED = appStateContext?.state.frontendSettings?.auth_enabled;
	const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [showLoadingMessage, setShowLoadingMessage] =
		useState<boolean>(false);
	const [activeCitation, setActiveCitation] = useState<Citation>();
	const [isCitationPanelOpen, setIsCitationPanelOpen] =
		useState<boolean>(false);
	const abortFuncs = useRef([] as AbortController[]);
	const [showAuthMessage, setShowAuthMessage] = useState<
		boolean | undefined
	>();
	const [convID, setConvId] = useState<string | null>("");
	const [historyListFilter, setHistoryListFilter] = useState<History[]>([]);
	const [messages, setMessages] = useState<ChatMessage[]>([]);
	const [textMsg, setTextMsg] = useState<string>("");
	const [processMessages, setProcessMessages] = useState<messageStatus>(
		messageStatus.NotRunning
	);
	const [clearingChat, setClearingChat] = useState<boolean>(false);
	const [hideErrorDialog, { toggle: toggleErrorDialog }] = useBoolean(true);
	const [errorMsg, setErrorMsg] = useState<ErrorMessage | null>();
	const [isHistoryPanelOpen, setIsHistoryPanelOpen] =
		useState<boolean>(false);
	const [selectedItem, setSelectedItem] = useState<string | null>("");
	const [fileDropdownData, setFileDropdownData] = useState<[]>([]);
	const [selectedDropdown, setSelectedDropdown] = useState("");

	const errorDialogContentProps = {
		type: DialogType.close,
		title: errorMsg?.title,
		closeButtonAriaLabel: "Close",
		subText: errorMsg?.subtitle,
	};

	const modalProps = {
		titleAriaId: "labelId",
		subtitleAriaId: "subTextId",
		isBlocking: true,
		styles: { main: { maxWidth: 450 } },
	};

	const [ASSISTANT, TOOL, ERROR] = ["assistant", "tool", "error"];
	const NO_CONTENT_ERROR = "No content in messages object.";

	useEffect(() => {
		if (
			appStateContext?.state.isCosmosDBAvailable?.status !==
				CosmosDBStatus.Working &&
			appStateContext?.state.isCosmosDBAvailable?.status !==
				CosmosDBStatus.NotConfigured &&
			appStateContext?.state.chatHistoryLoadingState ===
				ChatHistoryLoadingState.Fail &&
			hideErrorDialog
		) {
			let subtitle = `${appStateContext.state.isCosmosDBAvailable.status}. Please contact the site administrator.`;
			setErrorMsg({
				title: "Chat history is not enabled",
				subtitle: subtitle,
			});
			toggleErrorDialog();
		}
	}, [appStateContext?.state.isCosmosDBAvailable]);

	const handleErrorDialogClose = () => {
		toggleErrorDialog();
		setTimeout(() => {
			setErrorMsg(null);
		}, 500);
	};

	// useEffect(() => {
	// 	setIsLoading(
	// 		appStateContext?.state.chatHistoryLoadingState ===
	// 			ChatHistoryLoadingState.Loading,
	// 	);
	// }, [appStateContext?.state.chatHistoryLoadingState]);

	// const getUserInfoList = async () => {
	// 	if (!AUTH_ENABLED) {
	// 		setShowAuthMessage(false);
	// 		return;
	// 	}
	// 	const userInfoList = await getUserInfo();
	// 	if (
	// 		userInfoList.length === 0 &&
	// 		window.location.hostname !== "127.0.0.1"
	// 	) {
	// 		setShowAuthMessage(true);
	// 	} else {
	// 		setShowAuthMessage(false);
	// 	}
	// };

	let assistantMessage = {} as ChatMessage;
	let toolMessage = {} as ChatMessage;
	let assistantContent = "";

	const processResultMessage = (
		resultMessage: ChatMessage,
		userMessage: ChatMessage,
		conversationId?: string
	) => {
		if (resultMessage.role === ASSISTANT) {
			assistantContent += resultMessage.content;
			assistantMessage = resultMessage;
			assistantMessage.content = assistantContent;

			if (resultMessage.context) {
				toolMessage = {
					id: uuid(),
					role: TOOL,
					content: resultMessage.context,
					date: new Date().toISOString(),
				};
			}
		}

		if (resultMessage.role === TOOL) toolMessage = resultMessage;

		if (!conversationId) {
			isEmpty(toolMessage)
				? setMessages([...messages, userMessage, assistantMessage])
				: setMessages([
						...messages,
						userMessage,
						toolMessage,
						assistantMessage,
				  ]);
		} else {
			isEmpty(toolMessage)
				? setMessages([...messages, assistantMessage])
				: setMessages([...messages, toolMessage, assistantMessage]);
		}
	};

	const makeApiRequestWithCosmosDB = async (
		question: string,
		conversationId?: string
	) => {
		setIsLoading(true);
		setShowLoadingMessage(true);
		const abortController = new AbortController();
		abortFuncs.current.unshift(abortController);
		const userMessage: ChatMessage = {
			id: uuid(),
			role: "user",
			content: question,
			date: new Date().toISOString(),
		};

		//api call params set here (generate)
		let request: ConversationRequest;
		let conversation;
		if (conversationId) {
			conversation = appStateContext?.state?.currentChat;
			if (!conversation) {
				console.error("Conversation not found.");
				setIsLoading(false);
				setShowLoadingMessage(false);
				abortFuncs.current = abortFuncs.current.filter(
					(a) => a !== abortController
				);
				return;
			} else {
				conversation.messages.push(userMessage);
				request = {
					messages: [
						...conversation.messages.filter(
							(answer) => answer.role !== ERROR
						),
					],
					contract_workspace:
						appStateContext?.state.selectedFile != null
							? appStateContext?.state.selectedFile
									.contract_workspace
							: "",
				};
			}
		} else {
			request = {
				messages: [userMessage].filter(
					(answer) => answer.role !== ERROR
				),
				contract_workspace:
					appStateContext?.state.selectedFile != null
						? appStateContext?.state.selectedFile.contract_workspace
						: "",
			};
			setMessages(request.messages);
		}
		let result = {} as ChatResponse;
		var errorResponseMessage =
			"Please try again. If the problem persists, please contact the site administrator.";
		try {
			const response = conversationId
				? await historyGenerate(
						request,
						abortController.signal,
						selectedDropdown,
						conversationId
				  )
				: await historyGenerate(
						request,
						abortController.signal,
						selectedDropdown
				  );
			// once chat is generated we need to call history api
			await fetchChatHistoryList();
			if (!response?.ok) {
				const responseJson = await response.json();
				errorResponseMessage =
					responseJson.error === undefined
						? errorResponseMessage
						: parseErrorMessage(responseJson.error);
				let errorChatMsg: ChatMessage = {
					id: uuid(),
					role: ERROR,
					content: `There was an error generating a response. Chat history can't be saved at this time. ${errorResponseMessage}`,
					date: new Date().toISOString(),
				};
				let resultConversation;
				if (conversationId) {
					resultConversation =
						appStateContext?.state?.chatHistory?.find(
							(conv) => conv.id === conversationId
						);
					if (!resultConversation) {
						console.error("Conversation not found.");
						setIsLoading(false);
						setShowLoadingMessage(false);
						abortFuncs.current = abortFuncs.current.filter(
							(a) => a !== abortController
						);
						return;
					}
					resultConversation.messages.push(errorChatMsg);
				} else {
					setMessages([...messages, userMessage, errorChatMsg]);
					setIsLoading(false);
					setShowLoadingMessage(false);
					abortFuncs.current = abortFuncs.current.filter(
						(a) => a !== abortController
					);
					return;
				}
				appStateContext?.dispatch({
					type: "UPDATE_CURRENT_CHAT",
					payload: resultConversation,
				});
				setMessages([...resultConversation.messages]);
				return;
			}
			if (response?.body) {
				const reader = response.body.getReader();

				let runningText = "";
				while (true) {
					setProcessMessages(messageStatus.Processing);
					const { done, value } = await reader.read();
					if (done) break;

					var text = new TextDecoder("utf-8").decode(value);
					const objects = text.split("\n");
					objects.forEach((obj) => {
						try {
							if (obj !== "" && obj !== "{}") {
								runningText += obj;
								result = JSON.parse(runningText);
								if (
									!result.choices?.[0]?.messages?.[0].content
								) {
									errorResponseMessage = NO_CONTENT_ERROR;
									throw Error();
								}
								if (result.choices?.length > 0) {
									result.choices[0].messages.forEach(
										(msg) => {
											msg.id = result.id;
											msg.date = new Date().toISOString();
										}
									);
									if (
										result.choices[0].messages?.some(
											(m) => m.role === ASSISTANT
										)
									) {
										setShowLoadingMessage(false);
									}
									result.choices[0].messages.forEach(
										(resultObj) => {
											processResultMessage(
												resultObj,
												userMessage,
												conversationId
											);
										}
									);
								}
								runningText = "";
							} else if (result.error) {
								throw Error(result.error);
							}
						} catch (e) {
							if (!(e instanceof SyntaxError)) {
								console.error(e);
								throw e;
							} else {
							}
						}
					});
				}

				let resultConversation;
				if (conversationId) {
					resultConversation = appStateContext?.state?.currentChat;
					if (!resultConversation) {
						console.error("Conversation not found.");
						setIsLoading(false);
						setShowLoadingMessage(false);
						abortFuncs.current = abortFuncs.current.filter(
							(a) => a !== abortController
						);
						return;
					}
					isEmpty(toolMessage)
						? resultConversation.messages.push(assistantMessage)
						: resultConversation.messages.push(
								toolMessage,
								assistantMessage
						  );
				} else {
					resultConversation = {
						id: result.history_metadata.conversation_id,
						title: result.history_metadata.title,
						messages: [userMessage],
						date: result.history_metadata.date,
					};
					isEmpty(toolMessage)
						? resultConversation.messages.push(assistantMessage)
						: resultConversation.messages.push(
								toolMessage,
								assistantMessage
						  );
				}
				if (!resultConversation) {
					setIsLoading(false);
					setShowLoadingMessage(false);
					abortFuncs.current = abortFuncs.current.filter(
						(a) => a !== abortController
					);
					return;
				}
				appStateContext?.dispatch({
					type: "UPDATE_CURRENT_CHAT",
					payload: resultConversation,
				});
				isEmpty(toolMessage)
					? setMessages([...messages, assistantMessage])
					: setMessages([...messages, toolMessage, assistantMessage]);
			}
		} catch (e) {
			if (!abortController.signal.aborted) {
				let errorMessage = `An error occurred. ${errorResponseMessage}`;
				if (result.error?.message) {
					errorMessage = result.error.message;
				} else if (typeof result.error === "string") {
					errorMessage = result.error;
				}

				errorMessage = parseErrorMessage(errorMessage);

				let errorChatMsg: ChatMessage = {
					id: uuid(),
					role: ERROR,
					content: errorMessage,
					date: new Date().toISOString(),
				};
				let resultConversation;
				if (conversationId) {
					resultConversation =
						appStateContext?.state?.chatHistory?.find(
							(conv) => conv.id === conversationId
						);
					if (!resultConversation) {
						console.error("Conversation not found.");
						setIsLoading(false);
						setShowLoadingMessage(false);
						abortFuncs.current = abortFuncs.current.filter(
							(a) => a !== abortController
						);
						return;
					}
					resultConversation.messages.push(errorChatMsg);
				} else {
					if (!result.history_metadata) {
						console.error("Error retrieving data.", result);
						let errorChatMsg: ChatMessage = {
							id: uuid(),
							role: ERROR,
							content: errorMessage,
							date: new Date().toISOString(),
						};
						setMessages([...messages, userMessage, errorChatMsg]);
						setIsLoading(false);
						setShowLoadingMessage(false);
						abortFuncs.current = abortFuncs.current.filter(
							(a) => a !== abortController
						);
						return;
					}
					resultConversation = {
						id: result.history_metadata.conversation_id,
						title: result.history_metadata.title,
						messages: [userMessage],
						date: result.history_metadata.date,
					};
					resultConversation.messages.push(errorChatMsg);
				}
				if (!resultConversation) {
					setIsLoading(false);
					setShowLoadingMessage(false);
					abortFuncs.current = abortFuncs.current.filter(
						(a) => a !== abortController
					);
					return;
				}
				appStateContext?.dispatch({
					type: "UPDATE_CURRENT_CHAT",
					payload: resultConversation,
				});
				setMessages([...messages, errorChatMsg]);
			} else {
				setMessages([...messages, userMessage]);
			}
		} finally {
			setIsLoading(false);
			setShowLoadingMessage(false);
			abortFuncs.current = abortFuncs.current.filter(
				(a) => a !== abortController
			);
			setProcessMessages(messageStatus.Done);
		}
		return abortController.abort();
	};

	// const clearChat = async () => {
	// 	setClearingChat(true);
	// 	if (
	// 		appStateContext?.state.currentChat?.id &&
	// 		appStateContext?.state.isCosmosDBAvailable.cosmosDB
	// 	) {
	// 		let response = await historyClear(
	// 			appStateContext?.state.currentChat.id,
	// 		);
	// 		if (!response.ok) {
	// 			setErrorMsg({
	// 				title: "Error clearing current chat",
	// 				subtitle:
	// 					"Please try again. If the problem persists, please contact the site administrator.",
	// 			});
	// 			toggleErrorDialog();
	// 		} else {
	// 			appStateContext?.dispatch({
	// 				type: "DELETE_CURRENT_CHAT_MESSAGES",
	// 				payload: appStateContext?.state.currentChat.id,
	// 			});
	// 			appStateContext?.dispatch({
	// 				type: "UPDATE_CHAT_HISTORY",
	// 				payload: appStateContext?.state.currentChat,
	// 			});
	// 			setActiveCitation(undefined);
	// 			setIsCitationPanelOpen(false);
	// 			// setMessages([]);
	// 		}
	// 	}
	// 	setClearingChat(false);
	// };

	useEffect(() => {
		// When loading completes, refocus the TextField
		if (!isLoading && inputRef.current) {
			inputRef.current.focus();
		}
	}, [isLoading]);

	const parseErrorMessage = (errorMessage: string) => {
		let errorCodeMessage = errorMessage.substring(
			0,
			errorMessage.indexOf("-") + 1
		);
		const innerErrorCue = "{\\'error\\': {\\'message\\': ";
		if (errorMessage.includes(innerErrorCue)) {
			try {
				let innerErrorString = errorMessage.substring(
					errorMessage.indexOf(innerErrorCue)
				);
				if (innerErrorString.endsWith("'}}")) {
					innerErrorString = innerErrorString.substring(
						0,
						innerErrorString.length - 3
					);
				}
				innerErrorString = innerErrorString.replaceAll("\\'", "'");
				let newErrorMessage = errorCodeMessage + " " + innerErrorString;
				errorMessage = newErrorMessage;
			} catch (e) {
				console.error("Error parsing inner error message: ", e);
			}
		}
		return errorMessage;
	};

	const newChat = () => {
		setProcessMessages(messageStatus.Processing);
		setMessages([]);
		setIsCitationPanelOpen(false);
		setActiveCitation(undefined);
		appStateContext?.dispatch({
			type: "UPDATE_CURRENT_CHAT",
			payload: null,
		});
		setProcessMessages(messageStatus.Done);
	};

	const stopGenerating = () => {
		abortFuncs.current.forEach((a) => a.abort());
		setShowLoadingMessage(false);
		setIsLoading(false);
	};

	// useEffect(() => {
	// 	if (appStateContext?.state.currentChat) {
	// 		setMessages(appStateContext.state.currentChat.messages);
	// 	} else {
	// 		setMessages([]);
	// 	}
	// }, [appStateContext?.state.currentChat]);

	useEffect(() => {
		const saveToDB = async (messages: ChatMessage[], id: string) => {
			const response = await historyUpdate(messages, id);
			return response;
		};

		if (
			appStateContext &&
			appStateContext.state.currentChat &&
			processMessages === messageStatus.Done
		) {
			// if (appStateContext.state.isCosmosDBAvailable.cosmosDB) {
			if (!appStateContext?.state.currentChat?.messages) {
				console.error("Failure fetching current chat state.");
				return;
			}
			const noContentError =
				appStateContext.state.currentChat.messages.find(
					(m) => m.role === ERROR
				);

			if (!noContentError?.content.includes(NO_CONTENT_ERROR)) {
				saveToDB(
					appStateContext.state.currentChat.messages,
					appStateContext.state.currentChat.id
				)
					.then((res) => {
						if (!res.ok) {
							let errorMessage =
								"An error occurred. Answers can't be saved at this time. If the problem persists, please contact the site administrator.";
							let errorChatMsg: ChatMessage = {
								id: uuid(),
								role: ERROR,
								content: errorMessage,
								date: new Date().toISOString(),
							};
							if (!appStateContext?.state.currentChat?.messages) {
								let err: Error = {
									...new Error(),
									message:
										"Failure fetching current chat state.",
								};
								throw err;
							}
							setMessages([
								...appStateContext?.state.currentChat?.messages,
								errorChatMsg,
							]);
						}
						return res as Response;
					})
					.catch((err) => {
						console.error("Error: ", err);
						let errRes: Response = {
							...new Response(),
							ok: false,
							status: 500,
						};
						return errRes;
					});
			}
			// } else {
			// }
			appStateContext?.dispatch({
				type: "UPDATE_CHAT_HISTORY",
				payload: appStateContext.state.currentChat,
			});
			setMessages(appStateContext.state.currentChat.messages);
			setProcessMessages(messageStatus.NotRunning);
		}
	}, [processMessages]);

	// useEffect(() => {
	// 	if (AUTH_ENABLED !== undefined) getUserInfoList();
	// }, [AUTH_ENABLED]);

	useLayoutEffect(() => {
		chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" });
	}, [showLoadingMessage, processMessages]);

	const onShowCitation = async (citation: Citation) => {
		dispatch(showLoader());
		const result = await getCitationData(citation.file_id, citation.page);
		// backend needs to do this
		let newUrl;
		if (result?.citation_url?.length > 0) {
			newUrl = result?.citation_url[0];
		}
		const updatedCitation = { ...citation, ...result, newUrl };
		setActiveCitation(updatedCitation);
		setIsCitationPanelOpen(true);
		setIsHistoryPanelOpen(false);
		dispatch(hideLoader());
	};

	const onViewSource = (citation: Citation) => {
		if (citation.url && !citation.url.includes("blob.core")) {
			window.open(citation.url, "_blank");
		}
	};

	const parseCitationFromMessage = (message: ChatMessage) => {
		if (message?.role && message?.role === "tool") {
			try {
				const toolMessage = JSON.parse(
					message.content
				) as ToolMessageContent;
				return toolMessage.citations;
			} catch {
				return [];
			}
		}
		return [];
	};

	const disabledButton = () => {
		return (
			isLoading ||
			(messages && messages.length === 0) ||
			clearingChat ||
			appStateContext?.state.chatHistoryLoadingState ===
				ChatHistoryLoadingState.Loading
		);
	};

	const handleSend = (question: string) => {
		if (question.length) {
			const conversationID = appStateContext?.state.currentChat?.id
				? appStateContext?.state.currentChat?.id
				: convID
				? convID
				: undefined;
			makeApiRequestWithCosmosDB(question, conversationID);
			setTextMsg("");
		}
	};
	const handleShowHistory = () => {
		setIsHistoryPanelOpen(!isHistoryPanelOpen);
		setIsCitationPanelOpen(false);
	};

	useEffect(() => {
		fetchChatHistoryList();
		fetchAllFiles();
	}, []);
	const fetchChatHistoryList = async (
		offset = 0
	): Promise<History[] | null> => {
		const result = await historyList(offset)
			.then((response) => {
				if (response) {
					setHistoryListFilter(response);
				} else {
					setHistoryListFilter([]);
				}
				return response;
			})
			.catch((err) => {
				toast.error("Error during API request:", err);
				return null;
			});
		// dispatch(hideLoader());
		return result;
	};
	useEffect(() => {
		return () => {
			setConvId("");
			appStateContext?.dispatch({
				type: "UPDATE_CURRENT_CHAT",
				payload: null,
			});
		};
	}, [location.pathname]);
	useEffect(() => {
		if (convID) {
			dispatch(showLoader());
			const convMessages = historyRead(convID)
				.then((res) => {
					appStateContext?.dispatch({
						type: "UPDATE_CHAT_HISTORY",
						payload: res,
					});
					appStateContext?.dispatch({
						type: "UPDATE_CURRENT_CHAT",
						payload: { id: convID, messages: res },
					});
					setMessages(res);
					return res;
				})
				.catch((err) => {
					setMessages([]);
					return [];
				})
				.finally(() => {
					dispatch(hideLoader());
				});
		}
		return () => {
			setConvId("");
			appStateContext?.dispatch({
				type: "UPDATE_CURRENT_CHAT",
				payload: null,
			});
		};
	}, [convID]);
	const fetchHistoryData = (event: React.MouseEvent) => {
		const target = event.currentTarget as HTMLElement;
		const data = target.getAttribute("data-value");
		setSelectedItem(data);
		setConvId(data);
	};
	const fetchAllFiles = async (): Promise<any> => {
		dispatch(showLoader());
		const result = await listReadyFiles()
			.then((response) => {
				if (response) {
					const data = response.map((ele: any) => ({
						id: ele.file_id,
						value: ele.file_name,
					}));
					setFileDropdownData(data);
				}
				return response;
			})
			.catch((err) => {
				toast.error("Error during API request:", err);
				dispatch(showLoader());
				return null;
			});
		dispatch(hideLoader());
		return result;
	};

	const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		const target = e.target as HTMLInputElement;
		setTextMsg(target.value);
	};
	const fireInitialQuestion = (question: string) => {
		setTextMsg(question);
	};
	const deleteHistory = async (historyId: string) => {
		dispatch(showLoader());
		if (
			(appStateContext?.state.currentChat?.id === historyId ||
				convID === historyId) &&
			appStateContext
		) {
			appStateContext?.dispatch({
				type: "UPDATE_CURRENT_CHAT",
				payload: null,
			});
			setMessages([]);
			setConvId("");
		}
		await historyDelete(historyId);
		// after delete , fetch chat history list
		await fetchChatHistoryList();
		dispatch(hideLoader());
	};

	const handleChangeDropdown = (event: SelectChangeEvent) => {
		setSelectedDropdown(event.target.value);
	};

	return (
		<Grid container>
			<Grid
				sx={{
					flex: 1,
					mt: 8,
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					background: "#fff",
					boxShadow:
						"0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12)",
					borderRadius: "8px",
				}}
			>
				<Grid
					container
					sx={{ width: "100%" }}
					justifyContent="space-between"
				>
					<Typography
						align="center"
						sx={{ fontSize: "1.15rem", px: 2, mt: 3.5 }}
					>
						Contract Insights - Chatbot
					</Typography>

					<Grid sx={{ px: 2, mt: 0.5, display: "flex" }}>
						<Box sx={{ minWidth: 150 }}>
							<FormControl
								variant="filled"
								sx={{ m: 1, minWidth: 180 }}
							>
								<InputLabel id="demo-simple-select-standard-label">
									All Documents
								</InputLabel>
								<Select
									labelId="demo-simple-select-standard-label"
									id="demo-simple-select-standard"
									value={selectedDropdown}
									onChange={handleChangeDropdown}
								>
									<MenuItem value="">
										<em>All Documents</em>
									</MenuItem>
									{fileDropdownData.map((ele: any) => (
										<MenuItem key={ele.id} value={ele.id}>
											{ele.value}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Box>
						<Button
							sx={{ color: theme.palette.primary.main }}
							startIcon={<HistoryIcon />}
							onClick={handleShowHistory}
						>
							History
						</Button>
					</Grid>
				</Grid>
				<Divider sx={{ width: "100%" }} />

				<Grid
					justifyContent="center"
					alignItems="center"
					sx={{
						width: "100%",
						display: "flex",
						flexDirection: "row",
					}}
				>
					<Grid
						sx={{
							width: isCitationPanelOpen ? "45%" : "100%", // Adjust width when citation panel is open
							transition: "width 0.3s ease",
							px: isCitationPanelOpen ? 0 : 20,
							height: "85vh",
							overflowY: "auto",
						}}
					>
						{!messages || messages.length < 1 ? (
							<Grid
								container
								direction="column"
								alignItems="center"
								className={styles.chatEmptyState}
							>
								<Box
									component="div"
									sx={{
										color: theme.palette.primary.main,
										pt: 3,
									}}
								>
									<FrameIcon />
								</Box>

								{/* Example boxes */}
								<Box
									component="div"
									sx={{
										display: "flex",
										gap: 2,
										mt: 2,
									}}
								>
									<Box
										sx={{
											padding: "26px",
											width: "450px",
											textAlign: "center",
											backgroundColor:
												theme.palette.grey[200],
											borderRadius: 2,
											fontSize: "0.875rem",
											cursor: "pointer",
										}}
										onClick={() =>
											fireInitialQuestion(
												"Who is the supplier of this contract?"
											)
										}
									>
										"Who is the supplier of this contract?"
									</Box>
									<Box
										sx={{
											padding: "26px",
											width: "450px",
											textAlign: "center",
											backgroundColor:
												theme.palette.grey[200],
											borderRadius: 2,
											fontSize: "0.875rem",
											cursor: "pointer",
										}}
										onClick={() =>
											fireInitialQuestion(
												"What is the start date and end date of this contract?"
											)
										}
									>
										"What is the start date and end date of
										this contract?"
									</Box>
								</Box>
							</Grid>
						) : (
							<Box
								className={styles.chatMessageStream}
								role="log"
							>
								{messages.map((answer, index) => (
									<Box key={answer.id}>
										{answer.role === "user" ? (
											<Box
												className={
													styles.chatMessageUser
												}
												tabIndex={0}
												sx={{ mb: 3 }}
											>
												<Box
													className={
														styles.chatMessageUserMessage
													}
												>
													{answer.content}
												</Box>
												<Box
													sx={{
														display: "flex",
														alignItems: "center",
														ml: 4,
													}}
												>
													<Avatar
														alt="Shubham Mishra"
														sx={{
															fontSize: "14px",
															background:
																theme.palette
																	.background
																	.default,
															border: `2px solid ${theme.palette.primary.main}`,
															color: theme.palette
																.text.primary,
														}}
													></Avatar>
												</Box>
											</Box>
										) : answer.role === "assistant" ? (
											<Box
												sx={{
													display: "flex",
													alignItems: "center",
													mb: 2,
													position: "relative",
												}}
											>
												{/* VectorIcon outside the answer container */}
												<Box
													sx={{
														color: theme.palette
															.primary.main,
														display: "flex",
														alignItems: "center",
														mr: 3,
													}}
												>
													<VectorIcon />
												</Box>

												{/* Answer Container with border */}
												<Box
													sx={{
														border: `1px solid ${theme.palette.secondary.main}`,
														borderRadius: 3,
														flexGrow: 1,
														position: "relative",
													}}
												>
													<Answer
														answer={{
															answer: answer.content,
															citations:
																parseCitationFromMessage(
																	messages[
																		index -
																			1
																	]
																),
															message_id:
																answer.id,
															feedback:
																answer.feedback,
														}}
														onCitationClicked={(
															c
														) => onShowCitation(c)}
													/>
												</Box>
												{/* AI-generated text message positioned at the bottom-right */}
												<Box
													sx={{
														position: "absolute",
														bottom: "-21px",
														right: 8,
														padding: "4px 8px",
														borderRadius: "4px",
														fontSize: "0.75rem",

														// color: "#4B4B4B",
													}}
												>
													This content has been
													generated by AI
												</Box>
											</Box>
										) : answer.role === "error" ? (
											<Box
												className={
													styles.chatMessageError
												}
												sx={{ ml: "52px" }}
											>
												<Grid
													container
													alignItems="center"
													className={
														styles.chatMessageErrorContent
													}
												>
													{/* <StopIcon sx={{ color: 'rgba(182, 52, 67, 1)' }} /> */}
													<span>Error</span>
												</Grid>
												<span
													className={
														styles.chatMessageErrorContent
													}
												>
													{answer.content}
												</span>
											</Box>
										) : null}
									</Box>
								))}
								{showLoadingMessage && (
									<Box className={styles.chatMessageGpt}>
										<Answer
											answer={{
												answer: "Generating answer...",
												citations: [],
											}}
											onCitationClicked={() => null}
										/>
									</Box>
								)}
								<div ref={chatMessageStreamEnd} />
							</Box>
						)}
						<Grid
							container
							alignItems="center"
							className={styles.chatInput}
							sx={{
								position: "sticky",
								bottom: 0,
								backgroundColor:
									theme.palette.background.default,
								zIndex: 10,
							}}
						>
							{/* {isLoading && (
								<Grid
									container
									alignItems="center"
									className={styles.stopGeneratingContainer}
									role="button"
									tabIndex={0}
									onClick={stopGenerating}
								>
									<span className={styles.stopGeneratingText}>
										Stop generating
									</span>
								</Grid>
							)} */}

							<Dialog
								hidden={hideErrorDialog}
								onDismiss={handleErrorDialogClose}
								aria-labelledby="error-dialog-title"
								aria-describedby="error-dialog-description"
							>
								{/* Error Dialog Content */}
							</Dialog>
							{/* <QuestionInput
						clearOnSend
						placeholder="Type a new question..."
						disabled={isLoading}
						onSend={(question, id) => {
							console.log("question, id", question, id);
							appStateContext?.state.isCosmosDBAvailable?.cosmosDB
								? makeApiRequestWithCosmosDB(question, id)
								: makeApiRequestWithoutCosmosDB(question, id);
						}}
						conversationId={appStateContext?.state.currentChat?.id}
					/> */}
							<Box
								component="div"
								sx={{
									width: "100%",
									pb: 10,
									pt: 4,
								}}
							>
								<TextField
									inputRef={inputRef}
									variant="outlined"
									fullWidth
									placeholder="Type a new question..."
									disabled={isLoading}
									value={textMsg}
									slotProps={{
										input: {
											endAdornment: (
												<InputAdornment
													sx={{
														color: theme.palette
															.primary.main,
														cursor: "pointer",
													}}
													onClick={() =>
														handleSend(textMsg)
													}
													position="end"
												>
													<SendIcon />
												</InputAdornment>
											),
										},
									}}
									onInput={handleInput}
									onKeyDown={(e) => {
										if (e.key === "Enter" && !isLoading) {
											handleSend(textMsg);
										}
									}}
								/>
							</Box>
						</Grid>
					</Grid>
					{/* Citation Panel */}
					{messages &&
						messages.length > 0 &&
						isCitationPanelOpen &&
						activeCitation && (
							<Grid
								sx={{
									width: "55%",
									transition: "width 0.3s ease",
									borderLeft: `1px solid ${theme.palette.divider}`,
									boxShadow:
										"0px 2px 10px rgba(0, 0, 0, 0.1)",
									padding: "20px",
									backgroundColor: "#D2D2D2",
									borderRadius: 5,
								}}
								className={styles.citationPanel}
								tabIndex={0}
								role="tabpanel"
								aria-label="Citations Panel"
							>
								{/* Header section with title and close button */}
								<Grid
									container
									justifyContent="space-between"
									alignItems="center"
									className={
										styles.citationPanelHeaderContainer
									}
									sx={{
										borderBottom: `1px solid ${theme.palette.divider}`,
										paddingBottom: "10px",
										marginBottom: "20px",
									}}
								>
									<Typography
										variant="h6"
										className={styles.citationPanelHeader}
										sx={{
											fontWeight: "bold",
											color: "#333",
										}} // Styling for header
									>
										Citations
									</Typography>
									<IconButton
										aria-label="Close citations panel"
										onClick={() =>
											setIsCitationPanelOpen(false)
										}
									>
										<img src={CancelIcon} alt="Close" />
									</IconButton>
								</Grid>
								{/* Title of the citation */}
								<Typography
									className={styles.citationPanelTitle}
									tabIndex={0}
									title={
										activeCitation.url &&
										!activeCitation.url.includes(
											"blob.core"
										)
											? activeCitation.url
											: activeCitation.title ?? ""
									}
									sx={{
										fontWeight: "600",
										marginBottom: "10px",
										cursor: "pointer",
										color: theme.palette.primary.main,
										":hover": {
											textDecoration: "underline",
										},
									}}
									onClick={() => onViewSource(activeCitation)}
								>
									{activeCitation.title}
								</Typography>
								{/* Content of the citation */}
								{activeCitation.citation_url &&
								activeCitation.citation_url?.length > 0 ? (
									<iframe
										style={{
											width: "100%",
											height: "100%",
											border: "none",
										}}
										src={activeCitation.newUrl}
										width="100%"
										height="500px"
									></iframe>
								) : (
									<Box
										tabIndex={0}
										sx={{
											fontSize: "0.9rem",
											lineHeight: "1.5",
											color: "#555",
											backgroundColor: "#ffff",
											p: 5,
											borderRadius: 5,
										}}
									>
										<ReactMarkdown
											linkTarget="_blank"
											className={
												styles.citationPanelContent
											}
											children={DOMPurify.sanitize(
												activeCitation.content,
												{
													ALLOWED_TAGS: XSSAllowTags,
												}
											)}
										/>
									</Box>
								)}
								{/* Footer section like in the image, with contract details */}
								<Box
									sx={{
										marginTop: "30px",
										paddingTop: "15px",
										borderTop: `1px solid ${theme.palette.divider}`,
									}}
								>
									{activeCitation?.addidtional_data?.length >
										0 && (
										<Typography
											variant="body2"
											sx={{
												fontWeight: "bold",
												color: "#333",
											}}
										>
											On this page...
										</Typography>
									)}
									{activeCitation?.addidtional_data?.map(
										(ele, i) => (
											<Typography
												variant="body2"
												sx={{
													marginTop: "8px",
													color: "#555",
												}}
												key={i + 1}
											>
												<strong>
													{ele.parameter}:
												</strong>
												{ele.value}
											</Typography>
										)
									)}
								</Box>
							</Grid>
						)}

					{/* History Panel */}
					{isHistoryPanelOpen && (
						<Grid
							sx={{
								width: "30%",
								transition: "width 0.3s ease",
								borderLeft: `1px solid ${theme.palette.divider}`,
								boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
								padding: "20px",
								backgroundColor: "#D2D2D2",
								borderRadius: 5,
								overflowY: "hidden",
								mb: 3,
								mr: 3,
							}}
							className={styles.citationPanel}
							tabIndex={0}
							role="tabpanel"
							aria-label="Citations Panel"
						>
							{/* Header section with title and close button */}
							<Grid
								container
								justifyContent="space-between"
								alignItems="center"
								className={styles.citationPanelHeaderContainer}
								sx={{
									borderBottom: `1px solid ${theme.palette.divider}`,
									paddingBottom: "10px",
									marginBottom: "20px",
								}}
							>
								<Typography
									variant="h6"
									className={styles.citationPanelHeader}
									sx={{
										color: "#333",
									}}
								>
									History
								</Typography>
								<IconButton
									aria-label="Close citations panel"
									onClick={() => setIsHistoryPanelOpen(false)}
								>
									<img src={CancelIcon} alt="Close" />
								</IconButton>
							</Grid>

							{/* Content of the History Panel */}
							<Grid
								container
								direction="column"
								style={{ width: "-webkit-fill-available" }}
								sx={{
									height: "100%", // Full height of the container
								}}
							>
								<List
									sx={{
										flexGrow: 1,
										minHeight: "300px",
										maxHeight: "100%",
										overflowY:
											historyPanelMockLists.length > 5
												? "auto"
												: "hidden",
									}}
								>
									{historyListFilter?.map((data, index) => (
										<ListItem key={data.id} disablePadding>
											<ListItemButton
												data-value={data.id}
												onClick={fetchHistoryData}
												sx={{
													pt: 2,
													justifyContent:
														"space-between",
													border:
														data.id == selectedItem
															? `1px solid ${theme.palette.primary.main}`
															: "",
													borderRadius: 2,
												}}
											>
												<Grid>
													<Typography>
														{data.title}
													</Typography>
												</Grid>
												<Grid>
													<IconButton
														aria-label="delete"
														onClick={(event) => {
															event.stopPropagation(); // Prevents the click from bubbling up
															deleteHistory(
																data.id
															);
														}}
													>
														<img
															src={DeleteIcon}
															alt="Delete"
														/>
													</IconButton>
												</Grid>
											</ListItemButton>
										</ListItem>
									))}
								</List>
							</Grid>
						</Grid>
					)}
				</Grid>
			</Grid>

			{/* {appStateContext?.state.isChatHistoryOpen &&
				appStateContext?.state.isCosmosDBAvailable?.status !==
					CosmosDBStatus.NotConfigured && <ChatHistoryPanel />} */}
		</Grid>
	);
};

export default Chat;
