import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  percentStatus: 0,
};

const progressSlice = createSlice({
  name: 'progress',
  initialState,
  reducers: {
    setProgressStatus(state, action: PayloadAction<{  percentStatus: number;   }>) {
      // state.status = action.payload.status;
      state.percentStatus = action.payload.percentStatus;
      // state.contractId = action.payload.contractId;
    },
  },
});

export const { setProgressStatus } = progressSlice.actions;
export const selectProgressStatus = (state:any) => state.progress;
export default progressSlice.reducer;
