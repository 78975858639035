import * as React from "react";
interface SvgComponentProps {
	[key: string]: any;
}
const ConfigureIcon: React.FC<SvgComponentProps> = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={25}
		height={25}
		fill="currentColor"
		viewBox="0 0 28 22"
		{...props}
	>
		<path d="m20.026 21.615-.243-1.924a3.406 3.406 0 0 1-1.008-.566l-1.802.753-1.473-2.526 1.558-1.165a3.163 3.163 0 0 1-.031-.283 4.333 4.333 0 0 1 .031-.86L15.5 13.877l1.474-2.514 1.795.747c.154-.12.318-.23.493-.33s.346-.181.515-.242l.25-1.924h2.947l.243 1.93c.191.069.365.15.52.241.155.092.312.2.47.325l1.82-.747 1.473 2.514-1.583 1.183c.023.102.035.198.038.286.002.09.003.178.003.268 0 .086-.002.173-.006.262-.005.089-.02.19-.045.304l1.571 1.171-1.474 2.526-1.797-.759c-.159.125-.32.235-.484.331a2.931 2.931 0 0 1-.506.235l-.243 1.93h-2.948Zm.835-.947h1.255l.23-1.692c.326-.084.624-.204.893-.359.27-.155.53-.355.781-.598l1.587.66.628-1.074-1.385-1.033a2.56 2.56 0 0 0 .109-.48 3.921 3.921 0 0 0 0-.953 2.445 2.445 0 0 0-.109-.469l1.397-1.044-.628-1.074-1.605.67a3.396 3.396 0 0 0-.768-.6 3.325 3.325 0 0 0-.906-.368l-.201-1.691H20.87l-.211 1.685a3.358 3.358 0 0 0-.902.35c-.276.157-.54.36-.79.607l-1.587-.653-.628 1.074 1.378 1.016c-.053.15-.09.306-.111.468a3.92 3.92 0 0 0 0 .995c.021.158.056.314.105.467l-1.372 1.033.628 1.073 1.58-.663c.243.246.502.448.778.605.276.157.58.278.915.362l.207 1.686Zm.646-3.158c.532 0 .984-.184 1.357-.553.373-.37.56-.816.56-1.342 0-.525-.187-.972-.56-1.341a1.857 1.857 0 0 0-1.357-.553c-.538 0-.991.184-1.36.553-.37.369-.555.816-.555 1.341 0 .526.184.973.554 1.342.37.369.823.553 1.361.553ZM0 20.75v-2.533h14.115v2.352L0 20.75Zm0-6.107V12.29h14.115v2.353H0ZM0 8.46V6.107h23.525V8.46H0Zm0-5.927V0h23.525v2.533H0Z" />
	</svg>
);
export default ConfigureIcon;
