import * as React from "react";

interface SvgComponentProps {
	[key: string]: any;
}
const UpIcon: React.FC<SvgComponentProps> = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={12}
		height={8}
		fill="none"
		{...props}
	>
		<path
			fill="currentColor"
			d="M6 3.4 1.4 8 0 6.6l6-6 6 6L10.6 8 6 3.4Z"
		/>
	</svg>
);
export default UpIcon;
